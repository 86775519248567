import create from 'zustand';
import { coinOptions } from './constants';

const defaultState = {
    filters: {
        coin: coinOptions[0].value,
        rarity: undefined,
        isOnlyActiveShowing: true,
    },
};

export const useStore = create((set) => ({
    ...defaultState,
    setFilter: (filter) =>
        set((state) => ({
            filters: {
                ...state.filters,
                [filter.type]: filter.value,
            },
        })),
    resetFilters: () => set({ filters: defaultState.filters }),
}));
