import React from 'react';
import cn from 'classnames';
import { ReactComponent as WaxIcon } from '../../icons/wax.svg';

import './WaxButton.scss';

export const WaxButton = ({ text, onClick, className }) => {
    return (
        <button
            className={cn('wax-button', { [className]: className })}
            onClick={onClick}
        >
            <div className="wax-button__content">
                <WaxIcon /> {text}
            </div>
        </button>
    );
};
