import { CONTRACT_NAMES, CONTRACT_TABLE_NAMES } from '../Constants';
import { signTransaction, getTableData } from '../Helpers';

export const claimMiningReward = async ({ activeUser }) => {
    return await signTransaction({
        activeUser,
        account: CONTRACT_NAMES.stak,
        action: 'collect',
        data: { owner: activeUser.accountName },
        expireSeconds: 60,
    });
};

export const fetchMiningMultiplier = async () => {
    const data = await getTableData({
        contract: CONTRACT_NAMES.stak,
        scope: CONTRACT_NAMES.stak,
        table: CONTRACT_TABLE_NAMES.multiplier,
    });

    return data[0] && data[0].multiplier ? data[0].multiplier : 1;
};
