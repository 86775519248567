import React from 'react';
import { Button, RadioGroup } from '../../Components/Ui/v2';
import { FiltersTitle } from '../../Components/Ui/v2/FiltersTitle';
import { Select } from '../../Components/Ui/v2/Select';
import { Switch } from '../../Components/Ui/v2/Switch';
import { coinOptions, rarityOptions } from './constants';
import { useStore } from './useStore';

export const StoreFilters = () => {
    const { coin, rarity, isOnlyActiveShowing } = useStore(
        (state) => state.filters
    );
    const setFilter = useStore((state) => state.setFilter);
    const resetFilters = useStore((state) => state.resetFilters);

    const handleCoinChange = (value) => {
        setFilter({ type: 'coin', value });
    };

    const handleRarityChange = (value) => {
        setFilter({ type: 'rarity', value });
    };

    const handleActiveItemSwitch = (value) => {
        setFilter({ type: 'isOnlyActiveShowing', value });
    };

    const isClearFiltersDisabled =
        coin === coinOptions[0] && !rarity && !isOnlyActiveShowing;

    return (
        <div className="store-page-filters">
            <FiltersTitle className="store-page-filters__title" />
            <div className="store-page-filters__controls">
                <RadioGroup
                    className="hide-desktop"
                    value={coin}
                    onChange={handleCoinChange}
                    items={coinOptions}
                    name="coin"
                />
                <div className="store-page-filters__control hide-mobile">
                    <span>Coin</span>
                    <Select
                        w="100px"
                        options={coinOptions}
                        selectedItem={coin}
                        onChange={handleCoinChange}
                    />
                </div>
                <div className="hide-mobile">
                    <Select
                        defaultTrigger="Rarity"
                        w="120px"
                        options={rarityOptions}
                        selectedItem={rarity}
                        onChange={handleRarityChange}
                    />
                </div>
                <div className="store-page-filters__control hide-mobile">
                    <span>Show only active items</span>
                    <Switch
                        enabled={isOnlyActiveShowing}
                        onChange={handleActiveItemSwitch}
                    />
                </div>
                <Button
                    className="store-page-filters__clear hide-mobile"
                    use="secondary"
                    size="md"
                    disabled={isClearFiltersDisabled}
                    onClick={resetFilters}
                >
                    Clear Filters
                </Button>
            </div>
        </div>
    );
};
