export const ALL = 'All';
export const COMMON = 'Common';
export const UNCOMMON = 'Uncommon';
export const RARE = 'Rare';
export const EPIC = 'Epic';
export const LEGENDARY = 'Legendary';

export const RARITY_LABEL = 'Rarity';
export const RARITY_ITEM_TYPES = [ALL, COMMON, UNCOMMON, RARE, EPIC, LEGENDARY];

export const rarityMap = {
    common: COMMON,
    uncommon: UNCOMMON,
    rare: RARE,
    epic: EPIC,
    legendary: LEGENDARY,
};
