import React, { useState } from 'react';
import { Sidebar } from '../../../Components/Ui/v2';

export const AuctionSidebarContainer = ({ children }) => {
    const [isOpened, setIsOpened] = useState(false);

    return (
        <Sidebar
            className={`auction-page-sidebar-stats ${isOpened ? 'opened' : ''}`}
        >
            <button
                className="auction-page-sidebar-stats__close"
                onClick={() => setIsOpened(false)}
            >
                Close
            </button>
            {children}
        </Sidebar>
    );
};
