import React from 'react';
import { NavLink } from 'react-router-dom';

import { useMenu } from '../../Hooks/useMenu';
import { ReactComponent as More } from '../../assets/img/menu/icon-more.svg';
import { BIG_MENU_MIN_COUNT } from './constants';

const MobileMenu = () => {
    const menuList = useMenu();
    const allMenuItems = [...menuList.top, ...menuList.bottom];
    const isBigMenu = allMenuItems.length > BIG_MENU_MIN_COUNT;
    const mainMenu = allMenuItems.slice(0, BIG_MENU_MIN_COUNT);

    return (
        <div className="menu_block__mobile">
            <ul className="mobile_menu__list">
                {mainMenu.map((item) => (
                    <li key={item.name}>
                        <NavLink
                            className="mobile_menu__item"
                            activeClassName="active_link"
                            exact={item.exact}
                            to={item.path}
                        >
                            {React.createElement(item.icon, {
                                className: 'image',
                            })}
                            <span>{item.mobileName}</span>
                        </NavLink>
                    </li>
                ))}
                {isBigMenu && (
                    <li>
                        <NavLink
                            className="mobile_menu__item"
                            activeClassName="active_link"
                            to="/MoreMenu"
                        >
                            <More className="image" />
                            <span>More</span>
                        </NavLink>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default MobileMenu;
