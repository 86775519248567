import React from 'react';
import { NavLink } from 'react-router-dom';

import ChairImg from '../../assets/img/chair.png';
import ChairImg2 from '../../assets/img/chair2.png';
import { useApp } from '../../Data/AppContext';
import { useIsHaveOurAssets } from '../../Hooks/useIsHaveOurAssets';
import { NoNftTooltip } from '../NoNFtTooltip';
import { Button } from '../Ui/v2/Button';

const noNftTooltipId = 'no-nft-tooltip--button';

const ShowNFTButton = ({ scrollShelter }) => {
    const { isAuthenticated, userItemsFetched } = useApp();
    const isHaveOurAssets = useIsHaveOurAssets();
    const needRenderNoNftTooltip =
        isAuthenticated && userItemsFetched && !isHaveOurAssets;

    return (
        <NavLink className="show-btn hide-mobile" to="/NFTStaking">
            <img src={!scrollShelter ? ChairImg : ChairImg2} alt="" />

            <Button
                className="show-btn__button"
                size="l"
                use="secondary"
                data-for={needRenderNoNftTooltip && noNftTooltipId}
                data-tip
            >
                NFT Staking
            </Button>
            {needRenderNoNftTooltip && (
                <NoNftTooltip
                    place="top"
                    id={noNftTooltipId}
                    offset={{ top: 30 }}
                />
            )}
        </NavLink>
    );
};

export default ShowNFTButton;
