import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useApp } from '../../Data/AppContext';
import { useMenu } from '../../Hooks/useMenu';
import { NoNftTooltip } from '../NoNFtTooltip';

const noNftTooltipId = 'no-nft-tooltip--menu';

const Menu = () => {
    const { showInterface } = useApp();
    const menuList = useMenu();
    const needRenderNoNftTooltip = menuList.top.some(
        (item) => item.isNotActive
    );

    return (
        <>
            <aside
                className={classnames('menu_block', { show: showInterface })}
            >
                <div className="menu_block-top-bg" />
                <div className="menu_block-bottom-bg" />
                <div className="menu_container">
                    <nav className="nav_container">
                        <ul className="menu-list">
                            {menuList.top.map((item) => (
                                <MenuItem key={item.name} item={item} />
                            ))}
                        </ul>
                        <ul className="menu-list">
                            {menuList.bottom.map((item) => (
                                <MenuItem key={item.name} item={item} />
                            ))}
                        </ul>
                    </nav>
                </div>
            </aside>
            {needRenderNoNftTooltip && (
                <NoNftTooltip place="right" id={noNftTooltipId} />
            )}
        </>
    );
};

export default Menu;

const MenuItem = ({ item }) => (
    <li
        className="menu-list__items"
        data-for={item.isNotActive && noNftTooltipId}
        data-tip
    >
        <NavLink
            className={classnames('menu-list__items__link', {
                notActive: item.isNotActive,
            })}
            activeClassName="active_link"
            exact={item.exact}
            to={item.path}
            onClick={(event) => item.isNotActive && event.preventDefault()}
        >
            <div className="background_container" />
            <div className="img_container">
                {React.createElement(item.icon, {
                    className: 'menu-list__items__img',
                })}
            </div>
            {item.name}
            {item.isNew && <div className="badge badge--new">NEW!</div>}
            {item.isAdmin && <div className="badge badge--new">ADMIN</div>}
            {item.isHot && <div className="badge badge--hot">HOT</div>}
        </NavLink>
    </li>
);
