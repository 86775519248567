import { useMemo } from 'react';

import { ReactComponent as HomeShelter } from '../assets/img/menu/icon-home.svg';
import { ReactComponent as NFTStaking } from '../assets/img/menu/icon-staking.svg';
import { ReactComponent as Information } from '../assets/img/menu/icon-information.svg';
import { ReactComponent as Stats } from '../assets/img/menu/icon-stats.svg';
import { ReactComponent as Auction } from '../assets/img/menu/icon-auction.svg';
import { ReactComponent as Store } from '../assets/img/menu/icon-store.svg';
import { ReactComponent as Drop } from '../assets/img/menu/icon-drop.svg';
import { ReactComponent as Affiliate } from '../assets/img/menu/icon-affiliate.svg';
import { useApp } from '../Data/AppContext';
import { useIsHaveOurAssets } from './useIsHaveOurAssets';

export const useMenu = () => {
    const { isAuthenticated, authData, userItemsFetched } = useApp();
    const isHaveOurAssets = useIsHaveOurAssets();
    const isNewEmptyUser = isAuthenticated && !isHaveOurAssets;

    return useMemo(() => {
        const list = {
            top: [
                {
                    name: 'Shelter Lobby',
                    mobileName: 'Shelter',
                    path: '/',
                    exact: true,
                    icon: HomeShelter,
                    isNew: false,
                    isNotActive: isNewEmptyUser && userItemsFetched,
                },
                {
                    name: 'NFT Staking',
                    mobileName: 'Staking',
                    path: '/NFTStaking',
                    exact: false,
                    icon: NFTStaking,
                    isNew: false,
                    isNotActive: isNewEmptyUser && userItemsFetched,
                },
                {
                    name: 'Store',
                    mobileName: 'Store',
                    path: '/Store',
                    exact: false,
                    icon: Store,
                    isNew: true,
                },
                {
                    name: 'Auction',
                    mobileName: 'Auction',
                    path: '/Auction',
                    exact: false,
                    icon: Auction,
                    isNew: true,
                },
            ],
            bottom: [],
        };

        if (isAuthenticated && authData?.is_admin) {
            list.top.push({
                name: 'Drop',
                mobileName: 'Drop',
                path: '/Drop',
                exact: false,
                icon: Drop,
                isAdmin: true,
            });
        }

        list.bottom = [
            {
                name: 'My Operations',
                mobileName: 'Operations',
                path: '/MyStats',
                exact: false,
                icon: Stats,
                isNew: false,
            },
            {
                name: 'Information',
                mobileName: 'Info',
                path: '/Information',
                exact: false,
                icon: Information,
                isNew: false,
            },
            {
                name: 'Recruitment',
                mobileName: 'Recruitment',
                path: '/Affiliate',
                exact: false,
                icon: Affiliate,
                isHot: false,
            },
        ];

        return list;
    }, [isAuthenticated, authData, isNewEmptyUser, userItemsFetched]);
};
