import { JsonRpc } from 'eosjs';
import axios from 'axios';
import { CONTRACT_NAMES, CONTRACT_TABLE_NAMES } from '../../../Constants';

export const getAuctionTable = (assetsId, url, tableName) => {
    const rpc = new JsonRpc(url);
    const config = {
        json: true,
        code: CONTRACT_NAMES.auction,
        scope: CONTRACT_NAMES.auction,
        table: tableName,
        index_position: 1,
        key_type: '',
        limit: assetsId ? 1 : 100000,
        reverse: false,
        show_payer: false,
    };

    if (assetsId) {
        config.lower_bound = assetsId;
        config.upper_bound = assetsId;
    }

    return rpc.get_table_rows(config);
};

export const getAuctions = (assetsId, url) =>
    getAuctionTable(assetsId, url, CONTRACT_TABLE_NAMES.auctions);
export const getMyAuctions = (accountName, url) =>
    getAuctionTable(accountName, url, CONTRACT_TABLE_NAMES.members);

export const getHistory = (assetsId, limit = 20, endpoint = '') =>
    axios.get(
        `${endpoint}/v2/history/get_actions?account=${CONTRACT_NAMES.auction}&transfer.memo=${assetsId}&skip=0&limit=${limit}&sort=desc`,
        {
            timeout: 5000,
        }
    );

export const getHistoryTransfer = (limit = 20, endpoint = '') =>
    axios.get(
        `${endpoint}/v2/history/get_actions?account=${CONTRACT_NAMES.auction}&act.name=transfer&act.account=${CONTRACT_NAMES.auction}&skip=0&limit=${limit}&sort=desc`,
        {
            timeout: 5000,
        }
    );
