import React from 'react';
import { EmptyScreen } from '../../../Components/Ui/v2';
import { ReactComponent as CardsImg } from '../../../assets/img/cards-img.svg';

export const NotFound = () => (
    <EmptyScreen
        w={220}
        icon={<CardsImg fill="none" />}
        title="OOOPS! THERE ARE NO ITEMS"
    >
        change your filters to see other items
    </EmptyScreen>
);
