import React from 'react';
import { COMMON, UNCOMMON, RARE, EPIC, LEGENDARY } from '../../Constants';
import { RarityMarker } from '../Ui/v2/RarityMarker';

export const SidebarRarityMarkers = ({
    common = 0,
    uncommon = 0,
    rare = 0,
    epic = 0,
    legendary = 0,
    className = '',
}) => (
    <section className={`sidebar-rarity-markers ${className}`}>
        <span>{COMMON}</span>
        <span>
            <RarityMarker value="common" />
        </span>
        <span className="sidebar-rarity-markers__value">{common}</span>

        <span>{UNCOMMON}</span>
        <span>
            <RarityMarker value="uncommon" />
        </span>
        <span className="sidebar-rarity-markers__value">{uncommon}</span>

        <span>{RARE}</span>
        <span>
            <RarityMarker value="rare" />
        </span>
        <span className="sidebar-rarity-markers__value">{rare}</span>

        <span>{EPIC}</span>
        <span>
            <RarityMarker value="epic" />
        </span>
        <span className="sidebar-rarity-markers__value">{epic}</span>

        <span>{LEGENDARY}</span>
        <span>
            <RarityMarker value="legendary" />
        </span>
        <span className="sidebar-rarity-markers__value">{legendary}</span>
    </section>
);
