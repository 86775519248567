import { useRef } from 'react';
import { useQuery } from 'react-query';
import { getWhitelistPeriod } from '../services';
import { ENDPOINTS } from '../../../Helpers';

// Get whitelist time period
const useWhitelistPeriod = () => {
    let { current: endpoint } = useRef(ENDPOINTS[0]);

    const whitelistPeriod = useQuery(
        ['whitelistPeriod'],
        () => getWhitelistPeriod(endpoint),
        {
            select: (data) => data.rows?.[0],
            retry: (failureCount) => {
                const requestNumber = failureCount + 1;
                const needRetry = requestNumber <= ENDPOINTS.length;
                if (needRetry) {
                    endpoint = ENDPOINTS[requestNumber];
                }
                return needRetry;
            },
        }
    );

    return whitelistPeriod;
};

export { useWhitelistPeriod };
