import { useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useUserWhitelist } from '../../StorePage/hooks/useUserWhitelist';
import { useUserStats } from '../../StorePage/hooks/useUserStats';
import { useTemplates } from '../../StorePage/hooks/useTemplates';
import { useUser } from '../../../Hooks/useUser';
import { getDropLots } from '../../StorePage/services';
import { mergeStoreLotsWithTemplatesData } from '../../StorePage/hooks/helpers';
import { ENDPOINTS } from '../../../Helpers';

const useDrop = (lotId) => {
    const { accountName } = useUser() || {};
    let { current: endpoint } = useRef(ENDPOINTS[0]);

    const drop = useQuery(['drop', lotId], () => getDropLots(lotId, endpoint), {
        retryDelay: 1500,
        select: (data) => data.rows,
        retry: (failureCount) => {
            const requestNumber = failureCount + 1;
            const needRetry = requestNumber <= ENDPOINTS.length;
            if (needRetry) {
                endpoint = ENDPOINTS[requestNumber];
            }
            return needRetry;
        },
        // refetchInterval: 30000, // 30 seconds
        // refetchIntervalInBackground: true,
    });

    const templates = useTemplates(
        { lots: drop.data, lotId },
        {
            enabled: false,
            select: (templates) =>
                mergeStoreLotsWithTemplatesData(drop.data, templates),
        }
    );

    const userWhitelist = useUserWhitelist(accountName);
    const userStats = useUserStats(accountName);

    useEffect(() => {
        if (drop.isFetched && drop.data?.length) {
            templates.refetch();
        }
    }, [drop.isFetched, drop.data]);

    return {
        ...templates,
        userStats: userStats.data,
        userWhitelist: userWhitelist.data,
        isFetching:
            drop.isFetching ||
            userWhitelist.isFetching ||
            userStats.isFetching ||
            templates.isFetching,
        isSuccess:
            (drop.isSuccess && !drop.data?.length) ||
            (drop.isSuccess &&
                userWhitelist.isSuccess &&
                userStats.isSuccess &&
                templates.isSuccess),
        isError:
            drop.isError ||
            userWhitelist.isError ||
            userStats.isError ||
            templates.isError,
    };
};

export { useDrop };
