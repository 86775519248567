import React from 'react';
import cn from 'classnames';
import LogoImg from './Logo.jsx';
import WaxLogo from './WaxLogo.jsx';
import { useApp } from '../../Data/AppContext';
import { ConnectionLoader } from './ConnectionLoader';
// import { DropTimeNotification } from '../DropTimeNotification/DropTimeNotification';
import { HeaderBanner } from '../HeaderBanner';

const Header = () => {
    const { sidebar, isAuthenticated, userItemsFetched } = useApp();

    return (
        <header className={cn('header', { 'header--with-sidebar': sidebar })}>
            <div className="header__container">
                <div className="header__left">
                    <LogoImg />
                    {/* {isAuthenticated && authData?.is_admin && (
                        <DropTimeNotification />
                    )} */}
                    <HeaderBanner type="desktop" />
                </div>
                <div className="header__middle" />
                <div className="header__right">
                    <WaxLogo />
                    {isAuthenticated && !userItemsFetched && (
                        <ConnectionLoader />
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
