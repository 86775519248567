const getCookieDomain = () =>
    window.location.host.includes('.deepmine.pages.dev')
        ? 'deepmine.pages.dev'
        : 'deepmine.world';

export const setCookie = (name, value, maxAge) => {
    document.cookie = `${name}=${
        value || ''
    }; max-age=${maxAge}; path=/; domain=${getCookieDomain()}`;
};

export const getCookie = (name) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return c.substring(nameEQ.length, c.length);
    }
    return null;
};

export const eraseCookie = (name) => {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; max-age=0; domain=${getCookieDomain()}`;
};
