import React from 'react';
import Countdown from 'react-countdown';

import { finishBannersLogicDate, getCountdownText } from './utils';

export const DropIsLive = ({ link, type, onComplete }) => {
    return (
        <a
            href={link}
            className={`header-banner ${type}`}
            target="_blank"
            rel="noreferrer"
        >
            <p className="header-banner__title">Drop is live!</p>
            <p className="header-banner__text">
                Ends in{' '}
                <Countdown
                    renderer={getCountdownText}
                    date={finishBannersLogicDate}
                    onComplete={onComplete}
                />
            </p>
        </a>
    );
};
