export const getCardStats = (userStats, accountName, card) => {
    const stats =
        userStats?.data?.find((v) => v.owner === accountName)?.lot_stats ?? [];

    const cardStats = stats.find((v) => v.key === card.id)?.value ?? {};

    const itemsBought = cardStats.items_counter ?? 0;
    const cdItemsBought = cardStats.cd_items_counter ?? 0;
    const cdExpiredAt = cardStats.cd_expired_at ?? 0;

    const itemsLeftUserCount = card.max_items_per_user - itemsBought;
    const isOutOfLimit =
        itemsLeftUserCount === 0 || cdItemsBought === card?.max_items_before_cd;

    return {
        itemsBought,
        cdItemsBought,
        cdExpiredAt,
        itemsLeftUserCount,
        isOutOfLimit,
    };
};
