import React from 'react';
import cn from 'classnames';

export const Link = ({ children, className, active, ...props }) => {
    return (
        <a
            className={cn('dm-link', className, { 'dm-link--active': active })}
            {...props}
        >
            {children}
        </a>
    );
};
