export const getStatus = (
    isSaleEnd,
    isOutOfStock,
    notInWhitelist,
    isOutOfLimit,
    isHiddenSaleEndDetails
) => {
    if (isSaleEnd && !isHiddenSaleEndDetails) {
        return 'end';
    }

    if (isOutOfStock) {
        return 'outOfStock';
    }

    if (isOutOfLimit) {
        return 'outOfLimit';
    }

    if (notInWhitelist) {
        return 'whitelist';
    }

    return '';
};
