import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../assets/img/menu/icon-right-arrow.svg';
import { useMenu } from '../../Hooks/useMenu';
import { BIG_MENU_MIN_COUNT } from './constants';

export const MoreMenu = () => {
    const menuList = useMenu();
    const secondaryMenu = [...menuList.top, ...menuList.bottom].slice(
        BIG_MENU_MIN_COUNT
    );

    return (
        <div className="menu-block-more">
            <ul className="menu-block-more-list">
                {secondaryMenu.map((item) => (
                    <li key={item.name} className="menu-block-more-list-item">
                        <NavLink
                            className="menu-block-more-list-item-content"
                            exact={item.exact}
                            to={item.path}
                        >
                            <div className="menu-block-more-list-item-content__main">
                                <item.icon />
                                <span>{item.mobileName}</span>
                            </div>
                            <ArrowRight className="menu-block-more-list-item-content__arrow" />
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};
