import React from 'react';
import { EmptyScreen, Link } from '../../../Components/Ui/v2';
import { ReactComponent as DropImg } from '../../../assets/img/drop-img.svg';

export const NoDrops = () => (
    <EmptyScreen
        w={236}
        icon={<DropImg />}
        title="THERE ARE NO ANY DROPS FOR NOW"
    >
        check our{' '}
        <Link href="https://medium.com/@deepmineworld" target="_blank">
            medium page
        </Link>{' '}
        to stay up to date with our upcoming events
    </EmptyScreen>
);
