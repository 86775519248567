import React from 'react';
import { Tabs } from '../Ui/v2';
import { STAKING_TABS } from '../../Constants';

const StatusBarStaking = ({ setPage, page }) => {
    return (
        <div className="status-bar">
            <div className="status-bar__container">
                <Tabs active={page} onChange={setPage} items={STAKING_TABS} />
            </div>
        </div>
    );
};

export default StatusBarStaking;
