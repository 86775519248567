import React, { useEffect, useState } from 'react';

import { DropIsLive } from './DropIsLive';
import { DropStartsIn } from './DropStartsIn';
import {
    finishBannersLogicDate,
    liveBannerDate,
    startsInBannerDate,
} from './utils';
import './HeaderBanner.scss';
import { useApp } from '../../Data/AppContext';

const link =
    'https://wax.atomichub.io/drops/90484+90483+90485+90487+90486+90488';

export const HeaderBanner = ({ type = 'desktop' }) => {
    const { setIsBannerShowing } = useApp();
    const [banner, setBanner] = useState(() => {
        const date = new Date();

        if (date >= finishBannersLogicDate) return null;
        if (date >= liveBannerDate) return 'is-live';
        if (date >= startsInBannerDate) return 'starts-in';

        return null;
    });

    useEffect(() => {
        setIsBannerShowing(!!banner);
    }, [banner]);

    if (banner === 'starts-in')
        return (
            <DropStartsIn
                link={link}
                type={type}
                onComplete={() => setBanner('is-live')}
            />
        );

    if (banner === 'is-live')
        return (
            <DropIsLive
                link={link}
                type={type}
                onComplete={() => setBanner(null)}
            />
        );

    return null;
};
