import { useRef } from 'react';
import { useQuery } from 'react-query';
import { getUserWhitelist } from '../services';
import { ENDPOINTS } from '../../../Helpers';

// Check if the user exists in whitelist
const useUserWhitelist = (userName) => {
    let { current: endpoint } = useRef(ENDPOINTS[0]);

    const whitelist = useQuery(
        ['userWhitelist', userName],
        () => getUserWhitelist(userName, endpoint),
        {
            select: (data) => data.rows,
            retry: (failureCount) => {
                const requestNumber = failureCount + 1;
                const needRetry = requestNumber <= ENDPOINTS.length;
                if (needRetry) {
                    endpoint = ENDPOINTS[requestNumber];
                }
                return needRetry;
            },
        }
    );

    return whitelist;
};

export { useUserWhitelist };
