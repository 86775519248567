import React from 'react';
import InfoBlock from './InfoBlock';
import EarnedBlock from './EarnedBlock';

const Sidebar = () => (
    <div className="sidebar-wrapper staking_sidebar">
        <InfoBlock />
        <EarnedBlock />
    </div>
);

export default Sidebar;
