import React from 'react';
import cn from 'classnames';
import { Radio } from '../Radio';

const RadioGroup = ({
    value,
    items,
    onChange,
    name,
    label,
    disabled = false,
    className,
}) => (
    <div className={cn('dm-radio-group', [className])}>
        {label && <div className={cn('dm-radio-group__label')}>{label}</div>}
        <div className={cn('dm-radio-group__items')}>
            {items.map((item) => (
                <Radio
                    key={item.value}
                    value={item.value}
                    label={item.name}
                    name={name}
                    disabled={disabled}
                    isChecked={item.value === value}
                    onChange={onChange}
                />
            ))}
        </div>
    </div>
);

export { RadioGroup };
