import React from 'react';
import { ReactComponent as Icon } from '../../../assets/img/dont-have-auctions.svg';
import { AUCTION_TABS } from '../constants';

export const DontHaveAuctions = ({ setActiveTab }) => {
    const handleLinkClick = (event) => {
        event.preventDefault();
        setActiveTab(AUCTION_TABS.ALL);
    };

    return (
        <div className="auction-page-dont-have-auctions">
            <Icon />
            <p className="auction-page-dont-have-auctions__title">
                YOU DON’T HAVE ANY AUCTIONS YET
            </p>
            <span>
                let’s buy some at the{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    className="auction-page-dont-have-auctions__link"
                    onClick={handleLinkClick}
                >
                    auction
                </a>
            </span>
        </div>
    );
};
