import React, { useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import { Button } from '../../../../Components/Ui/v2';
import { ReactComponent as ClockIcon } from '../../../../assets/img/icons/clock.svg';
import { isAuctionFinished } from '../../utils';
import {
    getImagePath,
    formatNumber,
    getBalanceCount,
    pluralize,
} from '../../../../Helpers';
import { CURRENCY } from '../../../../Constants';
import { CARD_STATUSES } from '../../constants';
import { getMs } from '../../../../Helpers/utils';

export const countdownRenderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
}) => {
    if (completed) return 'Finished';
    if (days) return `${days}d ${hours}h ${minutes}m`;
    return `${hours}h ${minutes}m ${seconds}s`;
};

export const CardStatusBadge = ({ className, status }) => {
    const isFinished = status === 'finished';

    return (
        <div className={cn('card-badge', `card-badge--${status}`, className)}>
            <div className="card-badge__text">
                {isFinished ? 'FINISHED' : 'I’M IN!'}
            </div>
            <div className="card-badge__figure" />
        </div>
    );
};

const Card = ({ card }) => {
    const [isFinished, setIsFinished] = useState(isAuctionFinished(card));
    const status = isFinished ? CARD_STATUSES.Finished : card.status;

    return (
        <Link
            to={`/Auction/${card.asset_id}`}
            className={cn('card', { 'card--finished': isFinished })}
        >
            {!!status && (
                <CardStatusBadge className="card__badge" status={status} />
            )}
            <div className="card__preview">
                <img
                    src={getImagePath(Number(card.template_id))}
                    alt={card.name}
                />
            </div>
            <div className="card__content">
                <div className="card__title">{card.name}</div>
                <div className="card__bid">
                    <div
                        className={cn('card__price', {
                            'card__price--winning':
                                status === CARD_STATUSES.Winning,
                            'card__price--losing':
                                status === CARD_STATUSES.Losing,
                        })}
                    >
                        {card.current_price ? (
                            <>
                                {formatNumber(
                                    getBalanceCount(
                                        card.price_type,
                                        card.current_price
                                    ),
                                    {
                                        maximumFractionDigits: 2,
                                    }
                                )}{' '}
                                {CURRENCY[card.price_type]}
                            </>
                        ) : (
                            'No bids'
                        )}
                    </div>
                    <div
                        className={cn('card__bid-count', {
                            'card__bid-count--winning':
                                status === CARD_STATUSES.Winning,
                            'card__bid-count--losing':
                                status === CARD_STATUSES.Losing,
                        })}
                    >
                        {!!card.bid_count && (
                            <>
                                {card.bid_count}{' '}
                                {pluralize(card.bid_count, 'bid')}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="card__bottom">
                <div className="card__time">
                    {isFinished ? (
                        'The auction has finished'
                    ) : (
                        <>
                            <ClockIcon />{' '}
                            <Countdown
                                renderer={countdownRenderer}
                                date={new Date(getMs(card.expired_at))}
                                onComplete={() => setIsFinished(true)}
                            />
                        </>
                    )}
                </div>
                <Button className="card__button" use="secondary">
                    {isFinished ? 'Open Auction' : 'Place Bid'}
                </Button>
            </div>
        </Link>
    );
};

export default Card;
