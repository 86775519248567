import React from 'react';
import { ReactComponent as SmileIcon } from '../../img/smile.svg';

import './ReferalsEmpty.scss';

export const ReferalsEmpty = () => {
    return (
        <div className="referrals-empty">
            <SmileIcon />
            <div className="referrals-empty__text">
                You have got NO reсruits yet, but you can invite them
            </div>
            <div className="referrals-empty__instructions">
                <div className="referrals-empty__instructions__title">
                    Here are some tips to get started:
                </div>
                <ol className="referrals-empty__instructions__description">
                    <li>Copy your unique recruitment link</li>
                    <li>Share this link with your friends</li>
                    <li>
                        Your friends need to sign up, confirm their email and
                        Discord
                    </li>
                    <li>Receive rewards based on your scouting rank</li>
                </ol>
            </div>
        </div>
    );
};
