import { isMainNet } from '../Config';

export const WAX_CHAIN_ID = isMainNet
    ? '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4'
    : 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12';

export const WAX_RPC_ENDPOINTS_HOST = isMainNet
    ? 'wax.greymass.com'
    : 'waxtestnet.greymass.com';

export const WAX_RPC_ENDPOINTS_PROTOCOL = 'https';

export const ATOMIC_ASSETS = 'atomicassets';

export const WAX_EXPLORER_LINK = isMainNet
    ? 'https://wax.bloks.io/transaction'
    : 'https://wax-test.bloks.io/transaction';
