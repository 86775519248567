import React from 'react';
import cn from 'classnames';

const LeftIconPath = 'M.5 1.072 12.5 8l-12 6.928V1.072Z';
const RightIconPath = 'M13.5 1.072 1.5 8l12 6.928V1.072Z';

const ICON_PATH_MAP = {
    0: LeftIconPath,
    1: RightIconPath,
};

const Icon = ({ path }) => (
    <svg
        className={cn('dm-tab__icon')}
        width="14"
        height="16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d={path} />
    </svg>
);

const Tabs = ({ active, onChange, items }) => {
    return (
        <div className={cn('dm-tabs')}>
            {items.map((tab, idx) => (
                <button
                    key={tab.value}
                    className={cn('dm-tab', {
                        'dm-tab--active': active === tab.value,
                    })}
                    onClick={() => onChange(tab.value)}
                >
                    <Icon path={ICON_PATH_MAP[idx]} />
                    {tab.name}
                </button>
            ))}
        </div>
    );
};

export { Tabs };
