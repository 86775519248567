import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { CancelButton } from '../AffiliateModal/CancelButton/CancelButton';
import { ConnectWaxButton } from '../../ConnectWaxButton';
import { useApp } from '../../../Data/AppContext';
import './CallToAuthModal.scss';

export const CallToAuthModal = () => {
    const { hidePopupHandler } = useApp();
    const location = useLocation();
    const title = sessionStorage.getItem('callToAuthTitle');
    const description = sessionStorage.getItem('callToAuthDescription');

    useEffect(() => {
        return () => {
            sessionStorage.removeItem('callToAuthTitle');
            sessionStorage.removeItem('callToAuthDescription');
        };
    }, []);

    return (
        <div className="confirm-wrapper">
            <div className="confirm-title">{title || 'Join the Store'}</div>
            <div className="call-to-auth-modal-description confirm-text-base">
                {description ||
                    'You need to connect your WAX wallet in order to be able to buy items from the store'}
            </div>

            <div onClick={hidePopupHandler} className="confirm-submit">
                <ConnectWaxButton redirectUrlAfterConnect={location.pathname} />
            </div>
            <CancelButton />
        </div>
    );
};
