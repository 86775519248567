import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentContainer } from '../../../Components';
import { PageTitle, BackButton, Loader } from '../../../Components/Ui/v2';
import { useApp } from '../../../Data/AppContext';
import { useAuctions } from '../hooks/useAuction';
import { AuctionAssetPage } from './AuctionAssetPage';

const AuctionAssetPageContent = () => {
    const { assetId } = useParams();
    const { data, isFetching, isIdle, isError } = useAuctions(assetId);

    if (isError) {
        return 'Something went wrong';
    }
    if (isFetching || isIdle) {
        return (
            <Loader text="Loading auction..." style={{ margin: '0 auto' }} />
        );
    }

    return <AuctionAssetPage card={data[0]} />;
};

export const AuctionAssetPageContainer = () => {
    const { toggleSidebarHadler } = useApp();

    useEffect(() => {
        toggleSidebarHadler(false);
    }, []);

    return (
        <ContentContainer>
            <div className="auction-page auction-page--inner">
                <PageTitle withoutSidebar>Classic Auction</PageTitle>
                <div
                    className="auction-page__content"
                    style={{ background: 'none', marginTop: 10 }}
                >
                    <BackButton />
                    <div className="auction-asset-page-content">
                        <AuctionAssetPageContent />
                    </div>
                </div>
            </div>
        </ContentContainer>
    );
};
