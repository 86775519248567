import React, { useEffect } from 'react';
import { Button } from '../Ui/v2/Button';
import { useApp } from '../../Data/AppContext';
import {
    ALL,
    ALL_TERM,
    UNSTAKE_PAGE,
    DAILY_POOL,
    STAKING_DURATION_1_DAY,
    PRE_LAUNCH_POOL,
    STAKING_DURATION_FIXED,
} from '../../Constants';

const SelectCard = ({ page }) => {
    const {
        stakedList,
        unstakedList,
        selectedItemsForStake,
        setSelectedItemsForStake,
        selectedItemsForUnstake,
        setSelectedItemsForUnstake,
        activeRareFilter,
        activeTermFilter,
    } = useApp();

    const TERM_MAP = {
        [DAILY_POOL]: STAKING_DURATION_1_DAY,
        [PRE_LAUNCH_POOL]: STAKING_DURATION_FIXED,
    };

    const getTermFilterCondition = (stakingInfo) => {
        return !!activeTermFilter &&
            activeTermFilter !== ALL_TERM &&
            !!stakingInfo
            ? page !== UNSTAKE_PAGE ||
                  stakingInfo.stakingDuration === TERM_MAP[activeTermFilter]
            : true;
    };

    const getRarityFilterCondition = (rarity) => {
        return !!activeRareFilter && activeRareFilter !== ALL
            ? activeRareFilter.toLowerCase() === rarity.toLowerCase()
            : true;
    };

    const CARD_LIST_MAP = {
        STAKE_PAGE: unstakedList,
        UNSTAKE_PAGE: stakedList,
    };

    const UNSELECT_HANDLER_MAP = {
        STAKE_PAGE: setSelectedItemsForStake,
        UNSTAKE_PAGE: setSelectedItemsForUnstake,
    };

    const SELECTED_ITEMS_MAP = {
        STAKE_PAGE: selectedItemsForStake,
        UNSTAKE_PAGE: selectedItemsForUnstake,
    };

    useEffect(() => {
        UNSELECT_HANDLER_MAP[page]([]);
    }, [activeRareFilter, activeTermFilter, page]);

    const isNeedUnselect = SELECTED_ITEMS_MAP[page].length >= 2;

    const handleSelect = () => {
        UNSELECT_HANDLER_MAP[page](
            isNeedUnselect
                ? []
                : CARD_LIST_MAP[page].filter(
                      ({ data: { rarity }, stakingInfo }) =>
                          getRarityFilterCondition(rarity) &&
                          getTermFilterCondition(stakingInfo)
                  )
        );
    };

    return (
        <Button onClick={handleSelect} use="secondary">
            {isNeedUnselect ? 'Unselect' : 'Select All'}
        </Button>
    );
};

export default SelectCard;
