import React, { useEffect, useState } from 'react';
import { Button } from '../Ui/v2/Button';
import { useApp } from '../../Data/AppContext';

const InformationBtn = ({ buttonText, buttonHandler }) => {
    const { selectedItemsForStake, selectedItemsForUnstake } = useApp();
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setDisabled(
            (buttonText === 'Unstake Selected' &&
                !selectedItemsForUnstake.length) ||
                (buttonText === 'Stake Selected' &&
                    !selectedItemsForStake.length)
        );
    }, [buttonText, selectedItemsForStake, selectedItemsForUnstake]);

    return (
        <div className="information_btn">
            {buttonText && (
                <Button
                    use="secondary"
                    disabled={disabled}
                    onClick={buttonHandler}
                >
                    {buttonText}
                </Button>
            )}
        </div>
    );
};

export default InformationBtn;
