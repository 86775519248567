import React from 'react';

export const Restriction = ({
    isSaleEnd,
    notInWhitelist,
    isOutOfStock,
    isOutOfLimit,
}) => {
    if (isSaleEnd) {
        return (
            <div className="store-card__restriction">Sale has been ended</div>
        );
    }

    if (notInWhitelist) {
        return (
            <div className="store-card__restriction">Only for whitelisters</div>
        );
    }

    if (isOutOfStock) {
        return <div className="store-card__out-stock">OUT OF STOCK</div>;
    }

    if (isOutOfLimit) {
        return (
            <div className="store-card__restriction">You’re out of limit</div>
        );
    }

    return null;
};
