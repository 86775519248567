import { useState } from 'react';

const useCounter = ({ max = Infinity, min = 1, initial = 1 }) => {
    const [count, setCount] = useState(initial);

    const increment = () => setCount((x) => Math.min(x + 1, max));

    const decrement = () => setCount((x) => Math.max(x - 1, min));

    const handleCount = (value) => {
        const isNumber = Number.isInteger(Number(value));
        const newCount = Math.min(Math.max(value, min), max);

        setCount(isNumber ? newCount : min);
    };

    return {
        count,
        setCount: handleCount,
        increment,
        decrement,
    };
};

export { useCounter };
