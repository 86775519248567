import React from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { useApp } from '../../Data/AppContext';

import { ReactComponent as WaxCloud } from '../../assets/img/icons/WaxCloud.svg';
import { Button } from '../Ui/v2/Button';
import { ReactComponent as WaxLogo } from '../../assets/img/header/header_wax.svg';

const LoginModal = () => {
    const {
        setUserDataHandler,
        hidePopupHandler,
        darkenScreenHandler,
        lightenScreenHandler,
        userLoginHandler,
        toggleSidebarHadler,
        toggleBlurHandler,
        toggleShelterHandler,
    } = useApp();

    const validationSchema = yup.object().shape({
        email: yup.string().typeError('String').required('Email is required'),
        password: yup.string().required('Password is required'),
    });

    // User auth handler
    const userLogin = (
        data,
        { setSubmitting, setErrors, setStatus, resetForm }
    ) => {
        if (data.email === 'admin') {
            // TODO:: просто для проверки красного экрана
            data.butNFT = true;
            setUserDataHandler(data);
            hidePopupHandler();
            resetForm({});
            return;
        }
        setUserDataHandler(data);
        hidePopupHandler();
        resetForm({});

        // Todo:: тут все будет переделано под логику приложения. пока все для теста
        setTimeout(() => {
            darkenScreenHandler();
            toggleSidebarHadler(false);
            toggleBlurHandler(false);
            userLoginHandler();
            toggleShelterHandler(true);

            setTimeout(() => {
                lightenScreenHandler();
            }, 1000);
        }, 2000);
    };

    return (
        <div className="content_block">
            <div className="wax__logo">
                <WaxCloud className="modal__icon" />
                <span>
                    WAX Cloud <br /> Wallet
                </span>
            </div>
            <a className="link__create" href="https://wallet.wax.io/">
                Create a free account in just a clicks.
            </a>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                validateOnBlur
                onSubmit={userLogin}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isValid,
                    handleSubmit,
                    dirty,
                }) => {
                    return (
                        <form className="wax__form">
                            <input
                                className="form__input"
                                type="text"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                placeholder="E-mail / User name"
                            />
                            <input
                                className="form__input"
                                type="text"
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                placeholder="Password"
                            />

                            {touched.name && errors.name && (
                                <p className="errors">{errors.name}</p>
                            )}

                            <Button
                                size="xl"
                                className="connect_wallet__btn wax-login-btn"
                                onClick={handleSubmit}
                                disabled={!isValid && !dirty}
                            >
                                <WaxLogo /> Connect WAX Wallet
                            </Button>
                        </form>
                    );
                }}
            </Formik>

            <a className="link__create indent" href="https://wallet.wax.io/">
                Forgot password or user name
            </a>
        </div>
    );
};

export default LoginModal;
