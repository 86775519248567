import React from 'react';
import AnswerBlock from './AnswerBlock';

const AnswerTable = ({ title, tables }) => {
    return (
        <div className="faq_table__wrap">
            <div className="title">{title}</div>
            {tables.map((item) => (
                <AnswerBlock
                    key={item.table_title}
                    id={item.id}
                    title={item.table_title}
                    list={item.list}
                />
            ))}
        </div>
    );
};

export default AnswerTable;
