import React from 'react';
import classnames from 'classnames';

import { NavLink } from 'react-router-dom';
import { ReactComponent as DangerIcon } from '../../assets/img/icons/danger.svg';
import ScreenImg from '../../assets/img/screen.png';

const AlertContent = ({ type, caption, text, subtext, img, button }) => (
    <>
        {type === 'auth' && (
            <img src={ScreenImg} alt="" className="alert__screen" />
        )}

        <div className="alert_content">
            {img && <DangerIcon className="alert__icon" />}
            <h3 className="alert__caption">{caption}</h3>
            <p className="alert__text">{text}</p>
            <p className="alert__text">{subtext}</p>
            {button}
        </div>
    </>
);

const Alert = ({ type, caption, text, subtext, img, button, link }) => {
    return (
        <div className={classnames('alert', type)}>
            {link ? (
                <NavLink to={link}>
                    <AlertContent
                        type={type}
                        caption={caption}
                        text={text}
                        subtext={subtext}
                        img={img}
                        button={button}
                    />
                </NavLink>
            ) : (
                <AlertContent
                    type={type}
                    caption={caption}
                    text={text}
                    subtext={subtext}
                    img={img}
                    button={button}
                />
            )}
        </div>
    );
};

export default Alert;
