import { AFFILIATION_PREFIX } from '../Components/Modal/AffiliateModal/AffiliateModal';
import { useApp } from '../Data/AppContext';

export const useAffiliate = () => {
    const { showPopupHandler, hidePopupHandler } = useApp();

    const showAffiliationPopup = (type) =>
        showPopupHandler(`${AFFILIATION_PREFIX}-${type}`);

    return { showAffiliationPopup, hideAffiliationPopup: hidePopupHandler };
};
