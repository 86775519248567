import React, { useMemo } from 'react';
import {
    useTransactionHistory,
    useHistoryTransfer,
} from '../hooks/useTransactionHistory';
import { WAX_EXPLORER_LINK, TOKEN_TYPE, CURRENCY } from '../../../Constants';
import { formatNumber } from '../../../Helpers';

const EmptyState = ({ children }) => (
    <tr>
        <td
            className="auction-asset-page-options-last-bids-table__empty"
            colSpan={5}
        >
            {children}
        </td>
    </tr>
);

const historyAction = {
    [TOKEN_TYPE.DMP]: useHistoryTransfer,
    [TOKEN_TYPE.WAX]: useTransactionHistory,
};

export const AuctionAssetHistory = ({ accountName, assetId, priceType }) => {
    const {
        data: history = [],
        isFetching: isHistoryFetching,
        isFetched: isHistoryFetched,
    } = historyAction[priceType](assetId);

    const firstUserBid = useMemo(
        () => history.findIndex((item) => item.act.data.from === accountName),
        [history, accountName]
    );

    return (
        <table className="auction-asset-page-options-last-bids-table">
            <thead>
                <tr>
                    <th width="30%">Account</th>
                    <th>Amount</th>
                    <th>Time</th>
                    <th>Tx ID</th>
                </tr>
            </thead>
            <tbody>
                {/* eslint-disable-next-line no-nested-ternary */}
                {!isHistoryFetched && isHistoryFetching ? (
                    <EmptyState>Loading...</EmptyState>
                ) : history.length ? (
                    history.slice(0, 10).map((item, idx) => (
                        <tr
                            key={new Date(item.timestamp).getTime()}
                            data-wining={idx === 0 && idx === firstUserBid}
                            data-losing={idx !== 0 && idx === firstUserBid}
                        >
                            <td>{item.act.data.from}</td>
                            <td>
                                {formatNumber(
                                    item.act.data.quantity.replace(
                                        ` ${item.act.data.symbol}`,
                                        ''
                                    ),
                                    {
                                        maximumFractionDigits: 5,
                                    }
                                )}{' '}
                                {CURRENCY[priceType]}
                            </td>
                            <td>
                                {new Date(item.timestamp)
                                    .toLocaleString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                    })
                                    .replace(/\s|, /g, '-')}
                            </td>
                            <td>
                                <a
                                    href={`${WAX_EXPLORER_LINK}/${item.trx_id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {item.trx_id.slice(0, 8)}
                                </a>
                            </td>
                        </tr>
                    ))
                ) : (
                    <EmptyState>No bids yet</EmptyState>
                )}
            </tbody>
        </table>
    );
};
