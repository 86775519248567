import React from 'react';
import { Notice } from '../../../../Components/Ui/v2';
import { pluralize } from '../../../../Helpers';

export const ItemsLimit = ({ itemsLeft, itemsLeftTotal }) => {
    const availableItems = Math.min(itemsLeft, itemsLeftTotal);
    return (
        <Notice className="store-product-page-options__notice">
            You can buy only {availableItems}{' '}
            {pluralize(availableItems, 'item')} of this product
        </Notice>
    );
};
