import React from 'react';

import { DROP_TYPE } from '../constants';
import { OutOfStock } from './supportComponents/OutOfStock';
import { NoPossiblePurchase } from './supportComponents/NoPossiblePurchase';
import { PurchaseLimit } from './supportComponents/PurchaseLimit';
import { OnlyWhitelisters } from './supportComponents/OnlyWhitelisters';
import { ItemsLimit } from './supportComponents/ItemsLimit';
import { getCardStats } from '../services/stats';
import { Info } from './supportComponents/Info';
import { BuySection } from './supportComponents/BuySection';
import { MysteryBox } from './supportComponents/MysteryBox';
import { getMs } from '../../../Helpers/utils';

export const StoreProductPageOptions = ({
    card,
    isInWhitelist,
    accountName,
    onUpdate,
    whitelistPeriod,
    userStats,
}) => {
    const { cdItemsBought, cdExpiredAt, itemsLeftUserCount } = getCardStats(
        userStats,
        accountName,
        card
    );

    const itemsLeftCount = card?.asset_ids.length ?? 0;
    const isOutOfStock = itemsLeftCount === 0;

    const isPurchaseLimitExceeded = cdItemsBought === card.max_items_before_cd;
    const isPurchasePossible = itemsLeftUserCount > 0;
    const isAvailableOnlyForWhitelist =
        card.drop_type === DROP_TYPE.Whitelist && !isInWhitelist;

    const wlFinishAt = getMs(whitelistPeriod?.wl_finish_at ?? 0);

    const renderNotice = () => {
        if (isAvailableOnlyForWhitelist) {
            return (
                <OnlyWhitelisters onUpdate={onUpdate} wlFinishAt={wlFinishAt} />
            );
        }

        if (isOutOfStock) {
            return <OutOfStock />;
        }

        if (!isPurchasePossible) {
            return <NoPossiblePurchase />;
        }

        if (isPurchaseLimitExceeded) {
            return (
                <PurchaseLimit
                    possiblePurchaseTime={getMs(cdExpiredAt)}
                    onUpdate={onUpdate}
                />
            );
        }

        if (itemsLeftCount && itemsLeftUserCount) {
            return (
                <ItemsLimit
                    itemsLeft={itemsLeftCount}
                    itemsLeftTotal={itemsLeftUserCount}
                />
            );
        }

        return null;
    };

    const isFormDisabled =
        isPurchaseLimitExceeded ||
        isAvailableOnlyForWhitelist ||
        !isPurchasePossible ||
        isOutOfStock;

    return (
        <div className="store-product-page-options">
            <Info card={card} />
            {renderNotice()}
            <BuySection
                card={card}
                itemsLeftUserCount={itemsLeftUserCount}
                isFormDisabled={isFormDisabled}
                onSuccess={onUpdate}
            />
            <MysteryBox template_id={card.template_id} />
        </div>
    );
};
