import React from 'react';
import CardsList from '../components/CardsList';
import { DontHaveAuctions } from '../components/DontHaveAuctions';
import { getMyAuctionsCards } from '../hooks/helpers';

const MyAuction = ({ setActiveTab, cards }) => {
    const myAuctionCards = getMyAuctionsCards(cards);
    if (!myAuctionCards.length)
        return <DontHaveAuctions setActiveTab={setActiveTab} />;

    return <CardsList cards={myAuctionCards} />;
};

export default MyAuction;
