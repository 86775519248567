import React, { useContext } from 'react';
import { UALContext } from 'ual-reactjs-renderer';

import { WaxButton } from '../WaxButton/WaxButton';

import './Join.scss';

export const Join = () => {
    const { showModal } = useContext(UALContext);

    const handleLogin = () => {
        showModal();
    };

    return (
        <div className="join">
            <div className="join__content">
                <div className="join__title">
                    Join now and receive a free DeepMine sticker!
                </div>
                <WaxButton
                    className="join__button"
                    text="JOIN WITH WAX WALLET"
                    onClick={handleLogin}
                />
            </div>
            <img className="join__image" src="img/landing/recruit.png" alt="" />
        </div>
    );
};
