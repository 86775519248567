import React from 'react';
import loaderGif from '../../../../assets/img/loader.gif';

export const Loader = ({ text = 'Loading...', ...props }) => {
    return (
        <div {...props} className="dm-page-loader">
            <img src={loaderGif} width={142} height={142} alt={text} />
            <span>{text}</span>
        </div>
    );
};
