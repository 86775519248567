import React, { useEffect } from 'react';
import { ContentContainer } from '../../Components';
import { PageTitle } from '../../Components/Ui/v2';
import { useApp } from '../../Data/AppContext';
import { AffiliateLevel } from './components/AffiliateLevel/AffiliateLevel';
import { AffiliateLink } from './components/AffiliateLink/AffiliateLink';

import './AffiliatePage.scss';
import { Referrals } from './components/Referals/Referrals';
import {
    clearReferrerCookie,
    getAuthUrls,
    getReferralData,
    getReferrerCookie,
    getReferrerName,
} from '../../Services';
import { useAffiliate } from '../../Hooks/useModal';

export const AffiliatePage = () => {
    const {
        toggleSidebarHadler,
        setAffiliateModalData,
        authData,
        setAuthLinks,
        setReferralData,
        sidebar,
    } = useApp();
    const { showAffiliationPopup } = useAffiliate();

    useEffect(() => {
        if (toggleSidebarHadler && sidebar) {
            toggleSidebarHadler(false);
        }
    }, [toggleSidebarHadler, sidebar]);

    const onAuthDataChanged = async () => {
        if (authData?.wax_address) {
            const referrerCode = getReferrerCookie();
            if (referrerCode) {
                const referrerName = await getReferrerName(referrerCode);
                if (authData.user_created) {
                    setAffiliateModalData({ referrerName });
                    showAffiliationPopup('auth-success');
                } else {
                    showAffiliationPopup('auth-error');
                }
                clearReferrerCookie();
            }
            const referralData = await getReferralData();
            setReferralData(referralData);
            const newUrls = await getAuthUrls();
            setAuthLinks(newUrls);
        }
    };

    useEffect(() => {
        onAuthDataChanged();
    }, [authData]);

    return (
        <ContentContainer className="content--affiliate">
            <PageTitle withoutSidebar>Recruitment Program</PageTitle>
            <div className="affiliate-page">
                <div className="affiliate-page__content">
                    <AffiliateLevel />
                    <AffiliateLink />
                    <Referrals />
                </div>
            </div>
        </ContentContainer>
    );
};
