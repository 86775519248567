import React from 'react';
import cn from 'classnames';

import { useApp } from '../../../../Data/AppContext';

import './CancelButton.scss';

export const CancelButton = ({ classname }) => {
    const { hidePopupHandler } = useApp();

    return (
        <div
            className={cn('modal-cancel-button', classname)}
            onClick={hidePopupHandler}
        >
            Cancel
        </div>
    );
};
