import React, { useState } from 'react';
import { Button, Link } from '../Ui/v2';
import './styles.scss';

const cookiesAcceptorLocalStorageFlag = 'cookiesAcceptorLocalStorageFlag';

export const CookiesAcceptor = () => {
    const [isCookiesAccepted, setIsCookiesAccepted] = useState(
        Boolean(localStorage.getItem(cookiesAcceptorLocalStorageFlag))
    );
    const onClick = () => {
        localStorage.setItem(cookiesAcceptorLocalStorageFlag, true);
        setIsCookiesAccepted(true);
    };

    if (isCookiesAccepted) {
        return null;
    }

    return (
        <div className="cookies-frame">
            <p className="cookies-notificator-text">
                Cookies are loved everywhere, especially on a planet in the
                outer space like Eleazar. <br /> Allow cookies for a better
                experience. <Link href="/Cookies">Learn{'\u00A0'}more</Link>
            </p>
            <Button onClick={onClick} className="cookies-notificator-button">
                Allow
            </Button>
        </div>
    );
};
