import React from 'react';
import { Switch as SwitchHeadless } from '@headlessui/react';
import classNames from 'classnames';

export const Switch = ({ enabled, onChange, size = 'm' }) => {
    return (
        <SwitchHeadless
            checked={enabled}
            onChange={onChange}
            className={classNames('dm-switch', `size-${size}`)}
        >
            <div className={classNames('dm-switch-circle', { enabled })} />
        </SwitchHeadless>
    );
};
