import { isMainNet } from '../../../Config';

const vipCards = isMainNet
    ? ['492749', '492748', '492746', '492745', '492803']
    : ['411113', '411112', '411111', '411110', '411109'];
export const mergeStoreLotsWithTemplatesData = (storeLots, templates) => {
    return storeLots?.map((lot) => {
        const templateItem = templates.find(
            ({ template_id }) => Number(template_id) === lot.template_id
        );

        const isPack = templateItem?.immutable_data?.type === 'Pack';
        const isVipCard = vipCards.includes(templateItem?.template_id);
        const computeRarityByIsPack = isPack
            ? ''
            : templateItem?.immutable_data?.rarity?.toLowerCase() ?? 'common';

        const rarity = isVipCard ? 'legendary' : computeRarityByIsPack;
        return {
            ...lot,
            name: templateItem?.name ?? '',
            description: templateItem?.immutable_data?.description ?? '',
            rarity,
        };
    });
};

export const mergeStoreWithAssetsData = (store, assets) =>
    store?.map((item) => {
        const assetsItem = assets.find(
            ({ asset_id }) => asset_id === item.asset_ids[0]
        );

        return {
            ...item,
            template_mint: assetsItem?.template_mint ?? '',
            total_template_mints: assetsItem?.template?.issued_supply ?? '',
            max_supply_mint: assetsItem?.template?.max_supply ?? '',
        };
    });
