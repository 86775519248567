import React, { useState, useEffect } from 'react';

import classnames from 'classnames';
import { useApp } from '../../Data/AppContext';
import AlertNft from './AlertNft';
import { BtnBlock } from '..';
import CardItem from './CardItem';

import {
    ALL,
    UNSTAKE_PAGE,
    STAKE_PAGE,
    DAILY_POOL,
    STAKING_DURATION_1_DAY,
    PRE_LAUNCH_POOL,
    STAKING_DURATION_FIXED,
    ALL_TERM,
} from '../../Constants';

const CardList = ({
    stakeOrUnstakeBtnText,
    stakeOrUnstakeBtnHandler,
    selectedCards,
    selectCard,
    selectedUnstekedCards,
    setSelectUnstekedCard,
    type,
    page,
    pageType,
    pageTypeSecond,
    setPage,
}) => {
    const {
        stakedList,
        unstakedList,
        activeRareFilter,
        activeTermFilter,
        setActiveRareFilter,
        setActiveTermFilter,
    } = useApp();

    const [itemsToShow, setItemsToShow] = useState([]);

    useEffect(() => {
        const items = page === UNSTAKE_PAGE ? stakedList : unstakedList;

        const getRarityFilterCondition = (rarity) =>
            !!activeRareFilter && activeRareFilter !== ALL
                ? activeRareFilter.toLowerCase() === rarity.toLowerCase()
                : true;

        const getTermFilterCondition = (stakingInfo) => {
            return !!activeTermFilter &&
                activeTermFilter !== ALL_TERM &&
                !!stakingInfo
                ? page !== UNSTAKE_PAGE ||
                      (activeTermFilter === DAILY_POOL &&
                          stakingInfo.stakingDuration ===
                              STAKING_DURATION_1_DAY) ||
                      (activeTermFilter === PRE_LAUNCH_POOL &&
                          stakingInfo.stakingDuration ===
                              STAKING_DURATION_FIXED)
                : true;
        };

        setItemsToShow(
            items.filter(({ data: { rarity }, stakingInfo }) => {
                return (
                    getRarityFilterCondition(rarity) &&
                    getTermFilterCondition(stakingInfo)
                );
            })
        );
    }, [page, activeRareFilter, activeTermFilter, unstakedList, stakedList]);

    return (
        <div className="card-block">
            <div className="card-block__left-bg" />
            <div className="card-block__right-bg" />
            <div className="container">
                <BtnBlock
                    pageType={pageType}
                    pageTypeSecond={pageTypeSecond}
                    scroll
                    stakeOrUnstakeBtnText={stakeOrUnstakeBtnText}
                    stakeOrUnstakeBtnHandler={stakeOrUnstakeBtnHandler}
                    page={page}
                    selectedCards={selectedCards}
                    type={type}
                    setPage={setPage}
                    setSelectedRarity={setActiveRareFilter}
                    setSelectedTerm={setActiveTermFilter}
                />

                <div className="card__wrapper">
                    {page === UNSTAKE_PAGE && stakedList.length <= 0 && (
                        <AlertNft setPage={setPage} />
                    )}
                    <div
                        className={classnames('card-block__wrapper', {
                            show: page === UNSTAKE_PAGE,
                        })}
                    >
                        {itemsToShow.map((item) => (
                            <CardItem
                                key={item.asset_id}
                                type={type}
                                item={item}
                                selectCard={setSelectUnstekedCard}
                                selectedCards={selectedUnstekedCards}
                            />
                        ))}
                    </div>

                    <div
                        className={classnames('card-block__wrapper', {
                            show: page === STAKE_PAGE,
                        })}
                    >
                        {itemsToShow.map((item) => (
                            <CardItem
                                key={item.asset_id}
                                item={item}
                                type={type}
                                selectCard={selectCard}
                                selectedCards={selectedCards}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardList;
