import React from 'react';
import classnames from 'classnames';
import AnswerItem from './AnswerItem';

const AnswerList = ({ title, list, numeric }) => {
    return (
        <>
            {title && <div className="list_title">{title}</div>}
            <div className={classnames('list', { numeric })}>
                <ul>
                    {list.map((item) => (
                        <AnswerItem key={item.id} text={item.text} />
                    ))}
                </ul>
            </div>
        </>
    );
};

export default AnswerList;
