import React from 'react';
import { getWaxCount } from '../../../../Helpers';
import { CURRENCY } from '../../../../Constants';

export const CardInfo = ({ card, itemsLeft }) => (
    <div className="store-card__content">
        <p className="store-card__title">{card.name}</p>
        <span className="store-card__price">
            {getWaxCount(card.price_per_asset)} {CURRENCY[card.price_type]}
        </span>
        <span className="store-card__left">{itemsLeft} items left</span>
    </div>
);
