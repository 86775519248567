import React from 'react';
import cn from 'classnames';
import { Alert, LoginButton } from '../../Components';
import { ConnectWaxButton } from '../../Components/ConnectWaxButton';

export const ConnectWaxContent = ({ onShowModal }) => (
    <div className={cn('main-screen')}>
        <Alert
            type="login"
            caption="DEEPMINE CITIZENS ONLY"
            text="Please pass the identification."
        />

        <LoginButton type="auth" onClick={onShowModal} />

        <div className={cn('main-screen__connect-wax')}>
            <ConnectWaxButton />
        </div>
    </div>
);
