import { DROP_TYPE } from '../../StorePage/constants';

export const useDropLots = (drops = []) => {
    return drops.reduce(
        (acc, lot) => {
            if (lot.drop_type === DROP_TYPE.Public) {
                acc.publicLots.push(lot);
            }
            if (lot.drop_type === DROP_TYPE.Whitelist) {
                acc.whitelistLots.push(lot);
            }
            if (lot.drop_type === DROP_TYPE.All) {
                acc.publicLots.push(lot);
                acc.whitelistLots.push(lot);
            }

            return acc;
        },
        { publicLots: [], whitelistLots: [] }
    );
};
