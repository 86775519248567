import React from 'react';

export const Input = ({ value, onChange, onInput, onBlur, type, disabled }) => {
    const handleChange = (event) => {
        onChange?.(event);
    };

    return (
        <input
            className="dm-input"
            value={value}
            disabled={disabled}
            onChange={handleChange}
            onBlur={onBlur}
            onInput={onInput}
            type={type}
        />
    );
};
