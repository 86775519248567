import React from 'react';
import ReactTooltip from 'react-tooltip';

import './TooltipText.scss';

export const TooltipText = ({ children, Tooltip, id, place = 'bottom' }) => {
    const uid = `tooltip-text__${id}`;

    return (
        <>
            <div data-tip data-for={uid} className="tooltip-text">
                {children}
            </div>
            <ReactTooltip
                className="tooltip-text__react-tooltip"
                id={uid}
                effect="solid"
                place={place}
                delayHide={500}
                arrowColor="#000"
            >
                <Tooltip />
            </ReactTooltip>
        </>
    );
};
