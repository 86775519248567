import React from 'react';
import classnames from 'classnames';

const Caption = ({ children, isAuthenticated }) => {
    return (
        <h1
            className={classnames('page_caption', {
                page_caption__auth: isAuthenticated,
            })}
        >
            {children}
        </h1>
    );
};

export default Caption;
