import {
    CONTRACT_NAMES,
    CONTRACT_TABLE_NAMES,
    ATOMIC_ASSETS,
    STAKING_DURATION_1_DAY,
} from '../Constants';
import {
    signTransaction,
    getTableData,
    signTransactions,
    fetchRows,
} from '../Helpers';
import { getMs } from '../Helpers/utils';

export const stakeItems = async ({ activeUser, items }) => {
    return await signTransaction({
        activeUser,
        account: ATOMIC_ASSETS,
        action: 'transfer',
        data: {
            from: activeUser.accountName,
            to: CONTRACT_NAMES.stak,
            asset_ids: items,
            memo: STAKING_DURATION_1_DAY,
        },
    });
};

export const mintAchievement = async ({ activeUser, achievementId }) => {
    return await signTransaction({
        activeUser,
        account: CONTRACT_NAMES.eosioToken,
        action: 'transfer',
        data: {
            from: activeUser.accountName,
            to: CONTRACT_TABLE_NAMES.achievement,
            memo: achievementId,
            quantity: '0.50000000 WAX',
        },
    });
};

export const unstakeItems = async ({
    activeUser,
    preLaunchItems,
    dailyItems,
}) => {
    const actions = [];

    const unstakeActionData = {
        account: CONTRACT_NAMES.stak,
        action: 'unstake',
        data: {
            owner: activeUser.accountName,
            asset_ids: [],
            fixed: false,
            days: false,
        },
    };

    if (preLaunchItems.length)
        actions.push({
            ...unstakeActionData,
            data: {
                ...unstakeActionData.data,
                asset_ids: preLaunchItems,
                fixed: true,
            },
        });

    if (dailyItems.length)
        actions.push({
            ...unstakeActionData,
            data: {
                ...unstakeActionData.data,
                asset_ids: dailyItems,
                days: true,
            },
        });

    return await signTransactions({ activeUser, actions });
};

export const fetchStakingConfig = async () => {
    const config = await getTableData({
        contract: CONTRACT_NAMES.stak,
        scope: CONTRACT_NAMES.stak,
        table: CONTRACT_TABLE_NAMES.stakingconf,
    });

    return config.map((item) => {
        const [reward] = item.reward.split(' ');

        return { ...item, reward: Number(reward) };
    });
};

export const fetchPreLaunchFinishTimestamp = async () => {
    const { rows } = await fetchRows({
        contract: CONTRACT_NAMES.stak,
        scope: CONTRACT_NAMES.stak,
        table: CONTRACT_TABLE_NAMES.dateconf,
        limit: 1,
    });

    if (!rows[0]) return 0;

    return getMs(rows[0].end_time);
};
