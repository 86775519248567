import React from 'react';
import Countdown from 'react-countdown';

import { getCountdownText, liveBannerDate } from './utils';

export const DropStartsIn = ({ link, type, onComplete }) => {
    return (
        <a
            href={link}
            className={`header-banner starts-in ${type}`}
            target="_blank"
            rel="noreferrer"
        >
            <p className="header-banner__text">Drop starts in</p>
            <p className="header-banner__title">
                <Countdown
                    renderer={getCountdownText}
                    date={liveBannerDate}
                    onComplete={onComplete}
                />
            </p>
        </a>
    );
};
