import React, { useContext } from 'react';
import { UALContext } from 'ual-reactjs-renderer';

import { WaxButton } from '../WaxButton/WaxButton';
import { TooltipText } from '../TooltipText/TooltipText';

import './Main.scss';

const TooltipNameContent = () => <div>This is WAX wallet username</div>;

const TooltipWhatIsContent = () => (
    <div>
        WAX Wallet is a simple and convenient cryptocurrency wallet (crypto
        wallet).{' '}
        <a href="https://wallet.wax.io/" target="_blank" rel="noreferrer">
            Learn more
        </a>
    </div>
);

export const Main = ({ referrerName }) => {
    const { showModal } = useContext(UALContext);

    const handleLogin = () => {
        showModal();
    };

    return (
        <div className="landing-main">
            <div className="landing-main__title">
                Join tHE deepmine game and&nbsp;play&nbsp;with your friends
            </div>
            <div className="landing-main__description">
                <div>Join now to receive a free Recruit sticker</div>
            </div>
            {referrerName && (
                <div className="landing-main__invited-by">
                    * You have been invited by&nbsp;
                    <TooltipText
                        id="wax-wallet-username"
                        Tooltip={TooltipNameContent}
                        place="top"
                    >
                        <span>{referrerName}</span>
                    </TooltipText>
                </div>
            )}
            <WaxButton
                text="JOIN WITH WAX WALLET"
                className="landing-main__button"
                onClick={handleLogin}
            />
            <div className="landing-main__what-is">
                <TooltipText id="what-is-wax" Tooltip={TooltipWhatIsContent}>
                    NOT FAMILIAR WITH WAX?
                </TooltipText>
            </div>
        </div>
    );
};
