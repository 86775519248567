import React from 'react';
import cn from 'classnames';

const Button = ({
    children,
    onClick,
    className,
    use = 'primary',
    size = 'md',
    disabled,
    style,
    type = 'button',
    ...props
}) => (
    <button
        className={cn(
            'dm-button',
            `dm-button--${size}`,
            `dm-button--${use}`,
            className,
            {
                'dm-button--disabled': disabled,
            }
        )}
        style={style}
        onClick={onClick}
        disabled={disabled}
        type={type}
        {...props}
    >
        {children}
    </button>
);

export { Button };
