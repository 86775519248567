import React from 'react';

export const CardCheckBox = ({ isChecked }) => {
    return (
        <label className="card-item-checkbox">
            {isChecked && (
                <svg className="card-item-checkbox__mark" viewBox="0 0 20 17">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.6052 2.74346L8.73599 16.6319L0.888672 10.2113L2.78839 7.88946L8.26294 12.3686L17.2427 0.894531L19.6052 2.74346Z"
                    />
                </svg>
            )}
        </label>
    );
};
