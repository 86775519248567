import React from 'react';
import { StoreCard } from './StoreCard';

const CardsList = ({ data, userStats }) => (
    <div className="store-cards-list">
        {data.map((card) => (
            <StoreCard
                card={card}
                userStats={userStats}
                key={card.id}
                isHiddenSaleEndDetails
            />
        ))}
    </div>
);

export { CardsList };
