export const SORT_OPTION = {
    TYPE_DESC: 'TYPE_DESC',
    TYPE_ASC: 'TYPE_ASC',

    DATE_DESC: 'DATE_DESC',
    DATE_ASC: 'DATE_ASC',

    AMOUNT_DESC: 'AMOUNT_DESC',
    AMOUNT_ASC: 'AMOUNT_ASC',

    STATUS_DESC: 'STATUS_DESC',
    STATUS_ASC: 'STATUS_ASC',
};
