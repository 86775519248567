import React from 'react';

import { ContentContainer, Information } from '../../Components';

const NotAuthPage = () => (
    <ContentContainer className="content--not-auth">
        <div className="not-auth-page">
            <div className="not-auth-page__content">
                <Information />
            </div>
        </div>
    </ContentContainer>
);

export { NotAuthPage };
