import React, { useCallback, useEffect, useState } from 'react';
import { ContentContainer } from '../../Components';
import { PageTitle, Loader } from '../../Components/Ui/v2';
import { useApp } from '../../Data/AppContext';
import { StoreFilters } from '../StorePage/StoreFilters';
import { useWhitelistPeriod } from '../StorePage/hooks/useWhitelistPeriod';
import { useUser } from '../../Hooks/useUser';
import { useDrop } from './hooks/useDrop';
import { WhitelistRound } from './components/WhitelistRound';
import { PublicRound } from './components/PublicRound';
import { useStore as useStateStore } from '../StorePage/useStore';
import { NoDrops } from './components/NoDrops';
import { NotFound } from './components/NotFound';
import { useDropLots } from './hooks/useDropLots';
import { filterCards } from '../../Helpers';
import { getMs } from '../../Helpers/utils';
import useWindowDimensions from '../../Hooks/WindowDimensions';

const MIN_WIDE_CARD_WIDTH = 472;
const CONTAINER_PADDINGS = 323;
const CARDS_GAP = 12;

export const DropPage = () => {
    const { width } = useWindowDimensions();
    const { accountName } = useUser();
    const { toggleSidebarHadler } = useApp();
    const { data: whitelistPeriod, isFetching: isWhiteListFetching } =
        useWhitelistPeriod();
    const { data: dropLots, userStats, userWhitelist, isFetching } = useDrop();
    const { coin, rarity, isOnlyActiveShowing } = useStateStore(
        (state) => state.filters
    );
    const finishAt = getMs(whitelistPeriod?.wl_finish_at ?? 0);
    const startAt = getMs(whitelistPeriod?.wl_start_at ?? 0);
    const [isWLRoundFinished, setIsWLRoundFinished] = useState(
        Date.now() >= finishAt
    );
    useEffect(() => {
        setIsWLRoundFinished(Date.now() >= finishAt);
    }, [finishAt]);
    const onWLRoundFinished = useCallback(
        () => setIsWLRoundFinished(true),
        [setIsWLRoundFinished]
    );
    const filteredData = filterCards({
        data: dropLots,
        coin,
        rarity,
        isOnlyActiveShowing,
        isWLRoundFinished,
        wlStart: startAt,
        wlFinish: finishAt,
    });

    const { publicLots, whitelistLots } = useDropLots(filteredData);
    const isNoDrops = !dropLots?.length;
    const isNoAnyLots = !publicLots?.length && !whitelistLots?.length;

    const isInWhitelist = !!userWhitelist?.some(
        (user) => user.owner === accountName
    );

    useEffect(() => {
        toggleSidebarHadler(false);
    }, []);

    const containerWidth = width - CONTAINER_PADDINGS;
    // because x * MIN_WIDE_CARD_WIDTH + (x-1) * CARDS_GAP = containerWidth
    const minCountForWideCards = Math.floor(
        (containerWidth + CARDS_GAP) / (MIN_WIDE_CARD_WIDTH + CARDS_GAP)
    );

    return (
        <ContentContainer className="content--drop">
            <PageTitle withoutSidebar>Drop</PageTitle>

            <div className="store-page">
                <div className="store-page__content">
                    <StoreFilters />

                    <div className="store-page__list">
                        {/* eslint-disable no-nested-ternary */}
                        {isFetching || isWhiteListFetching ? (
                            <Loader text="Loading items..." />
                        ) : isNoDrops ? (
                            <NoDrops />
                        ) : isNoAnyLots ? (
                            <NotFound />
                        ) : (
                            <>
                                {!!whitelistLots.length &&
                                    !!whitelistPeriod && (
                                        <WhitelistRound
                                            userStats={userStats}
                                            isInWhitelist={isInWhitelist}
                                            data={whitelistLots}
                                            startAt={startAt}
                                            finishAt={finishAt}
                                            isRoundFinished={isWLRoundFinished}
                                            onRoundFinish={onWLRoundFinished}
                                            hasWideCards={
                                                whitelistLots.length <=
                                                minCountForWideCards
                                            }
                                        />
                                    )}

                                {!!publicLots.length && (
                                    <PublicRound
                                        userStats={userStats}
                                        data={publicLots}
                                        startAt={finishAt}
                                        hasWideCards={
                                            publicLots.length <=
                                            minCountForWideCards
                                        }
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </ContentContainer>
    );
};
