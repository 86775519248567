import Countdown from 'react-countdown';
import React from 'react';
import { countdownRenderer } from '../StoreCard/helpers';
import { Notice } from '../../../../Components/Ui/v2';

export const PurchaseLimit = ({ possiblePurchaseTime, onUpdate }) => (
    <Notice className="store-product-page-options__notice">
        You have exceeded the purchase limit for this item
        <br />
        Next purchase possible in{' '}
        <Countdown
            renderer={countdownRenderer}
            date={possiblePurchaseTime}
            onComplete={onUpdate}
        />
    </Notice>
);
