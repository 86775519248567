import React from 'react';
import cn from 'classnames';

const STATUSES = {
    end: 'SALE ENDED',
    outOfLimit: 'OUT OF LIMIT',
    outOfStock: 'OUT OF STOCK',
    whitelist: 'WHITELISTED',
    cooldown: 'COOLDOWN',
};

const Badge = ({ className, status }) => {
    return (
        <div
            className={cn('card-badge', className, {
                'card-badge--disabled': status !== 'cooldown',
                'card-badge--cooldown': status === 'cooldown',
            })}
        >
            <div className="card-badge__text">{STATUSES[status]}</div>
            <div className="card-badge__figure" />
        </div>
    );
};

export { Badge };
