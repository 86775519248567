import React from 'react';
import { Button } from '../../../../Components/Ui/v2';
import { useAffiliate } from '../../../../Hooks/useModal';

export const ButtonForCallToAuth = ({ text, onClick }) => {
    const { showAffiliationPopup } = useAffiliate();

    const handleClick = () => {
        onClick?.();
        showAffiliationPopup('call-to-auth');
    };

    return (
        <Button className="store-card__button" size="l" onClick={handleClick}>
            {text}
        </Button>
    );
};
