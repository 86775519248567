import classNames from 'classnames';
import React from 'react';

export const FiltersTitle = ({ text = 'filters', className = '' }) => {
    return (
        <span className={classNames('dm-filters-title', className)}>
            {text}
        </span>
    );
};
