import axios from 'axios';
import { JsonRpc } from 'eosjs';
import { toast } from 'react-toastify';
import { eraseCookie, getCookie, setCookie } from './cookie.service';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
export const MAX_AGE = 30 * DAY;

const defaultConfig = {
    withCredentials: true,
};

export const ENDPOINT =
    process.env.NODE_ENV === 'development'
        ? ''
        : process.env.REACT_APP_UBS_ORIGIN ?? 'https://rc.deepmine.world';
export const REFERRER_COOKIE = 'referrer_code';

export const getReferrerCookie = () => getCookie(REFERRER_COOKIE);
export const setReferrerCookie = (value) =>
    setCookie(REFERRER_COOKIE, value, MAX_AGE);
export const clearReferrerCookie = () => eraseCookie(REFERRER_COOKIE);

const responseWrapper = async (f, needErrorMessage) => {
    try {
        const response = await f;
        return response.data;
    } catch (e) {
        const errorMessage = e?.response?.data?.message ?? 'Error';
        toast.error(errorMessage);
        if (needErrorMessage) {
            return { responseError: errorMessage };
        }
    }
};

export const affiliateAuth = async (newUser) =>
    responseWrapper(
        axios.post(
            `${ENDPOINT}/ubs/wax/auth`,
            {
                wax_address: newUser,
            },
            defaultConfig
        )
    );

export const sendEmailCode = async (email, needErrorMessage) =>
    responseWrapper(
        axios.post(
            `${ENDPOINT}/ubs/mail/confirmation/request`,
            { email },
            defaultConfig
        ),
        needErrorMessage
    );

export const verifyEmailCode = async (code, needErrorMessage) =>
    responseWrapper(
        axios.post(
            `${ENDPOINT}/ubs/mail/confirmation/verify`,
            { code },
            defaultConfig
        ),
        needErrorMessage
    );

export const discordAuth = async (code) =>
    responseWrapper(
        axios.get(`${ENDPOINT}/ubs/discord/auth?code=${code}`, defaultConfig)
    );

export const getAuthUrls = async () =>
    responseWrapper(axios.get(`${ENDPOINT}/ubs/auth/links`, defaultConfig));

export const getAffiliateAchievements = async () =>
    responseWrapper(
        axios.get(`${ENDPOINT}/ubs/affiliate/achievements`, defaultConfig)
    );

export const getReferralData = async () =>
    responseWrapper(axios.get(`${ENDPOINT}/ubs/referrals`, defaultConfig));

export const getReferrerName = async (code) => {
    const data = await responseWrapper(
        axios.get(
            `${ENDPOINT}/ubs/referrer?referrer_code=${code}`,
            defaultConfig
        )
    );

    return data.wax_address;
};

export const confirmAchievement = async (id) =>
    responseWrapper(
        axios.post(
            `${ENDPOINT}/ubs/affiliate/achievements/confirm`,
            { achievement_id: id },
            defaultConfig
        )
    );

export const confirmAchievementMint = async (id) =>
    responseWrapper(
        axios.post(
            `${ENDPOINT}/ubs/affiliate/achievements/send`,
            { achievement_id: id },
            defaultConfig
        )
    );

export const getDiscordCode = (search) => {
    const urlParams = new URLSearchParams(search);
    return urlParams.get('code');
};

export const getIsAffiliatePath = (location) => {
    return location.pathname === '/Affiliate';
};

export const getHiddenReferral = (name) => `${name.slice(0, 5)}***`;

export const getAchievementMintState = (url, accountName) => {
    const rpc = new JsonRpc(url);
    const config = {
        json: true,
        code: 'deepmineachv',
        scope: 'deepmineachv',
        table: 'users',
        index_position: 1,
        key_type: '',
        limit: accountName ? 1 : 100000,
        reverse: false,
        show_payer: false,
    };

    if (accountName) {
        config.lower_bound = accountName;
        config.upper_bound = accountName;
    }

    return rpc.get_table_rows(config);
};
