import React from 'react';

import { ReactComponent as ArrowLeft } from '../../assets/img/icons/arrow_left.svg';
import { ReactComponent as ArrowRight } from '../../assets/img/icons/arrow_right.svg';
import { Button } from '../Ui/v2/Button';

const Pagination = ({ pages, currentPage, setCurrentPage }) => {
    const goToNextPage = () => {
        setCurrentPage((page) => (page === pages ? pages : page + 1));
    };

    const goToPreviousPage = () => {
        setCurrentPage((page) => (page === 1 ? 1 : page - 1));
    };

    return (
        <div className="pagination_block">
            <div className="pagination_container">
                <Button
                    disabled={currentPage && currentPage === 1}
                    onClick={() => setCurrentPage(1)}
                >
                    First
                </Button>

                <button
                    className="arrow_btn left_btn"
                    onClick={goToPreviousPage}
                >
                    <ArrowLeft className="icons" />
                </button>

                <div className="page_number">
                    <span>
                        Page {currentPage} of {pages}
                    </span>
                </div>

                <button className="arrow_btn rigth_btn" onClick={goToNextPage}>
                    <ArrowRight className="icons" />
                </button>

                <Button
                    disabled={currentPage && currentPage === pages}
                    onClick={() => setCurrentPage(pages)}
                >
                    Last
                </Button>
            </div>
        </div>
    );
};

export default Pagination;
