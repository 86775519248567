export const countdownRenderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
}) => {
    if (completed) return 'Finished';
    if (days) return `${days}d ${hours}h ${minutes}m`;
    return `${hours}h ${minutes}m ${seconds}s`;
};
