import React from 'react';

import './AffiliateSectionContainer.scss';

export const AffiliateSectionContainer = ({ children, title }) => {
    return (
        <div className="affiliate-section-container">
            <div className="affiliate-section-container__title">{title}</div>
            {children}
        </div>
    );
};
