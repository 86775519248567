import { useContext } from 'react';
import { UALContext } from 'ual-reactjs-renderer';

const useUser = () => {
    const { activeUser } = useContext(UALContext);

    return activeUser;
};

export { useUser };
