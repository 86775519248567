import React from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from '../../Data/AppContext';
import AppContent from './AppContent';
import { queryClient } from '../../Config/queryClient';
import { ErrorBoundary } from './ErrorBoundary';

const App = () => (
    <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <AppProvider>
                    <AppContent />
                </AppProvider>
            </BrowserRouter>
        </QueryClientProvider>
    </ErrorBoundary>
);

export { App };
