import React from 'react';

import { AffiliateModalError } from '../AffiliateModalError/AffiliateModalError';
import { useAffiliate } from '../../../../Hooks/useModal';

export const EmailModalError = () => {
    const { showAffiliationPopup } = useAffiliate();

    return (
        <AffiliateModalError
            title="This email is linked to another DeepMine account"
            description="You cannot become a scout because your email is linked to another DeepMine account"
            buttonText="Bind another email"
            onClick={() => showAffiliationPopup('email')}
        />
    );
};
