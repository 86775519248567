import React from 'react';
import { useApp } from '../../Data/AppContext';

import { ContentContainer, Sidebar } from '../../Components';
import MobileCaption from '../../Components/Ui/MobileCaption/MobileCaption';
import Caption from '../../Components/Ui/Caption/Caption';
import FaqList from './FaqList';

const InformationPage = () => {
    const { isAuthenticated, sidebar } = useApp();

    return (
        <>
            <ContentContainer>
                <div className="information_page">
                    <Caption isAuthenticated={isAuthenticated}>FAQ</Caption>
                    <MobileCaption text="FAQ" position="center" />
                    <FaqList />
                </div>
            </ContentContainer>
            {sidebar && <Sidebar />}
        </>
    );
};

export default InformationPage;
