import React, { useContext } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';
import { ConnectWaxButton } from '../ConnectWaxButton';
import { Link } from '../Ui/v2';
import { ReactComponent as PurseImg } from '../../assets/img/icons/purse.svg';

const Information = () => {
    const { showModal } = useContext(UALContext);

    return (
        <div className="unauthorized_content">
            <div className="unauthorized_wrapper">
                <div className="connect_wallet">
                    <PurseImg className="connect_wallet__icon" />
                    <h3 className="connect_wallet__title">WAX Wallet</h3>
                    <span className="connect_wallet__text">
                        Please connect your wallet
                        <br />
                        to enter the Shelter
                    </span>

                    <ConnectWaxButton />
                </div>
                <div className="information__container">
                    <div className="welcome-info">
                        <h2 className="welcome-info__title">
                            Howdy, stranger!
                        </h2>
                        <p className="welcome-info__text">
                            Welcome to Shelter City, the capital of Eleazar.
                        </p>
                        <p className="welcome-info__text">
                            You need to{' '}
                            <span
                                className="welcome-info__connect-wax"
                                onClick={showModal}
                            >
                                connect your WAX wallet
                            </span>{' '}
                            and own at least one DeepMine NFT to utilize the
                            full power of the Shelter facilities.
                        </p>
                        <p className="welcome-info__text">
                            Most of our NFTs can be found on{' '}
                            <Link
                                active
                                href="https://wax.atomichub.io/explorer/collection/deepminegame"
                                target="_blank"
                                rel="noreferrer"
                            >
                                AtomicHub
                            </Link>{' '}
                            marketplace, but before you go there, feel free to
                            browse{' '}
                            <RouteLink
                                to="/Store"
                                className="dm-link dm-link--active"
                            >
                                the Shelter Store
                            </RouteLink>{' '}
                            for some cool and rare things.
                        </p>

                        <div className="welcome-info__guide">
                            <p className="welcome-info__guide-title">
                                To acquire and be able to stake your NFTs in the
                                Shelter, follow these steps:
                            </p>
                            <ul className="welcome-info__guide-list">
                                <li>
                                    <Link
                                        active
                                        href="https://wallet.wax.io/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Create WAX Wallet
                                    </Link>
                                </li>
                                <li>
                                    Acquire Deep Mine NFTs on{' '}
                                    <Link
                                        active
                                        href="https://wax.atomichub.io/explorer/collection/deepminegame"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        AtomicHub
                                    </Link>{' '}
                                    and come back to the Shelter
                                </li>
                                <li>
                                    Connect your WAX wallet and enjoy your stay!
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Information;
