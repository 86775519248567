import React, { useEffect } from 'react';
import { ContentContainer } from '../../Components';
import { Loader, PageTitle, EmptyScreen } from '../../Components/Ui/v2';
import { useApp } from '../../Data/AppContext';
import { CardsList } from './components';
import { StoreFilters } from './StoreFilters';
import { ReactComponent as Icon } from '../../assets/img/dont-have-auctions.svg';
import { ReactComponent as CardsImg } from '../../assets/img/cards-img.svg';
import { useStore } from './hooks/useStore';
import { useStore as useStateStore } from './useStore';
import { LOT_TYPE } from './constants';
import { filterCards } from '../../Helpers';
import { useUser } from '../../Hooks/useUser';
import { useUserStats } from './hooks/useUserStats';
import { getCardStats } from './services/stats';

export const StorePage = () => {
    const { data, isSuccess, isError } = useStore();
    const { accountName } = useUser() || {};
    const userStats = useUserStats(accountName);
    const { toggleSidebarHadler } = useApp();

    const storeData = data?.filter((item) => item.lot_type === LOT_TYPE.Store);
    const { coin, rarity, isOnlyActiveShowing } = useStateStore(
        (state) => state.filters
    );
    const filteredData = filterCards({
        data: storeData,
        coin,
        rarity,
        isOnlyActiveShowing,
    })?.filter((card) => {
        const { isOutOfLimit } = getCardStats(userStats, accountName, card);
        return !isOutOfLimit;
    });

    useEffect(() => {
        toggleSidebarHadler(false);
    }, []);

    const renderContent = () => {
        if (isError) return 'Something went wrong';

        if (!isSuccess) return <Loader text="Loading items..." />;

        if (!storeData?.length) {
            return (
                <EmptyScreen
                    w={220}
                    icon={<CardsImg fill="none" />}
                    title="OOOPS! THERE ARE NO ITEMS"
                >
                    we sold all the goods. Don't worry, we're adding new
                    products
                </EmptyScreen>
            );
        }

        if (!filteredData?.length) {
            return (
                <EmptyScreen
                    w={220}
                    icon={<Icon />}
                    title="OOOPS! THERE ARE NO ITEMS"
                >
                    change your filters to see other items
                </EmptyScreen>
            );
        }

        return (
            <div className="store-page__list">
                <CardsList data={filteredData} userStats={userStats} />
            </div>
        );
    };

    return (
        <ContentContainer>
            <PageTitle withoutSidebar>Store</PageTitle>

            <div className="store-page">
                <div className="store-page__content">
                    <StoreFilters />
                    {renderContent()}
                </div>
            </div>
        </ContentContainer>
    );
};
