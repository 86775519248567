import React from 'react';
import { AchievementModalContainer } from '../AchievementModalContainer/AchievementModalContainer';
import RecruitIcon from '../../../../Containers/AffiliatePage/img/Recruit.png';
import NewcomerIcon from '../../../../Containers/AffiliatePage/img/Newcomer.png';
import MotivatorIcon from '../../../../Containers/AffiliatePage/img/Motivator.png';
import EncouragerIcon from '../../../../Containers/AffiliatePage/img/Encourager.png';
import InspirerIcon from '../../../../Containers/AffiliatePage/img/Inspirer.png';
import LeaderIcon from '../../../../Containers/AffiliatePage/img/Leader.png';

const privileges = {
    pin: [
        'You will receive a $title$ pin.',
        '$title$ pin owners will be eligible for various bonuses and rewards in the future.',
    ],
    ntt: [
        'You will receive $title$ NTT.',
        '$title$ NTT owners will be eligible for various bonuses and rewards in the future.',
    ],
};

const achievements = {
    recruit: {
        Image: <img src={RecruitIcon} alt="" />,
        title: 'Recruit',
        privileges,
        steps: ['Bind your email address', 'Bind your Discord account'],
        id: 'recruit',
    },
    newcomer: {
        Image: <img src={NewcomerIcon} alt="" />,
        title: 'Newcomer',
        privileges,
        steps: ['Bind your email address', 'Bind your Discord account'],
        id: 'newcomer',
    },
    motivator: {
        Image: <img src={MotivatorIcon} alt="" />,
        title: 'Motivator',
        privileges,
        steps: ['Invite 3+ people to DeepMine using your recruitment link'],
        id: 'motivator',
    },
    encourager: {
        Image: <img src={EncouragerIcon} alt="" />,
        title: 'Encourager',
        privileges,
        steps: ['Invite 10+ people to DeepMine using your recruitment link'],
        id: 'encourager',
    },
    inspirer: {
        Image: <img src={InspirerIcon} alt="" />,
        title: 'Inspirer',
        privileges,
        steps: ['Invite 30+ people to DeepMine using your recruitment link'],
        id: 'inspirer',
    },
    leader: {
        Image: <img src={LeaderIcon} alt="" />,
        title: 'Leader',
        privileges,
        steps: ['Invite 50+ people to DeepMine using your recruitment link'],
        id: 'leader',
    },
};

export const AchievementModal = ({ type }) => (
    <AchievementModalContainer {...achievements[type]} />
);
