import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useApp } from '../../../Data/AppContext';
import { Button, Input } from '../../../Components/Ui/v2';
import { useTransaction } from '../hooks/useTransaction';
import { TOKEN_TYPE } from '../../../Constants';
import { ButtonForCallToAuth } from '../../StorePage/components/supportComponents/ButtonForCallToAuth';

const MIN_BID_STEP = 1;

const anyNonDigit = /\D/;
const onlyDigit = /^\d+$/;
const bidValidation = {
    [TOKEN_TYPE.WAX]: onlyDigit,
    [TOKEN_TYPE.DMP]: onlyDigit,
};

const sanitizeBid = (bid, minBid, priceType) => {
    if (!bidValidation[priceType]?.test(bid) ?? true)
        throw new Error('Invalid bid value');

    if (bid < minBid + MIN_BID_STEP)
        throw new Error('Bid should be higher than the current bid');

    return bid;
};

export const getUserNumberBalance = (balance, type) =>
    Number(balance.replace(` ${type}`, ''));

export const AuctionAssetBidForm = ({
    assetId,
    bid,
    priceType,
    accountName,
}) => {
    const { waxBalance, dmpBalance, isAuthenticated } = useApp();
    const queryClient = useQueryClient();
    const { mutateAsync: makeBid } = useTransaction(
        assetId,
        priceType,
        accountName
    );
    const [bidValue, setBidValue] = useState(bid + 1);

    const balanceType = {
        [TOKEN_TYPE.WAX]: waxBalance,
        [TOKEN_TYPE.DMP]: dmpBalance,
    };

    const handleSetValue = (event) => {
        const { value } = event.target;
        setBidValue(value.replace(anyNonDigit, ''));
    };

    const handleCallAuth = () => {
        sessionStorage.setItem('callToAuthTitle', 'Join the Auction');
        sessionStorage.setItem(
            'callToAuthDescription',
            'Please connect your WAX wallet to participate in the Auction'
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newBid = Number.parseFloat(bidValue);
        const bidTypeBalance = balanceType[priceType];
        const balanceCurrency = +priceType === TOKEN_TYPE.WAX ? 'WAX' : 'DMP';

        if (newBid > getUserNumberBalance(bidTypeBalance, balanceCurrency)) {
            return toast.error('Overdrawn balance');
        }

        try {
            await makeBid({
                quantity: sanitizeBid(Number(bidValue), bid, priceType),
            });
            toast.success('Your bid is accepted');
            queryClient.invalidateQueries(['auction-history', assetId]);
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="auction-asset-page-options-form"
        >
            <Input value={bidValue} onChange={handleSetValue} />
            {isAuthenticated ? (
                <Button size="l" type="submit">
                    Place Bid
                </Button>
            ) : (
                <ButtonForCallToAuth
                    text="Place Bid"
                    onClick={handleCallAuth}
                />
            )}
        </form>
    );
};
