import React from 'react';
import classnames from 'classnames';
import { Button } from '../v2/Button';

const MobileCaption = ({ text, btnText, handler, position }) => {
    return (
        <div className={classnames('mobile_caption', { center: position })}>
            <h1>{text}</h1>
            {btnText && <Button onClick={handler}>{btnText}</Button>}
        </div>
    );
};

export default MobileCaption;
