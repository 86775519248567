import React, { useCallback, useState } from 'react';
import Countdown from 'react-countdown';
import cn from 'classnames';

import { countdownRenderer } from '../../StorePage/components/StoreCard/helpers';
import { StoreCard } from '../../StorePage/components';
import { pluralize } from '../../../Helpers';

const WhitelistRound = ({
    startAt,
    finishAt,
    isInWhitelist,
    userStats,
    data,
    isRoundFinished,
    onRoundFinish,
    hasWideCards,
}) => {
    const [isRoundRunning, setIsRoundRunning] = useState(
        !isRoundFinished && Date.now() >= startAt
    );
    const onRoundStart = useCallback(
        () => setIsRoundRunning(true),
        [setIsRoundRunning]
    );

    return (
        <div className="drop-category drop-category--whitelist">
            <div className="drop-category__top">
                <span className="drop-category__title">
                    Whitelist Drop (Only for whitelisters)
                </span>
                <span className="drop-category__counter">
                    {isRoundFinished && 'SALE ENDED'}
                    {!isRoundRunning && !isRoundFinished && (
                        <>
                            Starts in{' '}
                            <Countdown
                                renderer={countdownRenderer}
                                date={startAt}
                                onComplete={onRoundStart}
                            />
                        </>
                    )}
                    {isRoundRunning && !isRoundFinished && (
                        <>
                            Ends in{' '}
                            <Countdown
                                renderer={countdownRenderer}
                                date={finishAt}
                                onComplete={onRoundFinish}
                            />
                        </>
                    )}
                </span>
                <span className="drop-category__info">
                    {data.length} {pluralize(data.length, 'item')}
                </span>
            </div>
            <div
                className={cn('drop-category__list', {
                    'drop-category__list--wide': hasWideCards,
                })}
            >
                {data.map((lot) => (
                    <StoreCard
                        key={lot.id}
                        isInWhitelist={isInWhitelist}
                        roundStartAt={startAt}
                        saleEnd={isRoundFinished}
                        userStats={userStats}
                        type="whitelist"
                        card={lot}
                        isWide={hasWideCards}
                        context="Drop"
                    />
                ))}
            </div>
        </div>
    );
};

export { WhitelistRound };
