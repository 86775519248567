import ReactTooltip from 'react-tooltip';
import React from 'react';
import { Badge } from './Badge';
import { Restriction } from '../supportComponents/Restriction';
import { ReactComponent as InfoIcon } from '../../../../assets/img/icons/info.svg';
import { Tooltip } from './Tooltip';
import { getImagePath } from '../../../../Helpers';

export const CardImage = ({
    status,
    isSaleEnd,
    isOutOfStock,
    isOutOfLimit,
    notInWhitelist,
    card,
}) => (
    <div className="store-card__image-container">
        {status && <Badge className="store-card__badge" status={status} />}
        <Restriction
            isSaleEnd={isSaleEnd}
            isOutOfStock={isOutOfStock}
            isOutOfLimit={isOutOfLimit}
            notInWhitelist={notInWhitelist}
        />
        <div className="store-card__preview">
            <img src={getImagePath(Number(card.template_id))} alt={card.name} />
            <div
                data-tip
                data-for={`store-card__${card.id}`}
                className="card-tooltip store-card__info"
            >
                <InfoIcon />
            </div>
            <ReactTooltip
                className="card-tooltip__react-tooltip"
                id={`store-card__${card.id}`}
                effect="solid"
                place="bottom"
            >
                <Tooltip data={card} />
            </ReactTooltip>
        </div>
    </div>
);
