import React, { useMemo, useEffect } from 'react';
import { Loader, PageTitle, Tabs } from '../../Components/Ui/v2';
import { ContentContainer } from '../../Components';
import AllAuction from './AllAuction';
import MyAuction from './MyAuction';
import Filters from './components/filters';
import {
    AllAuctionStatsSidebar,
    MyAuctionStatsSidebar,
} from './AuctionSidebar';
import { useAuctionStore } from './store/useAuctionStore';
import { useAuctions } from './hooks/useAuction';
import { AUCTION_TABS } from './constants';
import { NotFoundAuctions } from './components/NotFoundAuctions';
import { isAuctionFinished } from './utils';
import { useApp } from '../../Data/AppContext';

const AUCTION_TYPE_MAP = {
    [AUCTION_TABS.ALL]: AllAuction,
    [AUCTION_TABS.MY]: MyAuction,
};

const SIDEBAR_TYPE_MAP = {
    [AUCTION_TABS.ALL]: AllAuctionStatsSidebar,
    [AUCTION_TABS.MY]: MyAuctionStatsSidebar,
};

const AUCTION_TAB_ITEMS = [
    { name: 'All Auctions', value: AUCTION_TABS.ALL },
    { name: 'My Auctions', value: AUCTION_TABS.MY },
];

const sortByActive = (cards) =>
    [...(cards ?? [])].sort(
        (first, second) => isAuctionFinished(first) - isAuctionFinished(second)
    );

const AuctionContainer = ({ data, isError, isSuccess }) => {
    const filters = useAuctionStore((state) => state.filters);
    const activeTab = useAuctionStore((state) => state.activeTab);
    const setActiveTab = useAuctionStore((state) => state.setActiveTab);

    const AuctionType = AUCTION_TYPE_MAP[activeTab];

    const filteredData = data?.filter(
        (card) => card.price_type === filters.coin
    );
    const sorted = useMemo(() => sortByActive(filteredData), [filteredData]);

    if (isError) {
        return 'Something went wrong';
    }
    if (!isSuccess) {
        return <Loader text="Loading auctions..." />;
    }
    if (!filteredData?.length) {
        return <NotFoundAuctions />;
    }

    return <AuctionType cards={sorted} setActiveTab={setActiveTab} />;
};

const AuctionPage = () => {
    const activeTab = useAuctionStore((state) => state.activeTab);
    const setActiveTab = useAuctionStore((state) => state.setActiveTab);

    const { toggleSidebarHadler, isAuthenticated } = useApp();
    const {
        data: auctionsData,
        isSuccess: isAuctionSuccess,
        isError: isAuctionError,
    } = useAuctions();
    const Sidebar = SIDEBAR_TYPE_MAP[activeTab];

    useEffect(() => {
        toggleSidebarHadler(true);
    }, [isAuthenticated]);

    return (
        <>
            <ContentContainer>
                <div className="auction-page">
                    <PageTitle>Auction</PageTitle>

                    <div className="auction-page__top">
                        <Tabs
                            active={activeTab}
                            onChange={setActiveTab}
                            items={AUCTION_TAB_ITEMS}
                        />
                    </div>
                    <div className="auction-page__content">
                        <div className="auction-page__content-left-bg" />
                        <div className="auction-page__content-right-bg" />
                        <div className="auction-page__filters">
                            {/* note: disable while loading ?? */}
                            <Filters />
                        </div>
                        <div className="auction-page__list">
                            <AuctionContainer
                                data={auctionsData}
                                isSuccess={isAuctionSuccess}
                                isError={isAuctionError}
                            />
                        </div>
                    </div>
                </div>
            </ContentContainer>
            <Sidebar data={auctionsData} />
        </>
    );
};

export default AuctionPage;
