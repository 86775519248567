import React from 'react';
import { ReactComponent as Icon } from '../../../assets/img/warning-xl.svg';

export const NotFoundAuctions = () => {
    return (
        <div className="auction-page-dont-have-auctions">
            <Icon />
            <p className="auction-page-dont-have-auctions__title">
                OOOPS! THERE ARE NO AUCTIONS
            </p>
            <span style={{ maxWidth: 190 }}>
                change your filters to see other auctions
            </span>
        </div>
    );
};
