export const CONTRACT_TABLE_NAMES = {
    auctions: 'auctions',
    store: 'store',
    collected: 'collected',
    stake: 'stake',
    accounts: 'accounts',
    stakingconf: 'stakingconf',
    dateconf: 'dateconf',
    multiplier: 'multiplier',
    lots: 'lots',
    users: 'users',
    whitelist: 'whitelist',
    dropconfig: 'dropconfig',
    members: 'members',
    achievement: 'deepmineachv',
};

export const CONTRACT_NAMES = {
    market: 'deepminemrkt',
    auction: 'deepmineauct',
    stak: 'deepminestak',
    game: 'deepminegame',
    tokn: 'deepminetokn',
    eosioToken: 'eosio.token',
};
