import React from 'react';

import { AffiliateModalError } from '../AffiliateModalError/AffiliateModalError';
import { useAffiliate } from '../../../../Hooks/useModal';

export const DiscordModalError = () => {
    const { showAffiliationPopup } = useAffiliate();

    return (
        <AffiliateModalError
            title="Your Discord account is linked to another DeepMine account"
            description="You cannot become a scout because your Discord account is linked to another DeepMine account"
            buttonText="Bind another Discord account"
            onClick={() => showAffiliationPopup('discord')}
        />
    );
};
