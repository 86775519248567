import React from 'react';
import classnames from 'classnames';

const ButtonLink = ({ onClick, children, to, active }) => {
    return (
        <a
            onClick={onClick}
            href={to}
            className={classnames('btn', { active })}
        >
            <span className="btn__wrap">{children}</span>
        </a>
    );
};

export default ButtonLink;
