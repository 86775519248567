import { CARD_STATUSES } from '../constants';
import { isAuctionFinished } from '../utils';

export const mergeAuctionWithAssetsData = (auctions, assets) => {
    return auctions?.map((item) => {
        const assetsItem = assets.find(
            ({ asset_id }) => asset_id === item.asset_id
        );

        const rarity =
            item.asset_id === '1099736959418'
                ? 'legendary'
                : assetsItem?.data.rarity?.toLowerCase() ?? 'common';

        return {
            ...item,
            name: assetsItem?.name ?? '',
            description: assetsItem?.data.description ?? '',
            template_id: assetsItem?.template.template_id ?? '',
            rarity,
            template_mint: assetsItem?.template_mint ?? '',
            total_template_mints: assetsItem?.template.issued_supply ?? '',
            max_supply_mint: assetsItem?.template.max_supply ?? '',
        };
    });
};

export const mergeAuctionsWithMyAuctions = (
    auctions,
    myAuctions,
    accountName
) => {
    return auctions?.map((item) => {
        const assetsItem = myAuctions.find(({ asset_ids }) =>
            asset_ids.includes(item.asset_id)
        );

        let status = '';
        if (assetsItem) {
            status = CARD_STATUSES.Losing;
        }
        if (item.current_winner === accountName) {
            status = CARD_STATUSES.Winning;
        }
        if (isAuctionFinished(item)) {
            status = CARD_STATUSES.Finished;
        }

        return {
            ...item,
            status,
            iAmIn: !!assetsItem,
        };
    });
};

export const getMyAuctionsCards = (cards) => cards.filter((card) => card.iAmIn);
