import React from 'react';

import cn from 'classnames';
import { useApp } from '../../Data/AppContext';

import ContentBg from '../../Containers/App/ContentBg';
import { CookiesAcceptor } from '../CookiesAcceptor';

const ContentContainer = ({ children, className }) => {
    const {
        showWelcome,
        isAuthenticated,
        toggleSidebarHadler,
        showInterface,
        toggleBlurHandler,
        toggleShelterHandler,
        showingWelcomeScreen,
        userItemsFetched,
        stakedList,
        unstakedList,
        hasNtt,
    } = useApp();

    return (
        <div className={cn('content', className)}>
            <ContentBg
                isAuthenticated={
                    isAuthenticated &&
                    userItemsFetched &&
                    (!!stakedList.length || !!unstakedList.length || hasNtt)
                }
                toggleSidebarHadler={toggleSidebarHadler}
                showInterface={showInterface}
                toggleBlurHandler={toggleBlurHandler}
                toggleShelterHandler={toggleShelterHandler}
                showingWelcomeScreen={showingWelcomeScreen}
            />

            <div
                className={cn('content__container', {
                    not_auth:
                        !isAuthenticated ||
                        showWelcome ||
                        (userItemsFetched &&
                            !stakedList.length &&
                            !unstakedList.length &&
                            !hasNtt),
                })}
            >
                <div className="content__left-bg" />
                <div className="content__right-bg" />
                {children}
            </div>
            <CookiesAcceptor />
        </div>
    );
};

export default ContentContainer;
