import { useQuery } from 'react-query';
import { getDataFromAtomicApi } from '../../../Helpers';

const getQueryParams = (lots) =>
    `templates?ids=${lots
        .map(({ template_id }) => template_id)
        .join(',')}&limit=${lots.length}&page=1`;

const useTemplates = ({ lots, lotId }, options = {}) => {
    const templates = useQuery(
        ['storeTemplates', lotId],
        () => getDataFromAtomicApi(getQueryParams(lots)),
        options
    );

    return templates;
};

export { useTemplates };
