import React from 'react';
import cn from 'classnames';

import { toast } from 'react-toastify';
import { AffiliateSectionContainer } from '../AffiliateSectionContainer/AffiliateSectionContainer';
import { ReactComponent as CopyIcon } from '../../img/copy.svg';
import { ReactComponent as InvitationIcon } from '../../img/invitation.svg';
import { Button } from '../../../../Components/Ui/v2';
import { useApp } from '../../../../Data/AppContext';
import { useAffiliate } from '../../../../Hooks/useModal';

import './AffiliateLink.scss';

export const AffiliateLink = () => {
    const { authData } = useApp();
    const { showAffiliationPopup } = useAffiliate();
    const isDisabled = !authData?.discord_confirmed;
    const generatedLink = `${window.location.origin}/welcome?referrer=${authData?.referrer_code}`;
    const handleCopyGenUrl = () => {
        if (generatedLink) {
            navigator.clipboard.writeText(generatedLink);
            toast.success('Copied');
        }
    };

    const handleClick = () => {
        if (!authData.email_confirmed) {
            showAffiliationPopup('email');
        } else if (!authData?.discord_confirmed) {
            showAffiliationPopup('discord');
        }
    };

    return (
        <AffiliateSectionContainer title="My recruitment link">
            <div className="affiliate-link-container">
                <div
                    className={cn('affiliate-link__input', {
                        'affiliate-link__input--disabled': isDisabled,
                    })}
                >
                    <div>{generatedLink}</div>
                    <CopyIcon
                        className="affiliate-link__copy"
                        onClick={handleCopyGenUrl}
                    />
                </div>
                {!isDisabled && (
                    <div className="referrals-empty">
                        <div className="referrals-empty__notice">
                            To become your recruit, your friend who followed the
                            recruitment link must connect their WAX wallet,
                            Discord and confirm the email. Note: If you had
                            recruits before the 1st of June, some of them may
                            disappear from your recruits if they haven’t
                            completed the aforementioned steps, but all your
                            achievements will remain.
                        </div>
                    </div>
                )}
                {isDisabled && (
                    <div className="affiliate-link__invitation">
                        <InvitationIcon />
                        <div className="affiliate-link__invitation__title">
                            Invite your friends to&nbsp;PLAY&nbsp;DEEPMINE
                            together
                        </div>
                        <div className="affiliate-link__invitation__description">
                            Press the button below to generate your unique
                            recruitment link
                        </div>
                        <Button
                            className="affiliate-link__invitation__button"
                            onClick={handleClick}
                        >
                            Become a scout
                        </Button>
                    </div>
                )}
            </div>
        </AffiliateSectionContainer>
    );
};
