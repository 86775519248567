import React from 'react';
import cn from 'classnames';
import { Alert, LoginButton } from '../../Components';

export const WelcomeContent = () => {
    return (
        <div className={cn('main-screen')}>
            <Alert
                type="success"
                caption="WELCOME, CITIZEN!"
                text="Access granted"
            />

            <LoginButton type="success" />
        </div>
    );
};
