import {
    SHOW_POPUP,
    HIDE_POPUP,
    LIGHTEN_SCREEN,
    DARKEN_SCREEN,
    USER_LOGIN,
    SET_USER_DATA,
    TOGGLE_INTERFACE,
    SHOW_SIDEBAR,
    USER_LOGOUT,
    TOGGLE_BLUR,
    TOGGLE_SHELTER,
    SCROLL_SHELTER,
    TOGGLE_STAKING_POPUP,
    TOGGLE_REWARDS_POPUP,
    TOGGLE_RARE_FILTER,
    TOGGLE_TERM_FILTER,
    SET_RARE_FILTER,
    SET_TERM_FILTER,
    USER_STAKED_ITEMS,
    ADD_ITEMS_TO_USER_STAKED_ITEMS,
    REMOVE_ITEMS_FROM_USER_STAKED_ITEMS,
    USER_NOT_STAKED_ITEMS,
    ADD_ITEMS_TO_USER_NOT_STAKED_ITEMS,
    REMOVE_ITEMS_FROM_USER_NOT_STAKED_ITEMS,
    USER_ITEMS_FETCHED,
    USER_WAX_BALANCE,
    USER_DMP_BALANCE,
    TRANSACTION_INFO,
    SELECTED_ITEMS_FOR_STAKE,
    SELECTED_ITEMS_FOR_UNSTAKE,
    SET_STAKING_CONFIG,
    SHOW_MORE,
    SHOW_WELCOME,
    PRE_LAUNCH_FINISH_TIMESTAMP,
    MINING_MULTIPLIER,
    SET_AFFILIATE_MODAL_DATA,
    SET_AUTH_DATA,
    SET_AUTH_LINKS,
    SET_REFERRAL_DATA,
    SET_IS_EMAIL_SENT,
    SET_HAS_NTT,
    SET_AFFILIATE_ACHIEVEMENTS,
    SET_IS_BANNER_SHOWING,
} from './AppActionTypes';

// TODO:: Здесь почти вся логика тестовая и её менять надо под логику сайта.

const AppReducer = (state, action) => {
    switch (action.type) {
        case USER_LOGIN: {
            return { ...state, isAuthenticated: true };
        }
        case SET_USER_DATA: {
            return { ...state, userData: action.data };
        }

        case USER_STAKED_ITEMS: {
            return {
                ...state,
                stakedList: action.value,
                stakedListFetched: true,
            };
        }

        case ADD_ITEMS_TO_USER_STAKED_ITEMS: {
            return {
                ...state,
                stakedList: [...state.stakedList, ...action.value],
            };
        }

        case REMOVE_ITEMS_FROM_USER_STAKED_ITEMS: {
            return {
                ...state,
                stakedList: state.stakedList.filter(
                    ({ asset_id }) =>
                        !action.value
                            .map(({ asset_id }) => asset_id)
                            .includes(asset_id)
                ),
            };
        }

        case USER_NOT_STAKED_ITEMS: {
            return {
                ...state,
                unstakedList: action.value,
                unstakedListFetched: true,
            };
        }

        case ADD_ITEMS_TO_USER_NOT_STAKED_ITEMS: {
            return {
                ...state,
                unstakedList: [...state.unstakedList, ...action.value],
            };
        }

        case REMOVE_ITEMS_FROM_USER_NOT_STAKED_ITEMS: {
            return {
                ...state,
                unstakedList: state.unstakedList.filter(({ asset_id }) => {
                    return !action.value
                        .map(({ asset_id }) => asset_id)
                        .includes(asset_id);
                }),
            };
        }

        case USER_ITEMS_FETCHED: {
            return { ...state, userItemsFetched: action.value };
        }

        case SHOW_POPUP: {
            return { ...state, showPopup: true, popupType: action.value };
        }
        case HIDE_POPUP: {
            return { ...state, showPopup: false, popupType: null };
        }
        case LIGHTEN_SCREEN: {
            return { ...state, shadow: false };
        }
        case DARKEN_SCREEN: {
            return { ...state, shadow: true };
        }
        case TOGGLE_INTERFACE: {
            return { ...state, showInterface: action.value };
        }
        case SHOW_SIDEBAR: {
            return { ...state, sidebar: action.value };
        }
        case USER_LOGOUT: {
            return {
                ...state,
                isAuthenticated: false,
                userData: null,
                authData: {},
                sidebar: false,
                scrollShelter: false,
                stakedList: [],
                stakedListFetched: false,
                unstakedList: [],
                unstakedListFetched: false,
                userItemsFetched: false,
                waxBalance: 0,
                waxBalanceFetched: false,
                dmpBalance: 0.0,
                dmpBalanceFetched: false,
                selectedItemsForStake: [],
                selectedItemsForUnstake: [],
                transactionsInfo: [],
                transactionInfoFetched: false,
                showWelcome: true,
            };
        }
        case TOGGLE_BLUR: {
            return { ...state, blur: action.value };
        }
        case TOGGLE_SHELTER: {
            return { ...state, shelter: action.value };
        }
        case SCROLL_SHELTER: {
            return { ...state, scrollShelter: action.value };
        }
        case TOGGLE_STAKING_POPUP: {
            return { ...state, stakingPopup: action.value };
        }
        case TOGGLE_REWARDS_POPUP: {
            return { ...state, rewardsPopup: action.value };
        }
        case TOGGLE_RARE_FILTER: {
            return { ...state, toggleRareFilter: action.value };
        }
        case TOGGLE_TERM_FILTER: {
            return { ...state, toggleTermFilter: action.value };
        }

        case SET_RARE_FILTER: {
            return { ...state, activeRareFilter: action.value };
        }
        case SET_TERM_FILTER: {
            return { ...state, activeTermFilter: action.value };
        }

        case USER_WAX_BALANCE: {
            return {
                ...state,
                waxBalance: action.value,
                waxBalanceFetched: true,
            };
        }

        case USER_DMP_BALANCE: {
            return {
                ...state,
                dmpBalance: action.value,
                dmpBalanceFetched: true,
            };
        }

        case SELECTED_ITEMS_FOR_STAKE: {
            return { ...state, selectedItemsForStake: action.value };
        }

        case SELECTED_ITEMS_FOR_UNSTAKE: {
            return { ...state, selectedItemsForUnstake: action.value };
        }

        case TRANSACTION_INFO: {
            return {
                ...state,
                transactionsInfo: action.value,
                transactionInfoFetched: true,
            };
        }

        case SET_STAKING_CONFIG: {
            return {
                ...state,
                stakingConfig: action.value,
                stakingConfigFetched: true,
            };
        }

        case SHOW_MORE: {
            return {
                ...state,
                stakedList: [...state.stakedList, ...action.value],
            };
        }

        case SHOW_WELCOME: {
            return { ...state, showWelcome: action.value };
        }

        case PRE_LAUNCH_FINISH_TIMESTAMP: {
            return {
                ...state,
                preLaunchFinishTimestamp: action.value,
                preLaunchFinishTimestampFetched: true,
            };
        }

        case MINING_MULTIPLIER: {
            return {
                ...state,
                miningMultiplier: action.value,
                miningMultiplierFetched: true,
            };
        }

        case SET_AFFILIATE_MODAL_DATA: {
            return {
                ...state,
                affiliateModalData: action.value,
            };
        }

        case SET_REFERRAL_DATA: {
            return {
                ...state,
                referralData: action.value,
            };
        }

        case SET_AUTH_DATA: {
            return {
                ...state,
                authData: action.value,
            };
        }

        case SET_AFFILIATE_ACHIEVEMENTS: {
            return {
                ...state,
                affiliateAchievements: action.value,
            };
        }

        case SET_AUTH_LINKS: {
            return {
                ...state,
                authLinks: action.value,
            };
        }

        case SET_IS_EMAIL_SENT: {
            return {
                ...state,
                isEmailSent: action.value,
            };
        }

        case SET_HAS_NTT: {
            return {
                ...state,
                hasNtt: action.value,
            };
        }

        case SET_IS_BANNER_SHOWING: {
            return {
                ...state,
                isBannerShowing: action.value,
            };
        }

        default:
            return state;
    }
};

export default AppReducer;
