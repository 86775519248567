import React from 'react';
import ReactTooltip from 'react-tooltip';

export const NoNftTooltip = (props) => (
    <ReactTooltip
        className="tooltip-text__react-tooltip"
        effect="solid"
        place="right"
        arrowColor="#000"
        {...props}
    >
        You need to have DeepMine NFT’s to access this page
    </ReactTooltip>
);
