import React from 'react';

import { Privacy } from '../../Components/Privacy';

import { ContentContainer, Sidebar } from '../../Components';
import MobileCaption from '../../Components/Ui/MobileCaption/MobileCaption';
import Caption from '../../Components/Ui/Caption/Caption';
import { useApp } from '../../Data/AppContext';

export const CookiesPage = () => {
    const { isAuthenticated, sidebar } = useApp();

    return (
        <>
            <ContentContainer>
                <div className="information_page">
                    <Caption isAuthenticated={isAuthenticated}>
                        PRIVACY POLICY
                    </Caption>
                    <MobileCaption text="PRIVACY POLICY" position="center" />
                    <Privacy />
                </div>
            </ContentContainer>
            {sidebar && <Sidebar />}
        </>
    );
};
