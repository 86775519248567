import React from 'react';
import cn from 'classnames';
import loader from '../../assets/img/loader.gif';

const ConnectionLoader = () => (
    <div className={cn('connection-loader')}>
        <img
            className={cn('connection-loader__img')}
            src={loader}
            alt="loading"
        />
        <p className={cn('connection-loader__text')}>
            Establishing a connection to blockchain.
            <br /> Please, do not refresh the page!
        </p>
    </div>
);

export { ConnectionLoader };
