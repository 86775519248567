import React from 'react';

export const EmptyScreen = ({ icon, title, children, w }) => {
    return (
        <section className="dm-empty-screen">
            <div className="dm-empty-screen__container" style={{ width: w }}>
                {icon && <div className="dm-empty-screen__icon">{icon}</div>}
                <h1 className="dm-empty-screen__title">{title}</h1>
                <span className="dm-empty-screen__content">{children}</span>
            </div>
        </section>
    );
};
