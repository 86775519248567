import React from 'react';
import { isMainNet } from '../../../../Config';

import srcDescription from '../../../../assets/img/mystery-pack.png';

export const MysteryBox = ({ template_id }) => {
    const mysteryBoxId = isMainNet ? 514593 : 417446;

    if (template_id !== mysteryBoxId) return null;

    return (
        <>
            <div className="store-product-page-divider" />
            <div className="store-product-page-description">
                <div className="store-product-page-description-title">
                    Details
                </div>
                <div className="store-product-page-description-text">
                    The probability of drawing by rarity (probability applies to
                    each card in the Paсk):
                </div>
                <div className="store-product-page-description-divider" />
                <div className="store-product-page-description-table">
                    <img src={srcDescription} alt="mystery box" />
                </div>
            </div>
        </>
    );
};
