import { useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTemplates } from './useTemplates';
import {
    mergeStoreLotsWithTemplatesData,
    mergeStoreWithAssetsData,
} from './helpers';
import { getStoreLots } from '../services';
import { ENDPOINTS } from '../../../Helpers';
import { useAssets } from '../../AuctionPage/hooks/useAssets';

const useStore = (lotId) => {
    let { current: endpoint } = useRef(ENDPOINTS[0]);

    const store = useQuery(
        ['store', lotId],
        () => getStoreLots(lotId, endpoint),
        {
            retryDelay: 1500,
            select: (data) => data.rows,
            retry: (failureCount) => {
                const requestNumber = failureCount + 1;
                const needRetry = requestNumber <= ENDPOINTS.length;
                if (needRetry) {
                    endpoint = ENDPOINTS[requestNumber];
                }
                return needRetry;
            },
            // refetchInterval: 30000, // 30 seconds
            // refetchIntervalInBackground: true,
        }
    );

    const templates = useTemplates(
        { lots: store.data, lotId },
        {
            enabled: false,
            select: (templates) =>
                mergeStoreLotsWithTemplatesData(store.data, templates),
        }
    );

    const assets = useAssets(
        { items: templates.data, assetsId: lotId },
        {
            enabled: !!templates.data,
            select: (assetsData) =>
                mergeStoreWithAssetsData(templates.data, assetsData),
        }
    );

    useEffect(() => {
        if (store.isFetched && store.data?.length) {
            templates.refetch();
            assets.refetch();
        }
    }, [store.isFetched, store.data]);

    return {
        ...assets,
        isFetching:
            store.isFetching || templates.isFetching || assets.isFetching,
        isSuccess:
            (store.isSuccess && !store.data?.length) ||
            (store.isSuccess && templates.isSuccess && assets.isSuccess),
        isError: store.isError || templates.isError || assets.isError,
    };
};

export { useStore };
