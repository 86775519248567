import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackImg } from '../../../../assets/img/icons/back.svg';

export const BackButton = () => {
    const history = useHistory();

    return (
        <button className="dm-back-button" onClick={() => history.goBack()}>
            <BackImg />
            <span>Go back</span>
        </button>
    );
};
