import { useQuery } from 'react-query';
import { getDataFromAtomicApi } from '../../../Helpers';

const getQueryParams = (items) => {
    const ids = items[0]?.asset_id
        ? items.map(({ asset_id }) => asset_id)
        : items.map(({ asset_ids }) => asset_ids[0]);

    return `assets?ids=${ids.filter((v) => v).join(',')}&limit=${
        items.length
    }&page=1`;
};

const useAssets = ({ items, assetsId }, options = {}) => {
    const assets = useQuery(
        ['assets', assetsId],
        () => {
            return items
                ? getDataFromAtomicApi(getQueryParams(items))
                : undefined;
        },
        options
    );

    return assets;
};

export { useAssets };
