import React, { Fragment } from 'react';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';

import { ReactComponent as ArrowBottom } from '../../../../assets/img/icons/arrow_bot.svg';
import { ReactComponent as ArrowTop } from '../../../../assets/img/icons/arrow_top.svg';

const triggerIconsBySize = {
    m: {
        bottom: ArrowBottom,
        top: ArrowTop,
    },
};

export const Select = ({
    size = 'm',
    selectedItem,
    onChange,
    options = [],
    w,
    defaultTrigger,
}) => {
    const triggerIcons = triggerIconsBySize[size];

    const getSelectLabel = () => {
        return (
            options.find((item) => item.value === selectedItem)?.name ??
            defaultTrigger
        );
    };

    return (
        <Listbox
            as="div"
            className={classNames('dm-select', `size-${size}`)}
            value={selectedItem}
            onChange={onChange}
            style={{ width: w }}
        >
            {({ open }) => (
                <>
                    <Listbox.Button
                        className={classNames('dm-select-trigger', { open })}
                    >
                        <span>{getSelectLabel()}</span>
                        {open ? <triggerIcons.top /> : <triggerIcons.bottom />}
                    </Listbox.Button>
                    <Listbox.Options className="dm-select-options">
                        {options.map((option) => (
                            <Listbox.Option
                                as={Fragment}
                                key={option.name}
                                value={option.value}
                            >
                                {({ active, selected }) => (
                                    <li
                                        className={classNames(
                                            'dm-select-option',
                                            { active, selected }
                                        )}
                                    >
                                        {option.name}
                                    </li>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </>
            )}
        </Listbox>
    );
};
