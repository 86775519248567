import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { UALContext } from 'ual-reactjs-renderer';
import classnames from 'classnames';
import { useApp } from '../../Data/AppContext';

import BackButton from '../Ui/BackButton/BackButton';
import ButtonLink from '../Ui/ButtonLink/ButtonLink';
import { Tabs } from '../Ui/v2';

import InformationBtn from './InformationBtn';
import SelectCard from './SelectCard';
import FilterSelector from '../Ui/FilterSelector/FilterSelector';

import {
    UNSTAKE_PAGE,
    TERM_ITEMS_TYPES,
    RARITY_ITEM_TYPES,
    RARITY_LABEL,
    TERM_LABEL,
    STAKING_TABS,
} from '../../Constants';

const BtnBlock = ({
    faq = false,
    scroll,
    stakeOrUnstakeBtnText,
    stakeOrUnstakeBtnHandler,
    page,
    type,
    setPage,
}) => {
    const { showModal } = useContext(UALContext);
    const history = useHistory();

    const {
        isAuthenticated,
        toggleRareFilter,
        toggleRareFilterHandler,
        toggleTermFilter,
        toggleTermFilterHandler,
        setActiveRareFilter,
        setActiveTermFilter,
        activeRareFilter,
        activeTermFilter,
    } = useApp();

    useEffect(() => {
        return () => {
            setActiveRareFilter('');
            setActiveTermFilter('');
        };
    }, []);

    const handleLogin = () => {
        history.push('/');
        showModal();
    };

    const raritySelectFilterHandler = (value) => {
        setActiveRareFilter(value);
        toggleRareFilterHandler(false);
    };

    const termSelectFilterHandler = (value) => {
        setActiveTermFilter(value);
        toggleTermFilterHandler(false);
    };

    if (isAuthenticated && !faq) {
        return (
            <div
                className={classnames('btn_block btn_block__is_auth', {
                    btn_block__scroll: scroll,
                    rewards: type === 'rewards',
                })}
            >
                <div className="btn_container">
                    <div className="page_type">
                        <div className="mobile_page_btn">
                            <Tabs
                                active={page}
                                onChange={setPage}
                                items={STAKING_TABS}
                            />
                        </div>

                        <div className="filters__container">
                            <div className="filter_block">
                                <FilterSelector
                                    tag="Filter by:"
                                    active={toggleRareFilter}
                                    selectedValue={activeRareFilter}
                                    items={RARITY_ITEM_TYPES}
                                    selectorToggle={() =>
                                        toggleRareFilterHandler(
                                            !toggleRareFilter
                                        )
                                    }
                                    selectValue={raritySelectFilterHandler}
                                    label={activeRareFilter || RARITY_LABEL}
                                />

                                {page === UNSTAKE_PAGE && (
                                    <FilterSelector
                                        active={toggleTermFilter}
                                        selectedValue={activeTermFilter}
                                        items={TERM_ITEMS_TYPES}
                                        selectorToggle={() =>
                                            toggleTermFilterHandler(
                                                !toggleTermFilter
                                            )
                                        }
                                        selectValue={termSelectFilterHandler}
                                        label={activeTermFilter || TERM_LABEL}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="selects-cards">
                        <SelectCard page={page} />

                        {stakeOrUnstakeBtnText && (
                            <InformationBtn
                                buttonText={stakeOrUnstakeBtnText}
                                buttonHandler={stakeOrUnstakeBtnHandler}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="btn_block">
            <BackButton>Go back</BackButton>

            {!isAuthenticated && faq && (
                <div className="information_btn">
                    <ButtonLink onClick={handleLogin} active>
                        Connect Wallet
                    </ButtonLink>
                </div>
            )}
        </div>
    );
};

export default BtnBlock;
