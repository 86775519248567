import React from 'react';
import { useParams } from 'react-router-dom';
import Countdown from 'react-countdown';

import { formatNumber, getBalanceCount } from '../../../Helpers';
import { ReactComponent as WaxIcon } from '../../../assets/img/icons/wax_icon_orange.svg';
import { ReactComponent as DmpIcon } from '../../../assets/img/icons/dmp_icon.svg';
import { ReactComponent as InfinityIcon } from '../../../assets/img/icons/infinity.svg';
import { countdownRenderer } from '../components/Card';
import { AuctionAssetHistory } from './AuctionAssetHistory';
import { RarityMarker } from '../../../Components/Ui/v2';
import { rarityMap, CURRENCY, TOKEN_TYPE } from '../../../Constants';
import { CARD_STATUSES } from '../constants';
import { getMs } from '../../../Helpers/utils';
import { AuctionAssetPageResult } from './AuctionAssetPageResult';

const Cell = ({ title, value, className = '' }) => {
    return (
        <div className={`auction-asset-page-options-cell ${className}`}>
            <span className="auction-asset-page-options-cell__title">
                {title}
            </span>
            <span className="auction-asset-page-options-cell__value">
                {value}
            </span>
        </div>
    );
};

const CURRENCY_ICON = {
    [TOKEN_TYPE.WAX]: WaxIcon,
    [TOKEN_TYPE.DMP]: DmpIcon,
};

export const AuctionAssetPageOptions = ({
    isFinished,
    setIsFinished,
    card,
    accountName,
}) => {
    const { assetId } = useParams();
    const startingBid = getBalanceCount(card.price_type, card.start_price ?? 0);
    const currentBid = getBalanceCount(card.price_type, card.current_price);
    const minBid = Math.max(startingBid - 1, currentBid ?? 0);

    const isIWon = isFinished && card.current_winner === accountName;
    const isLose =
        isFinished && card.isAmIn && card.current_winner !== accountName;
    const status = isFinished ? CARD_STATUSES.Finished : card.status;
    const currency = CURRENCY[card.price_type];
    const CurrencyIcon = CURRENCY_ICON[card.price_type];

    return (
        <div className="auction-asset-page-options">
            <div className="auction-asset-page-options-main">
                <Cell title="Asset ID" value={assetId} />
                <Cell
                    title="Rarity"
                    value={
                        <>
                            <RarityMarker value={card.rarity} />{' '}
                            {rarityMap[card.rarity]}
                        </>
                    }
                />
                <Cell
                    title="Mint number"
                    value={
                        <>
                            {card.template_mint}
                            {' of '}
                            {card.total_template_mints} (max:{' '}
                            {+card.max_supply_mint || <InfinityIcon />})
                        </>
                    }
                />
                {!isFinished && (
                    <>
                        <Cell
                            title="Starting bid"
                            value={
                                <>
                                    <CurrencyIcon width={14} height={14} />{' '}
                                    <span className="text--bold">
                                        {formatNumber(startingBid)} {currency}
                                    </span>
                                </>
                            }
                        />
                        <Cell
                            title="Auction ends in"
                            value={
                                <span className="text--bold">
                                    <Countdown
                                        renderer={countdownRenderer}
                                        date={new Date(getMs(card.expired_at))}
                                        onComplete={() => setIsFinished(true)}
                                    />
                                </span>
                            }
                        />
                        <Cell
                            className="auction-asset-page-options-current-bid"
                            title="Current bid"
                            value={
                                currentBid ? (
                                    <>
                                        <CurrencyIcon width={14} height={14} />{' '}
                                        <span
                                            className={`auction-asset-page-options-current-bid__text text--bold ${status}`}
                                        >
                                            {formatNumber(currentBid)}{' '}
                                            {currency}
                                        </span>
                                    </>
                                ) : (
                                    'No bids'
                                )
                            }
                        />
                    </>
                )}
            </div>
            <AuctionAssetPageResult
                isFinished={isFinished}
                isIWon={isIWon}
                isLose={isLose}
                assetId={assetId}
                currentBid={currentBid}
                minBid={minBid}
                card={card}
                accountName={accountName}
                currency={currency}
            />
            <div className="auction-asset-page-options-last-bids">
                <p className="auction-asset-page-options-last-bids__title">
                    Last 10 bids
                </p>
                <AuctionAssetHistory
                    assetId={assetId}
                    priceType={card.price_type}
                    accountName={accountName}
                />
            </div>
        </div>
    );
};
