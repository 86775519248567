import { Link, NavLink } from 'react-router-dom';
import React from 'react';
import { AuctionAssetBidForm } from './AuctionAssetBidForm';
import { Button } from '../../../Components/Ui/v2';

export const AuctionAssetPageResult = ({
    isFinished,
    isIWon,
    isLose,
    assetId,
    currentBid,
    minBid,
    card,
    accountName,
    currency,
}) => {
    if (isLose) {
        return (
            <div
                className="auction-asset-page-options__notice"
                style={{ margin: '28px 0 36px' }}
            >
                Auction finished. Unfortunately, you've been outbid. Your bid
                will be returned to your account in 10 minutes. Thank you for
                participating!
                <NavLink to="/Auction">
                    <Button className="auction-page__button-other-lots">
                        Discover other auctions
                    </Button>
                </NavLink>
            </div>
        );
    }

    if (isFinished && isIWon) {
        return (
            <div
                className="auction-asset-page-options__notice"
                style={{ margin: '28px 0' }}
            >
                <p style={{ marginBottom: 16 }}>
                    Congratulations, you've won the auction! The winning lot
                    will be sent to your account in 10 minutes. Thank you for
                    participating!
                </p>
                <Link to="/NFTStaking">
                    <Button>Go to Staking page</Button>
                </Link>
            </div>
        );
    }

    if (isFinished && !isIWon) {
        return (
            <div
                className="auction-asset-page-options__notice"
                style={{ margin: '28px 0 36px' }}
            >
                Auction ended. Winner is {card.current_winner} with a bid of{' '}
                {currentBid} {currency}
                <NavLink to="/Auction">
                    <Button className="auction-page__button-other-lots">
                        Discover other auctions
                    </Button>
                </NavLink>
            </div>
        );
    }

    return (
        <>
            <AuctionAssetBidForm
                assetId={assetId}
                bid={minBid}
                priceType={card.price_type}
                accountName={accountName}
            />
            <div className="auction-asset-page-options__notice">
                Your bid will be frozen until the auction end or until you are
                outbid.
            </div>
        </>
    );
};
