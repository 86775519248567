import React from 'react';
import Card from '../Card';

const CardsList = ({ cards }) => {
    return (
        <div className="cards-list">
            {cards.map((card) => (
                <Card key={card.asset_id} card={card} />
            ))}
        </div>
    );
};

export default CardsList;
