import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';
import { Button } from '../Ui/v2';
import { ReactComponent as WaxLogo } from '../../assets/img/header/header_wax.svg';

const ConnectWaxButton = ({ redirectUrlAfterConnect }) => {
    const { showModal } = useContext(UALContext);
    const history = useHistory();

    const handleLogin = () => {
        history.push(redirectUrlAfterConnect || '/');
        showModal();
    };

    return (
        <Button size="xl" className="connect_wallet__btn" onClick={handleLogin}>
            <WaxLogo /> Connect WAX Wallet
        </Button>
    );
};

export { ConnectWaxButton };
