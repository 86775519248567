import React from 'react';

import { RarityMarker } from '../../../../Components/Ui/v2';
import { rarityMap } from '../../../../Constants';
import { ReactComponent as InfinityIcon } from '../../../../assets/img/icons/infinity.svg';

const Tooltip = ({ data }) => {
    const assetId = data.asset_ids[0] || data.template_id;
    return (
        <>
            <ul className="card-tooltip__list">
                <li className="card-tooltip__list-item">
                    <span className="card-tooltip__title">Asset ID</span>
                    <span className="card-tooltip__text">#{assetId}</span>
                </li>
                {data.rarity && (
                    <li className="card-tooltip__list-item">
                        <span className="card-tooltip__title">Rarity</span>
                        <span className="card-tooltip__text">
                            <RarityMarker value={data.rarity} />{' '}
                            {rarityMap[data.rarity]}
                        </span>
                    </li>
                )}
                {data.template_mint !== '' && data.total_template_mints !== '' && (
                    <li className="card-tooltip__list-item">
                        <span className="card-tooltip__title">Mint number</span>
                        <span className="card-tooltip__text">
                            {data.template_mint} of {data.total_template_mints}{' '}
                            (max: {+data.max_supply_mint || <InfinityIcon />})
                        </span>
                    </li>
                )}
            </ul>

            <ul className="card-tooltip__content-list">
                <li className="card-tooltip__list-item">
                    <span className="card-tooltip__title">Description</span>
                    <span className="card-tooltip__text-secondary">
                        {data.description}
                    </span>
                </li>
            </ul>
        </>
    );
};

export { Tooltip };
