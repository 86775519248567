import React from 'react';
import { Button } from '../Ui/v2/Button';

const Congralutations = () => {
    return (
        <div className="content_block congratulations_modal">
            <h2>Congratulations!</h2>
            <div className="text">Transaction was successful!</div>
            <div className="button_blocks">
                <Button>Ok</Button>
            </div>
        </div>
    );
};

export default Congralutations;
