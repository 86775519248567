export const AUCTION_TABS = {
    ALL: 0,
    MY: 1,
};

export const CARD_STATUSES = {
    Winning: 'winning',
    Losing: 'losing',
    Finished: 'finished',
};
