import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import BoxImg from '../../assets/img/box.png';
import { Button } from '../../Components/Ui/v2';

export const MainPageMonitorLinks = ({ className }) => (
    <div className={cn('show-btn hide-mobile', className)}>
        <NavLink to="/Store">
            <img src={BoxImg} alt="" />

            <Button className="show-btn__button" size="l" use="secondary">
                Store
            </Button>
        </NavLink>
    </div>
);
