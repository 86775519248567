import { useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAssets } from './useAssets';
import {
    mergeAuctionWithAssetsData,
    mergeAuctionsWithMyAuctions,
} from './helpers';
import { getAuctions, getMyAuctions } from './services';
import { ENDPOINTS } from '../../../Helpers';
import { useUser } from '../../../Hooks/useUser';

export const defaultAuctionQueryOptions = (retryCallback) => ({
    retryDelay: 1500,
    select: (data) => data.rows,
    retry: (failureCount) => {
        const requestNumber = failureCount + 1;
        const needRetry = requestNumber <= ENDPOINTS.length;

        if (needRetry) {
            retryCallback(ENDPOINTS[requestNumber]);
        }

        return needRetry;
    },
    // refetchInterval: 30000, // 30 seconds
    // refetchIntervalInBackground: true,
});

export const useAuctions = (assetsId) => {
    const { accountName } = useUser() ?? {};
    let { current: endpoint } = useRef(ENDPOINTS[0]);

    const auctions = useQuery(
        ['auction', assetsId],
        () => getAuctions(assetsId, endpoint),
        defaultAuctionQueryOptions((newEndpoint) => {
            endpoint = newEndpoint;
        })
    );

    const assets = useAssets(
        { items: auctions.data, assetsId },
        {
            enabled: false,
            select: (assetsData) =>
                mergeAuctionWithAssetsData(auctions.data, assetsData),
        }
    );

    const myAuctions = useQuery(
        ['myAuction', accountName, assetsId],
        () => getMyAuctions(accountName, endpoint),
        {
            ...defaultAuctionQueryOptions((newEndpoint) => {
                endpoint = newEndpoint;
            }),
            enabled: false,
            select: (myAuctionsData) =>
                mergeAuctionsWithMyAuctions(
                    assets.data,
                    myAuctionsData.rows,
                    accountName
                ),
        }
    );

    useEffect(() => {
        if (auctions.isFetched && auctions.data?.length) {
            assets.refetch();
        }
    }, [auctions.isFetched, auctions.data]);

    useEffect(() => {
        if (accountName && auctions.isFetched && auctions.data?.length) {
            assets.refetch();
            myAuctions.refetch();
        }
    }, [accountName, auctions.isFetched, auctions.data]);

    const isAllAuctionsSuccess = auctions.isSuccess && assets.isSuccess;
    const isAllAuctionsFetching = auctions.isFetching || assets.isFetching;
    const isAllAuctionsError = auctions.isError || assets.isError;
    const isNoAuctions = auctions.isSuccess && auctions.data?.length === 0;

    return {
        ...(accountName ? myAuctions : assets),
        isFetching: accountName
            ? isAllAuctionsFetching || myAuctions.isFetching
            : isAllAuctionsFetching,
        isSuccess: accountName
            ? (isAllAuctionsSuccess && myAuctions.isSuccess) || isNoAuctions
            : isAllAuctionsSuccess || isNoAuctions,
        isError: accountName
            ? isAllAuctionsError || myAuctions.isError
            : isAllAuctionsError,
    };
};
