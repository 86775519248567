import React from 'react';
import cn from 'classnames';

const PageTitle = ({ children, withoutSidebar }) => (
    <h1
        className={cn('page-title', {
            'page-title__without-sidebar': withoutSidebar,
        })}
    >
        {children}
    </h1>
);

export { PageTitle };
