import React from 'react';
import cn from 'classnames';
import { Badge } from './StoreCard/Badge';
import { DROP_TYPE } from '../constants';
import { getCardStats } from '../services/stats';
import { getStatus } from '../services/status';
import { getImagePath, getIsSaleCardActive } from '../../../Helpers';
import { Restriction } from './supportComponents/Restriction';
import { getMs } from '../../../Helpers/utils';
import customDescriptions from './descriptions.json';

export const StoreProductPageInfo = ({
    card,
    isInWhitelist,
    accountName,
    userStats,
    whitelistPeriod,
    isHiddenSaleEndDetails,
}) => {
    const { isOutOfLimit } = getCardStats(userStats, accountName, card);

    const description = customDescriptions[card.id] || card.description;
    const itemsLeft = card?.asset_ids.length || 0;
    const isOutOfStock = itemsLeft === 0;
    const notInWhitelist =
        card.drop_type === DROP_TYPE.Whitelist && !isInWhitelist;

    const wlFinish = getMs(whitelistPeriod?.wl_finish_at ?? 0);
    const wlStart = getMs(whitelistPeriod?.wl_start_at ?? 0);

    const isSaleEnd = !getIsSaleCardActive({
        card,
        isWLRoundFinished: Date.now() >= wlFinish,
        wlStart,
        wlFinish,
    });

    const status = getStatus(
        isSaleEnd,
        isOutOfStock,
        notInWhitelist,
        isOutOfLimit,
        isHiddenSaleEndDetails
    );

    const restriction =
        isSaleEnd || isOutOfStock || notInWhitelist || isOutOfLimit;

    return (
        <div className="store-product-page-info">
            <h2 className="store-product-page-info__title">{card.name}</h2>
            <div
                className={cn('store-card', 'store-product-page-info__card', {
                    'store-card--restriction':
                        (restriction && !isHiddenSaleEndDetails) ||
                        isOutOfStock ||
                        isOutOfLimit,
                })}
            >
                {status && (
                    <Badge className="store-card__badge" status={status} />
                )}

                <Restriction
                    isSaleEnd={isSaleEnd && !isHiddenSaleEndDetails}
                    notInWhitelist={notInWhitelist && !isHiddenSaleEndDetails}
                    isOutOfStock={isOutOfStock}
                    isOutOfLimit={isOutOfLimit}
                />

                <div className="store-card__preview">
                    <img
                        src={getImagePath(Number(card.template_id))}
                        alt="store-card"
                    />
                </div>
            </div>
            <h3 className="store-product-page-info-description__title">
                Description
            </h3>
            <p className="store-product-page-info-description__text">
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: description }} />
            </p>
        </div>
    );
};
