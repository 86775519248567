import create from 'zustand';
import { AUCTION_TABS } from '../constants';
import { TOKEN_TYPE } from '../../../Constants';

const useAuctionStore = create((set) => ({
    filters: {
        coin: TOKEN_TYPE.DMP,
    },
    setFilter: (filter) =>
        set((state) => ({
            filters: {
                ...state.filters,
                [filter.type]: filter.value,
            },
        })),
    activeTab: AUCTION_TABS.ALL,
    setActiveTab: (activeTab) =>
        set(() => ({
            activeTab,
        })),
}));

export { useAuctionStore };
