import React, { Component } from 'react';
import { Button } from '../../Components/Ui/v2';

class ErrorBoundary extends Component {
    constructor() {
        super();

        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="centered">
                    <h1>Something went wrong</h1>
                    <br />
                    <Button onClick={() => window.location.reload()}>
                        Reload
                    </Button>
                </div>
            );
        }

        return this.props.children;
    }
}

export { ErrorBoundary };
