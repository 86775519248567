import React, { useState } from 'react';

import { AuctionAssetPageInfo } from './AuctionAssetPageInfo';
import { AuctionAssetPageOptions } from './AuctionAssetPageOptions';
import { isAuctionFinished } from '../utils';
import { useUser } from '../../../Hooks/useUser';

export const AuctionAssetPage = ({ card }) => {
    const { accountName } = useUser() ?? {};
    const [isFinished, setIsFinished] = useState(isAuctionFinished(card));

    return (
        <>
            <AuctionAssetPageInfo card={card} isFinished={isFinished} />
            <AuctionAssetPageOptions
                setIsFinished={setIsFinished}
                isFinished={isFinished}
                card={card}
                accountName={accountName}
            />
        </>
    );
};
