import React from 'react';
import cn from 'classnames';
import { CardStatusBadge } from '../components/Card';
import { getImagePath } from '../../../Helpers';
import { CARD_STATUSES } from '../constants';

export const AuctionAssetPageInfo = ({ card, isFinished }) => {
    const status = isFinished ? CARD_STATUSES.Finished : card.status;

    return (
        <div className="auction-asset-page-info">
            <p className="auction-asset-page-info__title">{card.title}</p>
            <div
                className={cn('card', 'auction-asset-page__card', {
                    'card--finished': isFinished,
                })}
            >
                {!!status && (
                    <CardStatusBadge className="card__badge" status={status} />
                )}
                <div className="card__preview">
                    <img
                        src={getImagePath(Number(card.template_id))}
                        alt={card.name}
                    />
                </div>
            </div>
            <div className="auction-asset-page-description">
                <p className="auction-asset-page-description__title">
                    Description
                </p>
                <span className="auction-asset-page-description__text">
                    {card.description}
                </span>
            </div>
        </div>
    );
};
