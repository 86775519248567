import cn from 'classnames';
import React from 'react';
import { Header, Menu, MobileMenu } from '../../Components';
import { HeaderBanner } from '../../Components/HeaderBanner';
import { useApp } from '../../Data/AppContext';

export const ShelterContentContainer = ({ children }) => {
    const { sidebar, blur, isBannerShowing } = useApp();

    return (
        <div
            className={cn('app-wrapper__container app-wrapper__content', {
                blur,
            })}
        >
            <HeaderBanner type="mobile" />
            <Header />

            <main
                className={cn('main-wrapper', {
                    'main-wrapper--with-sidebar': sidebar,
                    'main-wrapper--with-banner': isBannerShowing,
                })}
            >
                <Menu />
                {children}
            </main>

            <MobileMenu />
        </div>
    );
};
