import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogoImg } from '../../assets/img/header/header_logo.svg';
import { ReactComponent as DMLogo } from '../../assets/img/dm-logo.svg';

const Logo = () => {
    const history = useHistory();

    return (
        <div className="header-logo">
            <button
                onClick={() => history.push('/')}
                className="header-logo__btn"
            >
                <LogoImg className="header-logo__img" />
                <DMLogo className="header-logo__dm" />
            </button>
        </div>
    );
};

export default Logo;
