import React from 'react';

import './DeepMine.scss';

const DeepMineText = ({ title, description }) => (
    <div className="deepmine-text">
        <div className="deepmine-text__title">{title}</div>
        <div className="deepmine-text__description">{description}</div>
    </div>
);

export const DeepMine = () => {
    return (
        <div className="deepmine">
            <div className="deepmine__title">What is DeepMine?</div>
            <div className="deepmine__content">
                <DeepMineText
                    title="First social economic NFT strategy"
                    description="Team up with people from around the world to get to the core of Eleazar - a starless planet drifting through the dark voids of cosmos in the distant future."
                />

                <DeepMineText
                    title="Play-to-Earn"
                    description="Mine the DeepMine Energy (DME) token while you progress through the game. Producing and upgrading equipment, providing valuable services to other players, renting out your assets and many other mechanics are available to earn the tokens! "
                />

                <DeepMineText
                    title="Unique cooperative mechanics"
                    description="Join the group of the like-minded people to succeed, as getting to the core of the planet is not an easy task for a loner."
                />

                <DeepMineText
                    title="Different game roles and modes"
                    description="Work in the mine as a Contractor, produce equipment in your Factory, operate as a Mine Owner, be a Landlord or even become an influential Senator of DeepMine DAO. These are just some of the roles available for you to have a unique experience on Eleazar."
                />

                <DeepMineText
                    title="Extensive NFT collection"
                    description="Big and growing NFT collection of valuable assets available on the marketplaces and tradable on blockchain."
                />

                <DeepMineText
                    title="Metaverse experience"
                    description="DeepMine is an open world where other projects can interconnect into a big metaverse ecosystem. Join now and be one of the pioneers of this exciting journey!"
                />
            </div>
        </div>
    );
};
