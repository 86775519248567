import React from 'react';

import { Button } from '../../../Ui/v2';
import { ReactComponent as MistakeIcon } from '../../../../assets/img/icons/mistake.svg';
import { CancelButton } from '../CancelButton/CancelButton';

import './AffiliateModalError.scss';

export const AffiliateModalError = ({
    title,
    description,
    buttonText,
    onClick,
}) => (
    <div className="affiliate-modal-error">
        <MistakeIcon />
        <div className="affiliate-modal__title">{title}</div>
        <div className="affiliate-modal__description">{description}</div>
        <Button onClick={onClick} className="affiliate-modal-error__button">
            {buttonText}
        </Button>
        <CancelButton />
    </div>
);
