import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { CancelButton } from '../CancelButton/CancelButton';

import './AchievementModalContainer.scss';
import { useApp } from '../../../../Data/AppContext';
import { ReactComponent as LockedIcon } from '../../../../Containers/AffiliatePage/img/locked.svg';
import { ReactComponent as DoneIcon } from '../../../../Containers/AffiliatePage/img/done.svg';
import { Button, Loader } from '../../../Ui/v2';
import { useAffiliate } from '../../../../Hooks/useModal';
import { useUser } from '../../../../Hooks/useUser';
import { useUpdateBalance } from '../../../../Hooks/useUpdateBalance';
import { useChainRequest } from '../../../../Hooks/useChainRequest';
import {
    confirmAchievement,
    confirmAchievementMint,
    getAffiliateAchievements,
    mintAchievement,
    getAchievementMintState,
} from '../../../../Services';

export const AchievementModalContainer = ({
    Image,
    title,
    privileges,
    steps,
    id,
}) => {
    const activeUser = useUser();
    const achievementMintState = useChainRequest(
        getAchievementMintState,
        'achievementState',
        { enabled: false }
    );

    const { affiliateAchievements, setAffiliateAchievements } = useApp();
    const achievement = affiliateAchievements?.find(({ key }) => key === id);
    const { hideAffiliationPopup } = useAffiliate();
    const updateUserBalance = useUpdateBalance();

    const [isPaid, setIsPaid] = useState(false);

    useEffect(() => {
        if (achievement?.sended === false) {
            achievementMintState.refetch();
        }
    }, [achievement?.sended]);

    useEffect(() => {
        if (achievementMintState.data?.length && !isPaid) {
            setIsPaid(
                achievementMintState.data.some((ach) =>
                    [...ach.processed, ...ach.achievement_ids].includes(
                        achievement.id
                    )
                )
            );
        }
    }, [achievementMintState.data, isPaid]);

    const { mutate: confirmAchievementMutate, isLoading } = useMutation(
        () => confirmAchievement(achievement.id),
        {
            onSuccess: async () => {
                toast.success(`You’ve unlocked a ${title} pin`);
                setAffiliateAchievements(await getAffiliateAchievements());
            },
        }
    );

    const { mutate: confirmAchievementClaim } = useMutation(
        () =>
            mintAchievement({
                activeUser,
                achievementId: achievement.id,
            }),
        {
            onSuccess: async () => {
                await confirmAchievementMint(achievement.id);

                const updatedAchievements = affiliateAchievements.map((ach) => {
                    if (ach.id === achievement.id) {
                        ach.sended = true;
                    }
                    return ach;
                });

                setAffiliateAchievements(updatedAchievements);
                toast.success(`You’ve minted and claimed a ${title} pin`);
                updateUserBalance();
                hideAffiliationPopup();
            },
        }
    );

    if (!achievement) {
        return null;
    }

    const isDone = achievement.confirm;
    const isLocked = !isDone && achievement.active;
    const isSended = achievement?.sended || isPaid;
    const canMint = !isLocked && !isSended && isDone;

    return (
        <div
            className={cn('achievement-modal', {
                'achievement-modal--done': isDone && isSended,
            })}
        >
            <div className="achievement-modal__image-container">
                {(isLocked || (isLocked === false && isSended === false)) && (
                    <div className="achievement-modal__badge">
                        <LockedIcon />
                    </div>
                )}
                {Image}
            </div>
            <div className="affiliate-modal__title">{title}</div>
            {isLocked && (
                <div className="achievement-modal__unlock-text">
                    Congratulations! You can unlock this pin!
                </div>
            )}
            {canMint && (
                <div className="achievement-modal__unlock-text">
                    You’ve already unlocked the rank pin.
                    <br /> Mint it to get it!
                </div>
            )}

            {isDone && isSended && (
                <div className="achievement-modal__unlock-text">
                    <span>You’ve already claimed this pin!</span>
                    <div className="achievement-modal__unlock-text__icon">
                        <DoneIcon />
                    </div>
                </div>
            )}
            <div className="achievement-modal__privileges">
                <div className="achievement-modal__privileges__title">
                    What is it?
                </div>
                <ul className="achievement-modal__privileges__content">
                    {privileges[isSended ? 'ntt' : 'pin'].map((privilege) => (
                        <li key={privilege}>
                            {privilege.replace('$title$', title)}
                        </li>
                    ))}
                </ul>
            </div>
            {!isDone && !!steps?.length && (
                <div className="achievement-modal__steps">
                    <div className="achievement-modal__steps__title">
                        How to reach this rank?
                    </div>
                    <ol className="achievement-modal__steps__content">
                        {steps.map((step) => (
                            <li key={step}>{step}</li>
                        ))}
                    </ol>
                </div>
            )}
            {isLocked && !isLoading && (
                <Button
                    className="achievement-modal__button"
                    onClick={confirmAchievementMutate}
                >
                    <span>Unlock the rank pin</span>
                </Button>
            )}
            {canMint && !isLoading && (
                <Button
                    className="achievement-modal__button"
                    onClick={confirmAchievementClaim}
                >
                    <span>Mint and claim the rank pin (0.5 WAX)</span>
                </Button>
            )}
            {isLoading ? <Loader /> : <CancelButton />}
        </div>
    );
};
