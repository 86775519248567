import React from 'react';

import classnames from 'classnames';

import HandImgPng from '../../assets/img/hand.png';
import BuyImgPng from '../../assets/img/buyNft.png';
import SuccessImgPng from '../../assets/img/success.png';

const defaultOnClick = () => {};

const getLoginImage = (type) => {
    switch (type) {
        case 'auth':
            return HandImgPng;
        case 'warning':
            return BuyImgPng;
        case 'success':
        default:
            return SuccessImgPng;
    }
};

const LoginButton = ({ type, onClick = defaultOnClick }) => {
    return (
        <button className={classnames('login-btn', type)} onClick={onClick}>
            <img src={getLoginImage(type)} alt="" />
        </button>
    );
};

export default LoginButton;
