import React, { useContext, useEffect } from 'react';
import { UALContext } from 'ual-reactjs-renderer';
import { useApp } from '../../Data/AppContext';
import { ContentContainer } from '../../Components';

import { ConnectWaxContent } from './ConnectWaxContent';
import { WelcomeContent } from './WelcomeContent';
import { MainPageContent } from './MainPageContent';

const MainPage = () => {
    const { showModal } = useContext(UALContext);

    const {
        stakedList,
        unstakedList,
        userItemsFetched,
        userData,
        isAuthenticated,
        showWelcome,
        setShowingWelcomeScreen,
        showingWelcomeScreen,
    } = useApp();

    useEffect(() => {
        if (showingWelcomeScreen) {
            return;
        }

        if (
            userData &&
            userItemsFetched &&
            !stakedList.length &&
            !unstakedList.length
        ) {
            setShowingWelcomeScreen(true);
            return;
        }

        if (userData && isAuthenticated && showWelcome && userItemsFetched) {
            setShowingWelcomeScreen(true);
        }
    }, [
        showingWelcomeScreen,
        userData,
        userItemsFetched,
        stakedList,
        unstakedList,
        isAuthenticated,
        showWelcome,
        setShowingWelcomeScreen,
    ]);

    const showWelcomeContent =
        userData && isAuthenticated && showWelcome && userItemsFetched;
    const showMainContent = userData && isAuthenticated && !showWelcome;
    const showConnect = !showWelcomeContent && !showMainContent;

    return (
        <ContentContainer>
            {showWelcomeContent && <WelcomeContent />}
            {showMainContent && <MainPageContent />}
            {showConnect && <ConnectWaxContent onShowModal={showModal} />}
        </ContentContainer>
    );
};

export default MainPage;
