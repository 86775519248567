import React from 'react';
import { AffiliateSectionContainer } from '../AffiliateSectionContainer/AffiliateSectionContainer';
import NewcomerIcon from '../../img/Newcomer.png';
import MotivatorIcon from '../../img/Motivator.png';
import EncouragerIcon from '../../img/Encourager.png';
import InspirerIcon from '../../img/Inspirer.png';
import LeaderIcon from '../../img/Leader.png';
import RecruitIcon from '../../img/Recruit.png';
import { AffiliateLevelIcon } from '../AffiliateLevelIcon/AffiliateLevelIcon';

import './AffiliateLevel.scss';
import { useApp } from '../../../../Data/AppContext';
import { useAffiliate } from '../../../../Hooks/useModal';

const recruitLevel = { title: 'Recruit', icon: RecruitIcon };
const mainLevels = [
    { title: 'Newcomer', icon: NewcomerIcon, order: 1 },
    { title: 'Motivator', icon: MotivatorIcon, order: 2 },
    { title: 'Encourager', icon: EncouragerIcon, order: 3 },
    { title: 'Inspirer', icon: InspirerIcon, order: 4 },
    { title: 'Leader', icon: LeaderIcon, order: 5 },
];

export const AffiliateLevel = () => {
    const {
        affiliateAchievements,
        referralData,
        authData,
        setAffiliateModalData,
    } = useApp();
    const { showAffiliationPopup } = useAffiliate();
    const hasRecruit = referralData.is_referral;
    const openAchievementModal = (type) => () => {
        showAffiliationPopup(`achievement-${type}`);
    };

    const handleOpenRecruit = () => {
        if (!authData.email_confirmed) {
            setAffiliateModalData({ shouldOpenDiscord: false });
            showAffiliationPopup('email');
        } else if (!authData?.discord_confirmed) {
            showAffiliationPopup('discord');
        } else {
            openAchievementModal('recruit')();
        }
    };

    const handleOpenNewcomer = () => {
        if (!authData.email_confirmed) {
            setAffiliateModalData({ shouldOpenDiscord: true });
            showAffiliationPopup('email');
        } else if (!authData?.discord_confirmed) {
            showAffiliationPopup('discord');
        } else {
            openAchievementModal('newcomer')();
        }
    };

    const recruitAchievements = affiliateAchievements?.find(
        ({ key }) => key === 'recruit'
    );

    return (
        <AffiliateSectionContainer title="My scouting rank">
            <div className="affiliate-level">
                {hasRecruit && (
                    <AffiliateLevelIcon
                        onClick={handleOpenRecruit}
                        icon={recruitLevel.icon}
                        title={recruitLevel.title}
                        isDone={recruitAchievements?.confirm}
                        isSended={recruitAchievements?.sended}
                        isLocked={
                            !recruitAchievements?.confirm &&
                            recruitAchievements?.active
                        }
                    />
                )}
                {mainLevels.map(({ title, icon, order }, idx) => {
                    const levelAchievement = affiliateAchievements?.find(
                        ({ key }) => key === title.toLowerCase()
                    );

                    let nextLevelAchievementName;
                    let nextLevelAchievement;
                    if (idx < mainLevels.length - 1) {
                        nextLevelAchievementName = mainLevels.find(
                            (mainLevel) => mainLevel.order === order + 1
                        )?.title;
                        nextLevelAchievement = affiliateAchievements?.find(
                            ({ name }) => name === nextLevelAchievementName
                        );
                    }

                    return (
                        <AffiliateLevelIcon
                            key={title}
                            icon={icon}
                            title={title}
                            needRightLine={idx < mainLevels.length - 1}
                            isWide={!hasRecruit}
                            onClick={
                                title === 'Newcomer'
                                    ? handleOpenNewcomer
                                    : openAchievementModal(title.toLowerCase())
                            }
                            isDone={levelAchievement?.confirm}
                            isSended={levelAchievement?.sended}
                            isLocked={
                                !levelAchievement?.confirm &&
                                levelAchievement?.active
                            }
                            isNextLocked={nextLevelAchievement?.active}
                        />
                    );
                })}
            </div>
        </AffiliateSectionContainer>
    );
};
