export const getLocalDateFromUtc = (utcTimeString) => {
    const date = new Date(utcTimeString);
    const userTimezoneOffset = date.getTimezoneOffset() * 1000 * 60;

    return new Date(date.getTime() - userTimezoneOffset);
};

export const startsInBannerDate = getLocalDateFromUtc('2022-06-07T17:00:00');

export const liveBannerDate = getLocalDateFromUtc('2022-06-08T17:00:00');

export const finishBannersLogicDate = getLocalDateFromUtc(
    '2022-06-09T17:00:00'
);

export const getCountdownText = ({ hours, minutes, seconds, completed }) => {
    if (completed) return 'Finished';

    return `${hours}h ${minutes}m ${seconds}s`;
};
