import React from 'react';
import cn from 'classnames';

import { ReactComponent as DoneIcon } from '../../img/done.svg';
import { ReactComponent as LockedIcon } from '../../img/locked.svg';

import './AffiliateLevelIcon.scss';

export const AffiliateLevelIcon = ({
    title,
    icon,
    isWide,
    needRightLine,
    isDone,
    isSended,
    isLocked,
    onClick,
    isNextLocked,
}) => (
    <div
        className={cn('affiliate-level-icon', {
            'affiliate-level-icon--wide': isWide,
            'affiliate-level-icon--done': isDone && isSended,
            'affiliate-level-icon--locked': isLocked,
        })}
        onClick={onClick}
    >
        {isDone && isSended && (
            <div className="affiliate-level-icon__badge">
                <DoneIcon />
            </div>
        )}
        {(isLocked || isSended === false) && (
            <div className="affiliate-level-icon__badge">
                <LockedIcon />
            </div>
        )}

        <div className="affiliate-level-icon__image">
            <img src={icon} alt={title} />
            {needRightLine && (
                <div
                    className={cn('affiliate-level-icon__line', {
                        'affiliate-level-icon__line--semi': isDone && isSended,
                        'affiliate-level-icon__line--full':
                            isDone && isSended && isNextLocked,
                    })}
                />
            )}
        </div>
        <div
            className={cn('affiliate-level-icon__title', {
                'affiliate-level-icon__title--highlighted': isDone || isLocked,
            })}
        >
            {title}
        </div>
    </div>
);
