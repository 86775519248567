import { JsonRpc } from 'eosjs';
import { CONTRACT_NAMES, CONTRACT_TABLE_NAMES } from '../../../Constants';

const DEFAULT_CONFIG = {
    json: true,
    reverse: false,
    show_payer: false,
    code: CONTRACT_NAMES.market,
    scope: CONTRACT_NAMES.market,
};

export const getStoreLots = (lotId, url) => {
    const rpc = new JsonRpc(url);

    const config = {
        ...DEFAULT_CONFIG,
        table: CONTRACT_TABLE_NAMES.lots,
        index_position: 1,
        key_type: 'i64',
        limit: lotId ? 1 : 100000,
    };

    if (lotId) {
        config.lower_bound = lotId;
        config.upper_bound = lotId;
    }

    return rpc.get_table_rows(config);
};

export const getDropLots = (lotId, url) => {
    const rpc = new JsonRpc(url);

    const config = {
        ...DEFAULT_CONFIG,
        table: CONTRACT_TABLE_NAMES.lots,
        index_position: 2,
        key_type: 'i64',
        lower_bound: 1,
        upper_bound: 1,
        limit: lotId ? 1 : 10,
    };

    if (lotId) {
        config.lower_bound = lotId;
        config.upper_bound = lotId;
    }

    return rpc.get_table_rows(config);
};

export const getUserStats = (userName, url) => {
    const rpc = new JsonRpc(url);

    const config = {
        ...DEFAULT_CONFIG,
        table: CONTRACT_TABLE_NAMES.users,
        index_position: 1,
        key_type: 'name',
        lower_bound: userName,
        upper_bound: userName,
        limit: userName ? 1 : 100000,
    };

    return rpc.get_table_rows(config);
};

export const getUserWhitelist = (userName, url) => {
    const rpc = new JsonRpc(url);

    const config = {
        ...DEFAULT_CONFIG,
        table: CONTRACT_TABLE_NAMES.whitelist,
        index_position: 'primary',
        key_type: 'name',
        limit: userName ? 1 : 100000,
        lower_bound: userName,
        upper_bound: userName,
    };

    return rpc.get_table_rows(config);
};

export const getWhitelistPeriod = (url) => {
    const rpc = new JsonRpc(url);

    const config = {
        ...DEFAULT_CONFIG,
        table: CONTRACT_TABLE_NAMES.dropconfig,
        index_position: 1,
        key_type: 'name',
        lower_bound: CONTRACT_NAMES.market,
        limit: 1,
    };

    return rpc.get_table_rows(config);
};
