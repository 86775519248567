import React from 'react';

import { Button } from '../../../Ui/v2';
import { ReactComponent as MistakeIcon } from '../../../../assets/img/icons/mistake.svg';

import './AffiliateModalAuthError.scss';
import { useApp } from '../../../../Data/AppContext';

export const AffiliateModalAuthError = () => {
    const { hidePopupHandler } = useApp();

    return (
        <div className="affiliate-modal-auth-error">
            <MistakeIcon />
            <div className="affiliate-modal-auth-error__title">
                Oops. You can’t become a recruit
            </div>
            <div className="affiliate-modal-auth-error__description">
                You already have a DeepMine account and cannot become a recruit
            </div>
            <Button
                onClick={hidePopupHandler}
                className="affiliate-modal-auth-error__button"
            >
                Okay
            </Button>
        </div>
    );
};
