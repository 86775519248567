import React from 'react';
import classnames from 'classnames';
import AnswerList from './AnswerList';
import AnswerTable from './AnswerTable';

const FaqItem = ({ item, active, setActive }) => {
    const activeItemHandler = (id) => {
        if (active === id) return setActive();
        setActive(id);
    };

    const { id, question, answer, numeric, type, note } = item;

    if (type === 'text') {
        return (
            <div className={classnames('faq_item')}>
                <div
                    className={classnames('faq_item__title', {
                        active: active === id,
                    })}
                    onClick={() => {
                        activeItemHandler(id);
                    }}
                >
                    {question}
                </div>
                <div
                    className={classnames('faq_item__content', {
                        active: active === id,
                    })}
                >
                    {answer}
                </div>
            </div>
        );
    }

    if (type === 'list') {
        return (
            <div className={classnames('faq_item')}>
                <div
                    className={classnames('faq_item__title', {
                        active: active === id,
                    })}
                    onClick={() => {
                        activeItemHandler(id);
                    }}
                >
                    {question}
                </div>
                <div
                    className={classnames('faq_item__content', {
                        active: active === id,
                    })}
                >
                    {answer.map((item) => (
                        <AnswerList
                            key={item.title}
                            title={item.title}
                            list={item.list}
                            numeric={numeric}
                        />
                    ))}
                </div>
            </div>
        );
    }

    if (type === 'table') {
        return (
            <div className={classnames('faq_item')}>
                <div
                    className={classnames('faq_item__title', {
                        active: active === id,
                    })}
                    onClick={() => {
                        activeItemHandler(id);
                    }}
                >
                    {question}
                </div>
                <div
                    className={classnames('faq_item__content', {
                        active: active === id,
                    })}
                >
                    {answer.map((item) => (
                        <AnswerTable
                            key={item.title}
                            title={item.title}
                            tables={item.tables}
                        />
                    ))}
                    <div className="note_block">{note}</div>
                </div>
            </div>
        );
    }
};

export default FaqItem;
