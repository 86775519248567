import { useRef } from 'react';
import { useQuery } from 'react-query';
import { ENDPOINTS } from '../Helpers';
import { useUser } from './useUser';

const defaultQueryOptions = (retryCallback) => ({
    retryDelay: 1500,
    select: (data) => data.rows,
    retry: (failureCount) => {
        const requestNumber = failureCount + 1;
        const needRetry = requestNumber <= ENDPOINTS.length;

        if (needRetry) {
            retryCallback(ENDPOINTS[requestNumber]);
        }

        return needRetry;
    },
});

const useChainRequest = (requestFn, requestName, options) => {
    const { accountName } = useUser() ?? {};
    let { current: endpoint } = useRef(ENDPOINTS[0]);

    const result = useQuery(
        [requestName, accountName],
        () => requestFn(endpoint, accountName),
        {
            ...defaultQueryOptions((newEndpoint) => {
                endpoint = newEndpoint;
            }),
            ...options,
        }
    );

    return result;
};

export { useChainRequest };
