import axios from 'axios';
import { wait } from './utils';

const ATOMIC_API_ENDPOINTS = window.ENDPOINTS.atomic;

const CONNECTION_COUNT_LIMIT = ATOMIC_API_ENDPOINTS.length * 2;
let currentAtomicApiEndpoint = ATOMIC_API_ENDPOINTS[0];

const getEndpoint = () => {
    const baseEndpoint =
        ATOMIC_API_ENDPOINTS[
            ATOMIC_API_ENDPOINTS.indexOf(currentAtomicApiEndpoint) + 1
        ] || ATOMIC_API_ENDPOINTS[0];

    return baseEndpoint;
};

export const getDataFromAtomicApi = async (path, connectionCount = 0) => {
    try {
        connectionCount++;
        const {
            data: { data },
        } = await axios.get(`${currentAtomicApiEndpoint}/${path}`);

        return data;
    } catch (error) {
        if (
            error.message === 'Network Error' ||
            (error.response && Number(error.response.status) >= 500)
        ) {
            if (connectionCount >= CONNECTION_COUNT_LIMIT)
                throw new Error('NetworkError!');

            currentAtomicApiEndpoint = getEndpoint();

            await wait(1);
            return await getDataFromAtomicApi(path, connectionCount);
        }

        throw new Error(error.message);
    }
};
