import React from 'react';
import classnames from 'classnames';
import { RarityMarker } from '../../Components/Ui/v2/RarityMarker';

const AnswerBlock = ({ id, title, list }) => {
    return (
        <>
            <div className="table_title">{title}</div>
            <div className="faq_table">
                <div
                    className={classnames('faq_table__list', {
                        last: id === 1,
                    })}
                >
                    <div className="item head">Rarity</div>
                    <div className="item">
                        <RarityMarker value="common" />
                        <span className="item__text">Common</span>
                    </div>
                    <div className="item">
                        <RarityMarker value="uncommon" />
                        <span className="item__text">Uncommon</span>
                    </div>
                    <div className="item">
                        <RarityMarker value="rare" />
                        <span className="item__text">Rare</span>
                    </div>
                    <div className="item">
                        <RarityMarker value="epic" />
                        <span className="item__text">Epic</span>
                    </div>
                    <div className="item">
                        <RarityMarker value="legendary" />
                        <span className="item__text">Legendary</span>
                    </div>
                    <div className="item head">Daily DMP reward</div>
                    {list.map((item) => (
                        <div
                            key={item}
                            className="item head"
                            style={{ marginLeft: 16 }}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default AnswerBlock;
