import React, { useMemo } from 'react';
import { AffiliateSectionContainer } from '../AffiliateSectionContainer/AffiliateSectionContainer';
import { ReferalsEmpty } from '../ReferalsEmpty/ReferalsEmpty';

import './Referrals.scss';
import { useApp } from '../../../../Data/AppContext';
import { getHiddenReferral } from '../../../../Services';

export const Referrals = () => {
    const { referralData } = useApp();

    const referrals = useMemo(
        () =>
            referralData.referrals
                .filter((referral) => referral.is_recruit)
                .reverse(),
        [referralData.referrals]
    );

    const { length } = referrals;

    return (
        <AffiliateSectionContainer title="My recruits">
            <div className="referrals">
                <div className="referrals__count">{length}</div>
                {length > 0 ? (
                    <table className="referrals__table">
                        <tbody>
                            {referrals.map((referral, i) => (
                                <tr
                                    key={referral.wax_address}
                                    className="referrals__list-item"
                                >
                                    <td>{length - i}</td>
                                    <td>
                                        {getHiddenReferral(
                                            referral.wax_address
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <ReferalsEmpty />
                )}
            </div>
        </AffiliateSectionContainer>
    );
};
