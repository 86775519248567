import React from 'react';

import { Button } from '../../../Ui/v2';
import { ReactComponent as SuccessIcon } from '../../../../assets/img/icons/success.svg';
import { CancelButton } from '../CancelButton/CancelButton';

import './AffiliateModalAuthSuccess.scss';
import { useApp } from '../../../../Data/AppContext';
import { useAffiliate } from '../../../../Hooks/useModal';

const privileges = [
    'Recruit owners will be eligible for various bonuses and rewards in the future.',
];

export const AffiliateModalAuthSuccess = () => {
    const { affiliateModalData } = useApp();
    const { showAffiliationPopup } = useAffiliate();

    const handleClick = () => {
        showAffiliationPopup('email');
    };

    return (
        <div className="affiliate-modal-auth-success">
            <SuccessIcon />
            <div className="affiliate-modal-auth-success__title">
                Welcome to DeepMine!
            </div>
            <div className="affiliate-modal-auth-success__description">
                We’ve prepared a Recruit sticker for you.
                <br />
                Get it now
            </div>
            {affiliateModalData?.referrerName && (
                <div className="affiliate-modal-auth-success__invited-by">
                    * You have been invited by {affiliateModalData.referrerName}
                </div>
            )}
            <div className="affiliate-modal-auth-success__privileges">
                <div className="affiliate-modal-auth-success__privileges__title">
                    What is it?
                </div>
                <ol className="affiliate-modal-auth-success__privileges__content">
                    {privileges.map((privilege) => (
                        <li key={privilege}>{privilege}</li>
                    ))}
                </ol>
            </div>
            <Button
                onClick={handleClick}
                className="affiliate-modal-auth-success__button"
            >
                I want to get this sticker
            </Button>
            <CancelButton />
        </div>
    );
};
