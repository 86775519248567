import React from 'react';
import { Button, Input } from '../../../Components/Ui/v2';

export const Counter = ({
    disabled,
    onIncrement,
    onDecrement,
    value,
    onInput,
    size,
}) => {
    return (
        <>
            <Button
                disabled={disabled}
                className="store-card-counter__button"
                use="secondary"
                onClick={onDecrement}
                size={size}
            >
                -
            </Button>
            <Input
                disabled={disabled}
                value={value}
                onInput={onInput}
                size={size}
            />
            <Button
                disabled={disabled}
                className="store-card-counter__button"
                use="secondary"
                onClick={onIncrement}
                size={size}
            >
                +
            </Button>
        </>
    );
};
