import React from 'react';
import './styles.scss';

export const Privacy = () => {
    return (
        <div className="faq_list">
            <div className="faq__container">
                <div className="faq_list_wrap">
                    <p className="privacy-paragraph">
                        Effective date: 01.04.2022 NES Digital Solutions Ltd.
                        ("us", "we", or "our") operates the website. This page
                        informs you of our policies regarding the collection,
                        use, and disclosure of personal data when you use our
                        Service and the choices you have associated with that
                        data. We use your data to provide and improve the
                        Service. By using the Service, you agree to the
                        collection and use of information in accordance with
                        this policy.
                    </p>

                    <br />
                    <h3>I. INFORMATION COLLECTION AND USE</h3>
                    <p className="privacy-paragraph">
                        We collect several different types of information for
                        various purposes to provide and improve our Service to
                        you. Types of Data Collected:
                    </p>
                    <h4>1. Personal Data</h4>
                    <p className="privacy-paragraph">
                        While using our Service, we may ask you to provide us
                        with certain personally identifiable information that
                        can be used to contact or identify you ("Personal
                        Data"). Personally identifiable information may include,
                        but is not limited to: Email address, Discord username,
                        Telegram username
                    </p>
                    <h4>2. Usage Data</h4>
                    <p className="privacy-paragraph">
                        We may also collect information that your browser sends
                        whenever you visit our Service or when you access the
                        Service by or through any device ("Usage Data"). This
                        Usage Data may include information such as your
                        computer's Internet Protocol address (e.g. IP address),
                        browser type, browser version, the pages of our Service
                        that you visit, the time and date of your visit, the
                        time spent on those pages, unique device identifiers and
                        other diagnostic data. When you access the Service by or
                        through a mobile device, this Usage Data may include
                        information such as the type of mobile device you use,
                        your mobile device unique ID, the IP address of your
                        mobile device, your mobile operating system, the type of
                        mobile Internet browser you use, unique device
                        identifiers and other diagnostic data.
                    </p>
                    <h4>3. Tracking Cookies Data</h4>
                    <p className="privacy-paragraph">
                        We use cookies and similar tracking technologies to
                        track the activity on our Service and hold certain
                        information. Cookies are files with small amount of data
                        which may include an anonymous unique identifier.
                        Cookies are sent to your browser from a website and
                        stored on your device. Tracking technologies also used
                        are beacons, tags, and scripts to collect and track
                        information and to improve and analyze our Service.
                        Third-party cookies, beacons, tags and script may also
                        be used by Us on the website. A cookie does not identify
                        you personally, but it does identify your device. You
                        can instruct your browser to refuse all cookies or to
                        indicate when a cookie is being sent. However, if you do
                        not accept cookies, you may not be able to use some
                        portions of our Service. Examples of Cookies we use:
                        Session Cookies. We use Session Cookies to operate our
                        Service. Preference Cookies. We use Preference Cookies
                        to remember your preferences and various settings.
                        Security Cookies. We use Security Cookies for security
                        purposes.
                    </p>
                    <h3>II. USE OF DATA</h3>
                    <p className="privacy-paragraph">
                        NES Digital Solutions Ltd. uses the collected data for
                        various purposes: To provide and maintain the Service,
                        to notify you about changes to our Service, to allow you
                        to participate in interactive features of our Service
                        when you choose to do so, to provide customer care and
                        support, to provide analysis or valuable information so
                        that we can improve the Service, to monitor the usage of
                        the Service, to detect, prevent and address technical
                        issues
                    </p>
                    <h4>III. TRANSFER OF DATA</h4>
                    <p className="privacy-paragraph">
                        Your information, including Personal Data, may be
                        transferred to — and maintained on — computers located
                        outside of your state, province, country or other
                        governmental jurisdiction where the data protection laws
                        may differ than those from your jurisdiction. By using
                        Our service, you automatically consent to this Privacy
                        Policy followed by your submission of such information
                        which represents your agreement to that transfer. NES
                        Digital Solutions Ltd. will take all steps reasonably
                        necessary to ensure that your data is treated securely
                        and in accordance with this Privacy Policy and no
                        transfer of your Personal Data will take place to an
                        organization or a country unless there are adequate
                        controls in place including the security of your data
                        and other personal information
                    </p>
                    <h3>IV. DISCLOSURE OF DATA</h3>
                    <p className="privacy-paragraph">
                        Legal Requirements NES Digital Solutions Ltd. may
                        disclose your Personal Data in the good faith belief
                        that such action is necessary to: To comply with a legal
                        obligation, to protect and defend the rights or property
                        of NES Digital Solutions Ltd., to prevent or investigate
                        possible wrongdoing in connection with the Service, to
                        protect the personal safety of users of the Service or
                        the public, to protect against legal liability
                    </p>
                    <h3>V. SECURITY OF DATA</h3>
                    <p className="privacy-paragraph">
                        The security of your data is important to us, but
                        remember that no method of transmission over the
                        Internet, or method of electronic storage is 100%
                        secure. While we strive to use commercially acceptable
                        means to protect your Personal Data, we cannot guarantee
                        its absolute security.
                    </p>
                    <h3>VI. SERVICE PROVIDERS</h3>
                    <p className="privacy-paragraph">
                        We may employ third party companies and individuals to
                        facilitate our Service ("Service Providers"), to provide
                        the Service on our behalf, to perform Service-related
                        services or to assist us in analyzing how our Service is
                        used. These third parties have access to your Personal
                        Data only to perform these tasks on our behalf and are
                        obligated not to disclose or use it for any other
                        purpose. Analytics We may use third-party Service
                        Providers to monitor and analyze the use of our Service.
                    </p>
                    <h3>VII. LINKS TO OTHER SITES</h3>
                    <p className="privacy-paragraph">
                        Our Service may contain links to other sites that are
                        not operated by us. If you click on a third party link,
                        you will be directed to that third party's site. We
                        strongly advise you to review the Privacy Policy of
                        every site you visit. We have no control over and assume
                        no responsibility for the content, privacy policies or
                        practices of any third party sites or services.
                    </p>
                    <h3>VIII. CHILDREN'S PRIVACY</h3>
                    <p className="privacy-paragraph">
                        Our Service does not address anyone under the age of 18
                        ("Children"). We do not knowingly collect personally
                        identifiable information from anyone under the age of
                        18. If you are a parent or guardian and you are aware
                        that your Children has provided us with Personal Data,
                        please contact us. If we become aware that we have
                        collected Personal Data from children without
                        verification of parental consent, we take steps to
                        remove that information from our servers.
                    </p>
                    <h3>IX. CHANGES TO THIS PRIVACY POLICY</h3>
                    <p className="privacy-paragraph">
                        We may update our Privacy Policy from time to time. You
                        are advised to review this Privacy Policy periodically
                        for any changes. Changes to this Privacy Policy are
                        effective when they are posted on this page.
                    </p>
                    <h3>X. CONTACT US</h3>
                    <p className="privacy-paragraph">
                        If you have any questions about this Privacy Policy,
                        please contact us: Email: info@deepmine.world
                    </p>
                </div>
            </div>
        </div>
    );
};
