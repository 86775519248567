import React from 'react';
import { Button } from '../Ui/v2/Button';
import { STAKE_PAGE } from '../../Constants';

const AlertNft = ({ setPage }) => {
    const handleStakeClick = () => {
        setPage(STAKE_PAGE);
    };

    return (
        <div className="alert-nft">
            <div className="alert-nft__text">
                You don’t have any staked NFTs yet
                <p className="alert-nft__info">
                    Cards appear here after staking is confirmed by blockchain
                </p>
            </div>

            <Button use="secondary" size="l" onClick={handleStakeClick}>
                Stake NFTs
            </Button>
        </div>
    );
};

export default AlertNft;
