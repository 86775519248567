import React, { useState } from 'react';
import Countdown from 'react-countdown';
import cn from 'classnames';
import { countdownRenderer } from '../../StorePage/components/StoreCard/helpers';
import { StoreCard } from '../../StorePage/components/StoreCard';
import { pluralize } from '../../../Helpers';

const PublicRound = ({ startAt, userStats, data, hasWideCards }) => {
    // const isRoundRunning = Date.now() >= startAt;
    const [isRunning, setIsRunning] = useState(Date.now() >= startAt);

    return (
        <div className="drop-category drop-category--public">
            <div className="drop-category__top">
                <span className="drop-category__title">
                    Public Drop (For all users)
                </span>
                <span className="drop-category__counter">
                    {!isRunning && (
                        <>
                            Starts in{' '}
                            <Countdown
                                renderer={countdownRenderer}
                                date={startAt}
                                onComplete={() => setIsRunning(true)}
                            />
                        </>
                    )}
                </span>
                <span className="drop-category__info">
                    {data.length} {pluralize(data.length, 'item')}
                </span>
            </div>
            <div
                className={cn('drop-category__list', {
                    'drop-category__list--wide': hasWideCards,
                })}
            >
                {data.map((lot) => (
                    <StoreCard
                        roundStartAt={startAt}
                        key={lot.id}
                        userStats={userStats}
                        type="public"
                        card={lot}
                        isWide={hasWideCards}
                        context="Drop"
                    />
                ))}
            </div>
        </div>
    );
};

export { PublicRound };
