import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { UALContext } from 'ual-reactjs-renderer';
import { useApp } from '../../Data/AppContext';

import WaxSum from './WaxSum';

import { ReactComponent as WaxLogoImg } from '../../assets/img/header/header_wax.svg';
import { ReactComponent as LogoutImg } from '../../assets/img/icons/logout.svg';

const WaxLogo = () => {
    const history = useHistory();

    const { showModal, logout } = useContext(UALContext);
    const {
        userData,
        showInterface,
        isAuthenticated,
        userLogoutHandler,
        dmpBalance,
        waxBalance,
    } = useApp();

    const userLogout = () => {
        logout();
        userLogoutHandler();
    };

    const handleLogin = () => {
        history.push('/');
        showModal();
    };

    // TODO::НА БУДУЩЕЕ
    // function toCommas(value) {
    //     return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }

    if (!isAuthenticated) {
        return (
            <div className="header_wax__wrapper">
                <div className="header_wax" onClick={handleLogin}>
                    <WaxLogoImg className="wax-logo" />
                    Connect WAX Wallet
                </div>
            </div>
        );
    }

    return (
        <div className="header_wax__wrapper">
            {showInterface && (
                <WaxSum dmpBalance={dmpBalance} waxBalance={waxBalance} />
            )}

            <div className="header_wax">
                <WaxLogoImg className="wax-logo auth" />
                <NavLink to="/MyStats">{userData?.accountName} </NavLink>

                <button className="logout_btn" onClick={userLogout}>
                    <LogoutImg /> Log out
                </button>
            </div>
        </div>
    );
};

export default WaxLogo;
