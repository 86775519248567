import { useCallback } from 'react';
import { useUser } from './useUser';
import { useApp } from '../Data/AppContext';
import { fetchDmpBalance, fetchWaxBalance } from '../Services';

const useUpdateBalance = () => {
    const { accountName } = useUser() ?? {};
    const { setDmpBalance, setWaxBalance } = useApp();

    const updateUserBalance = useCallback(() => {
        fetchDmpBalance({ account: accountName }).then((balance) => {
            setDmpBalance(balance);
        });

        fetchWaxBalance({ account: accountName }).then((balance) =>
            setWaxBalance(balance)
        );
    }, [setWaxBalance, setDmpBalance, accountName]);

    return updateUserBalance;
};

export { useUpdateBalance };
