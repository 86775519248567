import React from 'react';

import { Button } from '../../../Ui/v2';
import { ReactComponent as DiscordIcon } from '../../../../assets/img/icons/discord-icon-yellow.svg';
import { CancelButton } from '../CancelButton/CancelButton';

import './DiscordModal.scss';
import { useApp } from '../../../../Data/AppContext';
import { useAffiliate } from '../../../../Hooks/useModal';

export const DiscordModal = () => {
    const { authLinks } = useApp();
    const { showAffiliationPopup } = useAffiliate();

    const handleClick = async () => {
        try {
            window.open(authLinks.discord, '_blank').focus();
        } catch (e) {
            showAffiliationPopup('discord-error');
        }
    };

    return (
        <div className="discord-modal">
            <div className="affiliate-modal__title">
                Bind your Discord account
            </div>
            <Button className="discord-modal__button" onClick={handleClick}>
                <DiscordIcon />
                <span>Bind Discord account</span>
            </Button>
            <CancelButton />
        </div>
    );
};
