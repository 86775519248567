import React from 'react';
import { ReactComponent as BlueprintIcon } from '../../icons/blueprint.svg';
import { ReactComponent as DiscordIcon } from '../../icons/discord.svg';
import { ReactComponent as MediumIcon } from '../../icons/medium.svg';
import { ReactComponent as TgIcon } from '../../icons/tg.svg';
import { ReactComponent as TwitterIcon } from '../../icons/twitter.svg';
import logo from '../../icons/logo.png';
import useWindowDimensions from '../../../../Hooks/WindowDimensions';

import './Header.scss';
import { PHONE_WIDTH } from '../../utils/constants';

export const Header = () => {
    const { width } = useWindowDimensions();

    return (
        <div className="landing-header">
            {width > PHONE_WIDTH && (
                <div className="landing-header__section">
                    <a
                        href="https://docs.deepmine.world/blueprint"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <div className="landing-header__icon-content">
                            <BlueprintIcon />
                            <div>Blueprint</div>
                        </div>
                    </a>
                </div>
            )}

            <div className="landing-header__section">
                <a
                    href="https://deepmine.world/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        className="landing-header__logo"
                        src={logo}
                        alt="DeepMine"
                    />
                </a>
            </div>
            <div className="landing-header__section">
                {width <= PHONE_WIDTH && (
                    <a
                        href="https://docs.deepmine.world/blueprint"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <BlueprintIcon />
                    </a>
                )}
                <a
                    href="https://discord.gg/2gUMMerVVq"
                    target="_blank"
                    rel="noreferrer"
                >
                    <DiscordIcon />
                </a>
                <a
                    href="https://t.me/DeepMineWorld"
                    target="_blank"
                    rel="noreferrer"
                >
                    <TgIcon />
                </a>
                <a
                    href="https://twitter.com/DeepMineWorld"
                    target="_blank"
                    rel="noreferrer"
                >
                    <TwitterIcon />
                </a>
                <a
                    href="https://medium.com/@deepmineworld"
                    target="_blank"
                    rel="noreferrer"
                >
                    <MediumIcon />
                </a>
            </div>
        </div>
    );
};
