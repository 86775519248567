import React from 'react';

const Radio = ({ onChange, value, isChecked, label, name }) => {
    const handleChange = () => {
        onChange(value);
    };

    return (
        <div className="dm-radio-button">
            <input
                id={label}
                name={name}
                onChange={handleChange}
                value={value}
                type="radio"
                checked={isChecked}
            />
            <label className="dm-radio-button__label" htmlFor={label}>
                {label}
            </label>
        </div>
    );
};

export { Radio };
