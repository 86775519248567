import React, { createContext, useContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import {
    SHOW_POPUP,
    HIDE_POPUP,
    DARKEN_SCREEN,
    LIGHTEN_SCREEN,
    USER_LOGIN,
    SET_USER_DATA,
    TOGGLE_INTERFACE,
    SHOW_SIDEBAR,
    USER_LOGOUT,
    TOGGLE_BLUR,
    TOGGLE_SHELTER,
    SCROLL_SHELTER,
    TOGGLE_STAKING_POPUP,
    TOGGLE_REWARDS_POPUP,
    TOGGLE_RARE_FILTER,
    TOGGLE_TERM_FILTER,
    USER_STAKED_ITEMS,
    ADD_ITEMS_TO_USER_STAKED_ITEMS,
    REMOVE_ITEMS_FROM_USER_STAKED_ITEMS,
    USER_NOT_STAKED_ITEMS,
    ADD_ITEMS_TO_USER_NOT_STAKED_ITEMS,
    REMOVE_ITEMS_FROM_USER_NOT_STAKED_ITEMS,
    USER_WAX_BALANCE,
    USER_DMP_BALANCE,
    SET_STAKING_CONFIG,
    TRANSACTION_INFO,
    SELECTED_ITEMS_FOR_STAKE,
    SELECTED_ITEMS_FOR_UNSTAKE,
    USER_ITEMS_FETCHED,
    SHOW_WELCOME,
    SHOWING_WELCOME_SCREEN,
    PRE_LAUNCH_FINISH_TIMESTAMP,
    MINING_MULTIPLIER,
    SET_RARE_FILTER,
    SET_TERM_FILTER,
    SET_AFFILIATE_MODAL_DATA,
    SET_AUTH_DATA,
    SET_AUTH_LINKS,
    SET_REFERRAL_DATA,
    SET_IS_EMAIL_SENT,
    SET_AFFILIATE_ACHIEVEMENTS,
    SET_HAS_NTT,
    SET_IS_BANNER_SHOWING,
} from './AppActionTypes';

const AppContext = createContext();

export const useApp = () => {
    return useContext(AppContext);
};

// Todo:: тут в основном вся логика по взаимодействию с интерфейсом и тестовые данные, которые потом поменять на данные из запросов.
const initialState = {
    isAuthenticated: false,
    userData: null,
    showPopup: false,
    popupType: null,
    shadow: false,
    showInterface: true,
    sidebar: false,
    blur: false,
    shelter: false,
    scrollShelter: false,
    stakingPopup: false,
    rewardsPopup: false,
    isBannerShowing: false,

    nftList: [],

    stakedList: [],
    stakedListFetched: false,
    unstakedList: [],
    unstakedListFetched: false,

    userItemsFetched: false,

    selectedItemsForStake: [],
    selectedItemsForUnstake: [],

    rewardsList: [],
    stakingConfig: [],
    stakingConfigFetched: false,

    waxBalance: 0,
    waxBalanceFetched: false,
    dmpBalance: 0,
    dmpBalanceFetched: false,

    activeRareFilter: '',
    activeTermFilter: '',

    toggleRareFilter: false,
    toggleTermFilter: false,

    transactionInfoFetched: false,
    transactionsInfo: [],

    showWelcome: true,
    showingWelcomeScreen: false,

    preLaunchFinishTimestamp: 0,
    preLaunchFinishTimestampFetched: false,

    miningMultiplier: 1,
    miningMultiplierFetched: false,

    affiliateModalData: {},

    referralData: {
        referrals: [],
        is_referral: false,
    },
    authData: {},
    authLinks: {},
    affiliateAchievements: undefined,
    isEmailSent: false,
    hasNtt: false,
};

export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);
    const showPopupHandler = (value) => dispatch({ type: SHOW_POPUP, value });
    const hidePopupHandler = () => dispatch({ type: HIDE_POPUP });

    const darkenScreenHandler = () => dispatch({ type: DARKEN_SCREEN });
    const lightenScreenHandler = () => dispatch({ type: LIGHTEN_SCREEN });

    const userLoginHandler = () => dispatch({ type: USER_LOGIN });
    const setUserDataHandler = (data) =>
        dispatch({ type: SET_USER_DATA, data });
    const userLogoutHandler = () => dispatch({ type: USER_LOGOUT });

    const setUserStakedItems = (value) =>
        dispatch({ type: USER_STAKED_ITEMS, value });
    const addItemsToUserStakedItems = (value) =>
        dispatch({ type: ADD_ITEMS_TO_USER_STAKED_ITEMS, value });
    const removeItemsFromUserStakedItems = (value) =>
        dispatch({ type: REMOVE_ITEMS_FROM_USER_STAKED_ITEMS, value });

    const setUserNotStakedItems = (value) =>
        dispatch({ type: USER_NOT_STAKED_ITEMS, value });
    const addItemsToUserNotStakedItems = (value) =>
        dispatch({ type: ADD_ITEMS_TO_USER_NOT_STAKED_ITEMS, value });
    const removeItemsFromUserNotStakedItems = (value) =>
        dispatch({ type: REMOVE_ITEMS_FROM_USER_NOT_STAKED_ITEMS, value });

    const setUserItemsFetched = (value) =>
        dispatch({ type: USER_ITEMS_FETCHED, value });

    const setSelectedItemsForStake = (value) =>
        dispatch({ type: SELECTED_ITEMS_FOR_STAKE, value });
    const setSelectedItemsForUnstake = (value) =>
        dispatch({ type: SELECTED_ITEMS_FOR_UNSTAKE, value });

    const toggleInterfaceHandler = (value) =>
        dispatch({ type: TOGGLE_INTERFACE, value });

    const toggleSidebarHadler = (value) =>
        dispatch({ type: SHOW_SIDEBAR, value });

    const toggleBlurHandler = (value) => dispatch({ type: TOGGLE_BLUR, value });

    const toggleShelterHandler = (value) =>
        dispatch({ type: TOGGLE_SHELTER, value });
    const scrollShelterHandler = (value) =>
        dispatch({ type: SCROLL_SHELTER, value });

    const toggleStakingPopupHandler = (value) =>
        dispatch({ type: TOGGLE_STAKING_POPUP, value });
    const toggleRewardsPopupHandler = (value) =>
        dispatch({ type: TOGGLE_REWARDS_POPUP, value });

    const toggleRareFilterHandler = (value) =>
        dispatch({ type: TOGGLE_RARE_FILTER, value });
    const toggleTermFilterHandler = (value) =>
        dispatch({ type: TOGGLE_TERM_FILTER, value });

    const setActiveRareFilter = (value) =>
        dispatch({ type: SET_RARE_FILTER, value });
    const setActiveTermFilter = (value) =>
        dispatch({ type: SET_TERM_FILTER, value });

    const setWaxBalance = (value) =>
        dispatch({ type: USER_WAX_BALANCE, value });
    const setDmpBalance = (value) =>
        dispatch({ type: USER_DMP_BALANCE, value });

    const setTransactionInfo = (value) =>
        dispatch({ type: TRANSACTION_INFO, value });

    const setStakingConfig = (value) =>
        dispatch({ type: SET_STAKING_CONFIG, value });

    const setShowWelcome = (value) => dispatch({ type: SHOW_WELCOME, value });
    const setShowingWelcomeScreen = (value) =>
        dispatch({ type: SHOWING_WELCOME_SCREEN, value });

    const setPreLaunchFinishTimestamp = (value) =>
        dispatch({ type: PRE_LAUNCH_FINISH_TIMESTAMP, value });

    const setMiningMultiplier = (value) =>
        dispatch({ type: MINING_MULTIPLIER, value });

    const setAffiliateModalData = (value) =>
        dispatch({ type: SET_AFFILIATE_MODAL_DATA, value });

    const setReferralData = (value) =>
        dispatch({ type: SET_REFERRAL_DATA, value });
    const setAuthData = (value) => dispatch({ type: SET_AUTH_DATA, value });
    const setAuthLinks = (value) => dispatch({ type: SET_AUTH_LINKS, value });

    const setAffiliateAchievements = (value) =>
        dispatch({ type: SET_AFFILIATE_ACHIEVEMENTS, value });

    const setIsEmailSent = (value) =>
        dispatch({ type: SET_IS_EMAIL_SENT, value });

    const setHasNtt = (value) => dispatch({ type: SET_HAS_NTT, value });

    const setIsBannerShowing = (value) =>
        dispatch({ type: SET_IS_BANNER_SHOWING, value });

    return (
        <AppContext.Provider
            value={{
                userData: state.userData,

                showInterface: state.showInterface,
                shadow: state.shadow,
                isAuthenticated: state.isAuthenticated,
                sidebar: state.sidebar,
                showPopup: state.showPopup,
                popupType: state.popupType,
                isBannerShowing: state.isBannerShowing,

                nftList: state.nftList,
                rewardsList: state.rewardsList,
                stakedList: state.stakedList,
                unstakedList: state.unstakedList,
                userItemsFetched: state.userItemsFetched,

                blur: state.blur,
                noNft: state.noNft,
                shelter: state.shelter,
                scrollShelter: state.scrollShelter,
                stakingPopup: state.stakingPopup,
                rewardsPopup: state.rewardsPopup,
                toggleRareFilter: state.toggleRareFilter,
                toggleTermFilter: state.toggleTermFilter,
                activeRareFilter: state.activeRareFilter,
                activeTermFilter: state.activeTermFilter,

                stakedListFetched: state.stakedListFetched,
                unstakedListFetched: state.unstakedListFetched,

                waxBalance: state.waxBalance,
                waxBalanceFetched: state.waxBalanceFetched,
                dmpBalance: state.dmpBalance,
                dmpBalanceFetched: state.dmpBalanceFetched,

                transactionsInfo: state.transactionsInfo,
                transactionInfoFetched: state.transactionInfoFetched,

                selectedItemsForStake: state.selectedItemsForStake,
                selectedItemsForUnstake: state.selectedItemsForUnstake,

                stakingConfig: state.stakingConfig,
                stakingConfigFetched: state.stakingConfigFetched,

                showWelcome: state.showWelcome,
                showingWelcomeScreen: state.showingWelcomeScreen,

                preLaunchFinishTimestamp: state.preLaunchFinishTimestamp,
                preLaunchFinishTimestampFetched:
                    state.preLaunchFinishTimestampFetched,

                miningMultiplier: state.miningMultiplier,
                miningMultiplierFetched: state.miningMultiplierFetched,

                affiliateModalData: state.affiliateModalData,

                referralData: state.referralData,
                authData: state.authData,
                authLinks: state.authLinks,
                affiliateAchievements: state.affiliateAchievements,
                isEmailSent: state.isEmailSent,
                hasNtt: state.hasNtt,

                showPopupHandler,
                hidePopupHandler,
                darkenScreenHandler,
                lightenScreenHandler,
                userLoginHandler,
                userLogoutHandler,
                setUserNotStakedItems,
                addItemsToUserNotStakedItems,
                removeItemsFromUserNotStakedItems,
                setUserStakedItems,
                addItemsToUserStakedItems,
                removeItemsFromUserStakedItems,
                setUserItemsFetched,
                setUserDataHandler,
                toggleInterfaceHandler,
                toggleSidebarHadler,
                toggleBlurHandler,
                toggleShelterHandler,
                scrollShelterHandler,
                toggleStakingPopupHandler,
                toggleRewardsPopupHandler,
                toggleRareFilterHandler,
                toggleTermFilterHandler,
                setActiveRareFilter,
                setActiveTermFilter,
                setWaxBalance,
                setDmpBalance,
                setTransactionInfo,
                setStakingConfig,
                setSelectedItemsForStake,
                setSelectedItemsForUnstake,
                setShowWelcome,
                setShowingWelcomeScreen,
                setPreLaunchFinishTimestamp,
                setMiningMultiplier,
                setAffiliateModalData,
                setReferralData,
                setAuthData,
                setAuthLinks,
                setAffiliateAchievements,
                setIsEmailSent,
                setHasNtt,
                setIsBannerShowing,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
