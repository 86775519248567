import React from 'react';
import Countdown from 'react-countdown';
import { countdownRenderer } from '../StoreCard/helpers';
import { Link, Notice } from '../../../../Components/Ui/v2';

export const OnlyWhitelisters = ({ wlFinishAt, onUpdate }) => (
    <Notice className="store-product-page-options__notice">
        This product is only for sale to whitelisters and will available for
        public sale in{' '}
        <Countdown
            renderer={countdownRenderer}
            date={wlFinishAt}
            onComplete={onUpdate}
        />
        .
        <br />
        <br />
        More about upcoming events on our{' '}
        <Link href="/{link-to-medium-page}">medium page</Link>
    </Notice>
);
